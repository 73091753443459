import React from "react";
import RenewButtonIcon from '../../assets/renewButtonIcon';

export default function RenewButton({status, viewChange}) {
  const renewButtonStatus = ['expired', 'expiring']

  const handleOnClick = () => {
    viewChange('renew', true)
  }

  return (
    <button className={`flex bg-blue-100 hover:bg-blue-200 hover:border-blue-200 border border-blue-100 sm:text-base text-sm text-white inline-block px-5 py-2 shadow rounded-lg relative gap-2 items-center`} onClick={() => handleOnClick()}>
      <RenewButtonIcon classNames={'hc-14 wc-14 shrink-0'} strokeColor={'#1E40AF'}/>
      <div className='text-blue-800 text-base font-semibold'>
        {`${renewButtonStatus.includes(status.toLowerCase()) ? 'Renew' : 'Resubmit'} Documentation`}
      </div>
    </button>
  )
}