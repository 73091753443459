import React from 'react';

export function statusListByPageType(type, business) {
  if (type === 'sent') {
    let list = [
      {value: 'pending_mgr', label: 'Pending Manger Board Approval'},
      {value: 'pending', label: 'Open'},
      {value: 'reopen', label: 'Re-opened'},
      {value: 'accepted', label: 'Pending Approval'},
      {value: 'confirm', label: 'Approved'},
    ]
    if(!business?.manager_request_gate) list = list.filter((item) => item.value !== 'pending_mgr')
    return list
  }
  if (type === 'available') {
    return [
      {value: 'pending', label: 'No response'},
      {value: 'accept', label: 'Accepted'},
      {value: 'decline', label: 'Declined'}
    ]
  }
  return []
}

export const getSentBucketDefaultSelection = (mrgType) => {
  return(mrgType ? ['pending_mgr', 'pending', 'reopen', 'accepted', 'confirm'] : ['pending', 'reopen', 'accepted', 'confirm'])
}

export const getAvaialbleBucketDefaultSelection = (Filter) => {
  return(Filter?.length > 0 ? Filter : ['pending', 'accept', 'decline'])
}

export const getAffectedVaraibles = (allEvents, newEvent) => {
  let reduceVar = ''
  let increaseVar = ''
  if (!newEvent || !allEvents) return [reduceVar, increaseVar];
  let oldEvent = allEvents?.find(event => event.id === newEvent.id);
  if (!oldEvent) return [reduceVar, increaseVar];
  let oldEventState = oldEvent?.user_status;
  let newEventState = newEvent?.user_status;
  const statusCounts = {
    Accepted: 'acceptCount',
    Declined: 'declineCount',
    Pending: 'pendingCount'
  };
  reduceVar = statusCounts[oldEventState] || '';
  increaseVar = statusCounts[newEventState] || '';
  return [reduceVar, increaseVar];
}

export const getSentAffectedVaraibles = (allEvents, newEvent) => {
  if (!newEvent || !allEvents) return ['', ''];
  const existingEvent = allEvents?.find(event => event.id === newEvent.id);
  if (!existingEvent) return ['', ''];
  const reduceVar = getVariableNameByStatus(existingEvent) || '';
  const increaseVar = getVariableNameByStatus(newEvent) || '';
  return [reduceVar, increaseVar];
}

export const getVariableNameByStatus = ({ status, status_content: statusContent }) => {
  if (status === 'Accepted' && ['Pending Your Approval', 'Pending Manager Approval'].includes(statusContent)) {
    return 'acceptedCount';
  } else if (status === 'Open' && ['Re-Opened', 'Open'].includes(statusContent)) {
    return statusContent === 'Re-Opened' ? 'reopenCount' : 'pendingCount';
  } else if (status === 'Approved' && ['Response Needed (Re-opened)', 'Approved'].includes(statusContent)) {
    return 'approvedCount'
  }
  return ''
}

export const checkAndShowMassActionSelectAll = (events) => {
  return (events.every(event => event.location_id === events[0].location_id))
}

export const cancelConfirmationText = (eventIds, selectedOption, pastActionText) => {
  const isMultiple = eventIds?.length > 1;
  const isDelete = selectedOption === 'delete_without_sync';

  const actionText = isDelete
    ? `deleting ${isMultiple ? "these sub requests" : "this sub request"} permanently. This cannot be undone. ${isMultiple ? "These sub requests" : "This sub request"} will no longer be active but will remain visible under the "Deleted" status filter for your reference.`
    : `cancelling ${isMultiple ? "these sub requests" : "the sub request"} and marking ${isMultiple ? "them" : "it"} as red on the All Requests board.`;

  const confirmationText = isDelete
    ? `Yes, permanently delete ${isMultiple ? "these sub requests" : "this sub request"}.`
    : `Yes, cancel ${isMultiple ? "these sub requests" : "this sub request"}.`;

  return [
    `When you select ${isDelete ? "Schedule Updated" : pastActionText ? "Couldn't find sub" : "Can't Find a Sub"}, you will be ${actionText}`,
    confirmationText
  ];
};

export const cancelConfirmationTextForOther = (selectedOption) => {
  const deleteOptions = ['delete_class_original_instructor', 'delete_class'];
  
  if (deleteOptions.includes(selectedOption)) {
    return [
      `Only select this option if you have confirmed with the original instructor and the manager that they are teaching and no longer need a sub. The sub request will be deleted and cannot be reverted. This request will no longer be active but will remain visible under the "Deleted" status filter for management to view.`,
      `Yes, permanently delete this sub request.`
    ];
  }

  const confirmationText = ['crunch_event_delete', 'delete_class_duplicate'].includes(selectedOption)
    ? 'Yes, permanently delete this sub request.' 
    : 'Yes, cancel this sub request.';

  return ['', confirmationText];
};

export const isCancelOption = (selectedOption) => ['delete_without_sync', 'cancel_sub_request'].includes(selectedOption);

export const isCancelOptionForOther = (selectedOption) => ['delete_class_original_instructor', 'delete_class', 'rescind_class', 'crunch_event_delete', 'delete_class_duplicate'].includes(selectedOption);

export const checkDisabledApplyButton = (selectedOption, permanentlyCancel, disableConfirmBtn) => {
  return (selectedOption == '' || (!permanentlyCancel && (isCancelOption(selectedOption) || isCancelOptionForOther(selectedOption))) || disableConfirmBtn)
}

export const reopenActionHash = (event) => {
  return event?.more_action_detail?.find(item => item?.key == 'reopen_class')
}