import React, { Fragment, useState } from "react";
import CustomPopupContainerComponent from '../../../../../subRequestDetails/components/customPopupContainerComponent';
import { LoadingIcon } from '../../../../../subRequests/assets';
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon, } from '@heroicons/react/outline'
import { axiosRequest } from '../../../../../subRequests/helpers/helpers';
import InfoIcon from '../../../../../documentations/assets/infoIcon';

export default function AssignSkillsPopup(props) {
  const { showAssignSkillPopup, closeAssignSkillsPopup, skills, selectedPeopleForMassActions, showAlert, completeMassAssignActions, business } = props
  const [ selectedSkillIds, setSelectedSkillIds ] = useState([])
  const [ disableConfirmBtn, setDisableConfirmBtn ] = useState(false)
  const allSkillIds = skills?.map(item => item.id) || [];
  const clubreadyFisikalIntegration = (business?.clubready_integration || business?.fisikal_integration)
  const integrationSkillIds = clubreadyFisikalIntegration ? skills?.filter(item => !item.manually_added).map(item => item.id) || [] : [];
  const skillIds = allSkillIds.filter(id => !integrationSkillIds.includes(id));

  const handleAssign = () => { 
    if (disableConfirmBtn || selectedSkillIds?.length === 0 || selectedPeopleForMassActions?.length === 0) return;
    setDisableConfirmBtn(true)
    let params = {
      business_user_ids: selectedPeopleForMassActions,
      business_category_ids: selectedSkillIds
    }
    axiosRequest(`/api/v3/business_categories/bulk_assign`, 'POST', params, 'data').then(result => {
      if(result?.status === 200){
        showAlert(true, 'success', result?.message)
        setTimeout(()=>{
          showAlert(false, '', '')
          completeMassAssignActions()
          setDisableConfirmBtn(false)
        }, 3000)
      }else{
        showAlert(true, 'danger', result?.error)
        setDisableConfirmBtn(false)
        setTimeout(()=>{
          showAlert(false, '', '')
        }, 3000)
      }
    })
  }

  const checkAllSkillsSelected = () => {
    return skillIds.every(v => selectedSkillIds.includes(v)) && skillIds?.length > 0
  }

  const handleSelectAllSkills = (e) => {
    setSelectedSkillIds(e.target.checked ? skillIds : [])
  }

  const handleSelectSkill = (e) => {
    let val = parseInt(e.target.value)
    var __FOUND = selectedSkillIds.includes(val)
    setSelectedSkillIds(__FOUND ? selectedSkillIds.filter(v => v !== val) : [...selectedSkillIds, val])
  }

  const skillName = (categoryId) => {
    let category = skills.find((item) => item?.id === categoryId)
    if(category){
      return category?.name
    }else{
      return categoryId
    }
  }

  const removeSelectedSkills = (categoryId) => {
    setSelectedSkillIds(prevState => (prevState.filter(item => item !== parseInt(categoryId))))
  }

  return(
    <Fragment>
      <CustomPopupContainerComponent show={showAssignSkillPopup} close={closeAssignSkillsPopup} title={''} customWidth={'sm:max-w-sm assign-skills-popup'}>
        <div className="px-4 pt-3 font-open-sans">
          <div className={`flex gap-2 justify-center text-center font-bold text-xl font-open-sans`}>
            Assign Skill(s)
            {clubreadyFisikalIntegration &&
              <div className='inline shrink-0 w-5 h-5 profile-notification-container relative'>
                <InfoIcon classNames={'inline shrink-0 w-5 h-5'}/>
                <div className="profile-notification-tooltip text-white bg-black shadow-md border rounded-md p-2 absolute bottom-8 -left-24 text-xs font-semibold w-56 text-center">
                  {`Some skills are automatically associated with your classes in ${business?.clubready_integration ? 'Clubready' : 'Fisikal'} and can only be applied to users in ${business?.clubready_integration ? 'Clubready' : 'Fisikal'}. Only custom skills can be applied directly to Netgym user profiles.`}
                </div>
              </div>
            }
          </div>
          
          <div className="min-h-400">
            <div className='grid grid-cols-1 sm:gap-4 sm:items-start mt-4'>
              <div className='mt-1 sm:mt-0 sm:col-span-1'>
                <div className="relative filters_box w-full">
                  <Menu as="div" className="relative block text-left">
                    <div>
                      <Menu.Button className={`h-10 flex items-center justify-center w-full rounded-md border border-gray-300 shadow-sm pl-4 pr-2 py-2 text-sm text-gray-400 focus:outline-nonefocus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500`}>
                        {selectedSkillIds?.length === 0 ?
                          <p>Select skill(s)</p>
                        :
                        (checkAllSkillsSelected()) ?
                          <p className='text-gray-900'>All skills selected</p>
                        :
                          <p className='text-gray-900'>{`${selectedSkillIds?.length} skill${selectedSkillIds?.length > 1 ? 's' : ''} selected`}</p>
                        } 
                        <ChevronDownIcon className="ml-auto text-gray-400 w-4 mr-0.5" aria-hidden="true" />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95">
                      <Menu.Items className="origin-top-right z-10 relative right-0 w-full rounded-md shadow-lg bg-white ring-none border border-gray-300 focus:outline-none">
                        <div className="flex items-center p-2 border-b">
                          <div className="flex items-center py-1">
                            <label className="inline-flex items-center text-gray-400 text-sm">
                              <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-1" checked = {checkAllSkillsSelected()} onChange = {(e) => handleSelectAllSkills(e)}/>
                              Select All
                              </label>
                          </div>
                        </div>
                        <div className="p-2 max-h-60 overflow-y-auto pb-12">
                          {skills.map((skill, i) =>
                            <div className="py-1" key = {i}>
                              <label className="flex items-center text-gray-900 text-sm mb-2 profile-notification-container relative">
                                <input type="checkbox" className={`w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-1 ${integrationSkillIds?.includes(skill.id) ? 'cursor-not-allowed opacity-50' : ''}`} id={skill.id} name="skills[]" onChange={(e) => handleSelectSkill(e, skill)} value={skill.id} checked={selectedSkillIds?.includes(skill.id) || integrationSkillIds?.includes(skill.id)} disabled={integrationSkillIds?.includes(skill.id)}/>
                                <label className={`ml-1.5 text-sm font-medium text-gray-500`} htmlFor={skill.id}>{skill?.name}</label> 
                                {(clubreadyFisikalIntegration && integrationSkillIds?.includes(skill.id)) &&
                                  <div className="profile-notification-tooltip text-white bg-black shadow-md border rounded-md p-2 absolute z-10 -top-2 left-4 text-xs font-semibold w-64 text-center">
                                    {`These are skills associated with your classes in ${business?.clubready_integration ? 'Clubready' : 'Fisikal'} and can only be applied to users in ${business?.clubready_integration ? 'Clubready' : 'Fisikal'}. They cannot be applied directly to NetGym user profiles.`}
                                  </div>
                                }
                              </label>
                            </div>
                          )}
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
                {selectedSkillIds?.length > 0 &&
                  <div className='flex items-center mt-3 gap-2 flex-wrap'>
                    {selectedSkillIds.map((categoryId, i) => 
                      <div className='bg-purple-100 text-purple-800 py-0.5 px-2.5 flex items-center rounded-xl' key={i}>
                        <div className='text-sm pr-1 font-medium'>{skillName(categoryId)}</div>
                        <a onClick={() => removeSelectedSkills(categoryId)}>
                          <svg xmlns="http://www.w3.org/2000/svg" className="text-violet-800  h-3 w-3" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                          </svg>
                        </a>
                      </div>
                    )}
                  </div>
                }
              </div>
            </div>
          </div>
          <div className="mt-4 relative">
            {disableConfirmBtn && 
              <div className="flex items-center left-5 top-2.5 justify-center absolute">
                <LoadingIcon classNames={'animate-spin h-4 w-4 text-black'}/>
              </div>
            }
            <button 
              className={`bg-gray-900 rounded-md text-white flex items-center justify-center w-full py-2 text-sm ${(selectedSkillIds?.length == 0 || disableConfirmBtn) ? 'opacity-50 cursor-not-allowed' : ''}`}
              onClick={() => handleAssign()}
              disabled={selectedSkillIds?.length == 0 || disableConfirmBtn}
            >
              Assign
            </button>
          </div>
        </div>
      </CustomPopupContainerComponent>
    </Fragment>
  )
}