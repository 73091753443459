import React, { Fragment } from 'react';
import { Dialog, Menu, Transition } from '@headlessui/react'
import { Link, Redirect } from 'react-router-dom';
import { logOut, DeleteAccountComponent, removeAccount, getSudomain, addAccounts, checkAndSwitchAccount, checkTheSettingsPagePath, settingsPermissions } from '../helpers';
import CustomPopupContainerComponent from '../subRequestDetails/components/customPopupContainerComponent';
import Cookies from 'js-cookie';
import ja from 'date-fns/esm/locale/ja/index';
import { Accordion, Card } from "react-bootstrap";
import IntercomManager from '../intercom/intercomManager';

const userNavigation = [
  { name: 'Add Account', href: '#' },
  { name: 'Log Out', href: '#' },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default class Sidebar extends React.Component {
  constructor(props){
    super(props);
    this.state={
      show: false,
      insightLinks: [],
      deletableAccount: 0,
      deleteModal: false,
      disableDeleteButton: false,
      loggedOut: false,
      subBoardItems: [
        { name: 'My Requests', href: '/my-requests', icon: "/assets/icon_sb_sub_board.png", current: false, alt_name: 'My Requests', old_prop: false, drop_down: true },
        { name: 'All Requests', href: '/all-requests', icon: "/assets/icon_sb_sub_board.png", current: false, alt_name: 'All Requests', old_prop: false, drop_down: true },
      ],
    }
  }

  setOpen = () => {
    this.props.setSidebarOpen()
  }

  getName(role){
    let name = ""
    switch(role){
      case 'account_owner':
      case 'Account Owner':
      case 'business_admin':
      case 'Business Admin':
        name = "admin"
        break;
      case 'regional_admin':
      case 'Regional Admin':
        name = 'regional_admin'
        break;
      case 'fitness_manager':
      case 'Fitness Manager':
        name = 'fitness_manager'
        break;
      case 'instructor':
      case 'Fitness Instructor':
        name = 'instructor'
        break;
      case 'ops':
      case 'Ops':
        name = 'ops'
        break;
    }
    return name
  }

  getHref = (item) => {
    let url = ""
    let user = this.props.user
    let business = this.props.business
    let role = user?.role
    let permissions = this.props.permissions || {}
    switch(item.name){
      case 'Sub Board':
      case 'My Requests':
        url = `/my-requests`
        break;
      case 'My Profile':
        url = item.href
        break;
      case 'Availability':
        url =  item.alt_name === 'Availabilit' ? '/availability/search' : '/availability'
        break;
      case 'Directory':
        url = '/directory'
        break;
      case 'Sub Schedule':
      case 'All Requests':
        url = `/all-requests`
        break;
      case 'Insights':
      case 'Instructor History':
      case 'Behavior':
      case 'Responsiveness':
      case 'Home Club Report':
        url = "/analytics"
        break;
      case 'Messages':
        url = business?.new_messenger ? ((user?.all_inbox_messages_count === 0 && user?.chatrooms_count !== 0) ? `/messenger/chat-room/${user?.stream_id}` : '/messenger' ) : `/my-requests`
        break;
      case 'Settings':
          url = checkTheSettingsPagePath(user, permissions, "/settings-v4")
          break;
      case 'Configure':
        url = "/configure"
        break;
      case 'Refer & Earn':
        url = 'https://share.hsforms.com/1O2Mnu7SnRzKIqNfeZ0cNeAe3aix'
        break;
      case 'Attendance':
        url = `/attendance`
        break;
      case 'Resource Hub':
        url = `/resource_hub`
        break;
      case 'Notification Logs':
        url = `/notification-audits`
        break;
      case 'Documentation':
        url = `/documentation`
        break;
      default:
        url = `/my-requests`
        break;
    }
    return url
  }

  toggleInsights = (e) => {
    this.props.setInsightsOpen()
    this.props.setCurrent('Instructor History', false)
  }

  showLink = (item) => {
    let business = this.props?.business
    let permissions = this.props?.permissions
    let user = this.props?.user
    switch(item){
      case "Manager Board":
        return business?.manager_request_gate ? (user?.role_display == 'Fitness Instructor' || user.role_display == 'Ops') ? "hidden" : ""  : "hidden"
        break;
      case "Sub Board":
        return ''
        // return user?.role_display == 'Ops' ? permissions?.sub_request?.write ? "" : "hidden" : ""
        break;
      case "Attendance":
        return (user?.abc_feature && user?.attendance_feature) ? ""  : "hidden"
        break;
      case "Messages":
        return business?.new_messenger ? ""  : "hidden"
        break;      
      case "Insights":
        return (user?.role_display !== 'Fitness Instructor' && user?.role_display !== 'Ops') ? ""  : "hidden"
          break;
      case "Settings":
        if (user?.role_display === "Netgym Employee") return "";
        if (user?.role_display === "Fitness Instructor") return "hidden";
        if (user?.role_display === "Ops") return (settingsPermissions(permissions) ? "" : "hidden");
        const hasAccess = (settingsPermissions(permissions) || ['business_admin', 'account_owner'].includes(user?.role_name))

        return hasAccess ? "" : "hidden";
        break;
      case "Configure":
        return user?.role_display === 'Netgym Employee' ? ""  : "hidden"
        break;
      case "Home Club Report":
        return business?.reports ? ""  : "hidden"
        break;
      case "Resource Hub":
        return business?.resource_hub ? ""  : "hidden"
        break;
      case "Availability":
        return business?.availability_feature ? ""  : "hidden"
        break;
      case "Notification Logs":
        return user?.role_display === 'Netgym Employee' ? ""  : "hidden"
        break;
      case "Sub Schedule":
        return "hidden"
        break;
      case "All Requests":
        return user?.role_display == 'Fitness Instructor' ? "hidden"  : ""
        break;
      case "My Requests":
        return user?.role_display == 'Ops' ? permissions?.sub_request?.write ? "" : "hidden" : ""
        break;
      case "Documentation":
        return (business?.documentations_feature && (user?.role_display !== 'Fitness Instructor' && (permissions?.documentations?.read || user?.role_display == 'Netgym Employee'))) ? "" : "hidden"
        break;
      default:
        return ""
        break;
    }
  }

  linksCount = (item) => {
    let count = 0
    switch(item.name){
      case 'Attendance':
        count = this.props.user?.unread_attendance_count
        break;
      case 'Messages':
        count = this.props.user?.unread_count
        break;
    }
    return count
  }

  delete = (e, id) => {
    e.preventDefault()
    this.setState({
      deleteModal: true,
      deletableAccount: id
    })    
  }

  processDelete = () => {
    this.setState({disableDeleteButton: true})
    removeAccount(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), this.state.deletableAccount)
    .then(response => response.json())
    .then(result => {
      if(result.status == 200){
        this.setState({disableDeleteButton: false})
        this.closeModal()
        window.location.reload()
      }
    })
  }

  closeModal = () => {
    this.setState({
      deleteModal: false,
      deletableAccount: 0
    })
  }

  handleLogout = (e) => {
    e.preventDefault()
    logOut(Cookies.get(`currentUser.${getSudomain()}`), getSudomain())
    .then(response => response.json())
    .then(result => { 
      if(result.status == 200){
        let domain = "."+window.location.hostname.replace(getSudomain() + '.', '' )
        Cookies.remove(`currentUser.${getSudomain()}`, { path: '/', domain: domain })
        Cookies.remove('currentEmail', { path: '/', domain: domain })
        Cookies.remove('switch_account', { path: '/', domain: domain })
        Cookies.remove('switch_account_owner', { path: '/', domain: domain })
        Cookies.remove('switch_account_business', { path: '/', domain: domain })
        Cookies.remove('switch_account_email', { path: '/', domain: domain })
        this.setState({loggedOut: true})
      }
    })
  }
  
  checkSubScheduleActive = () => {
    const { navigation } = this.props
    let subSchedule = navigation.findIndex((obj => obj.name === 'Sub Schedule'));
    return navigation[subSchedule].current
  }

  render() {
    const { sidebarOpen, user, permissions, business, insightsOpen, navigation, accounts } = this.props
    const { show, insightLinks, deleteModal, disableDeleteButton, loggedOut, subBoardItems  } = this.state
    return (
      <React.Fragment>
        {loggedOut &&
          <Fragment>
            <IntercomManager isLoggedIn={!loggedOut} user={user} signInPage={true}/>
            <Redirect to={'/signin'} />
          </Fragment>
        }
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="fixed inset-0 flex z-40 md:hidden" onClose={(e) => this.setOpen()}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="relative flex flex-col w-42 pt-5 pb-4 bg-black">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                  </div>
                </Transition.Child>
                <div className="flex-1 h-0 overflow-y-auto">
                  <div className="flex items-center justify-center mt-2 mb-4">
                    <div className="flex flex-col items-center justify-center">
                      <a href="/">
                        {business?.custom_logo &&
                          <img src={business?.profile_url} alt="logo" className="max-h-12 max-w-28" />
                        }
                        {!business?.custom_logo &&
                          <img src={window.location.origin + '/assets/logo.png'} alt="logo" className="max-h-12 max-w-28" />
                        }
                      </a>
                      {user.role_display === "Netgym Employee" &&
                        <a href="/my-requests" className="mt-2 text-xs text-white">Internal NG</a>
                      }
                    </div>
                  </div>
                  <nav className="space-y-1 mx-1">
                    {navigation.map((item, i) => (
                      <React.Fragment key={i}>
                        <div className={`{(insightLinks.includes(item.alt_name) && !insightsOpen) ? "hidden" : insightLinks.includes(item.alt_name) ? "relative" : "relative"} mb-0.5`}>
                          <div className={this.showLink(item.name)}>
                            {(item.drop_down && user?.role_display !== 'Fitness Instructor') ?
                              <Fragment>
                                <Accordion 
                                  className=''
                                  defaultActiveKey={'0'}
                                >
                                  <Card className="border-0">
                                    <Accordion.Toggle 
                                      eventKey="0" 
                                      className={classNames(
                                        'text-gray-50 text-opacity-80', "relative tailwind_sidebar_link",
                                        'group flex items-center pl-2.5 pr-2 py-3.5 pb-2 text-sb text-opacity-80 font-semibold rounded-lg w-full text-left cursor-default'
                                      )}
                                      disabled
                                    >
                                      {!(insightLinks.includes(item.alt_name)) &&
                                        <img src={item.icon} className="tailwind_sidebar_icon" />
                                      }
                                      <span className="flex-1">{item.name}</span>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="0">
                                      <Card.Body className="p-0">
                                        <div className="mt-0.5 ml-4 relative">
                                          <div className={`absolute w-px h-full left-0 top-0 rounded-full bg-white opacity-80`}></div>
                                          {subBoardItems?.map((subItem, j) => 
                                            <React.Fragment key={j}>
                                              <div className={`${(insightLinks.includes(subItem.alt_name) && !insightsOpen) ? "hidden" : insightLinks.includes(subItem.alt_name) ? "relative" : "relative "} ml-3 mb-0.5`}>
                                                <div className={this.showLink(subItem.name)}>
                                                  <Link
                                                    key={subItem.name}
                                                    to={this.getHref(subItem)}
                                                    className={classNames(
                                                      ((subItem.alt_name == 'My Requests' && item.current) || (subItem.alt_name == 'All Requests' && this.checkSubScheduleActive())) ? 'sidebar-active-tab-colour tailwind_sidebar_link text-gray-50 text-opacity-80' : 'text-gray-50 hover:text-white sidebar-hover-tab-colour text-opacity-80', "relative tailwind_sidebar_link",
                                                      'group flex items-center px-3 py-2 text-sb text-opacity-80 font-semibold rounded-lg'
                                                    )}
                                                  >
                                                    <span className="flex-1">{subItem.name} {(this.linksCount(subItem) && this.linksCount(subItem) > 0) ? `(${this.linksCount(subItem)})`
                                                    : null}</span>
                                                  </Link>
                                                </div>
                                              </div>
                                            </React.Fragment>
                                          )}
                                        </div>
                                      </Card.Body>
                                    </Accordion.Collapse>
                                  </Card>
                                </Accordion>
                              </Fragment>
                            :
                              <Fragment>
                                {item.old_prop ?
                                  <a
                                    key={item.name}
                                    href={this.getHref(item)}
                                    className={classNames(
                                      item.current ? 'sidebar-active-tab-colour text-gray-50 text-opacity-80 relative' : 'text-gray-50 hover:text-white sidebar-hover-tab-colour:hover text-opacity-80', "relative",
                                      'group flex items-center pl-2.5 pr-2 py-3.5 text-sb text-opacity-80 font-semibold rounded-lg'
                                    )}
                                  >
                                    {!(insightLinks.includes(item.alt_name)) &&
                                      <img src={item.icon} className="tailwind_sidebar_icon" />
                                    }
                                    <span className="flex-1">{(item.name == 'Sub Schedule' && user?.role_display == "Fitness Instructor") ? 'My Sub Schedule' : item.name} {(this.linksCount(item) && this.linksCount(item) > 0) ? `(${this.linksCount(item)})`
                                  : null}</span>
                                    {item.name == 'Manager Board' && this.props.user?.manager_board_count && this.props.user?.manager_board_count > 0 ?  
                                      <div className="bg-red-500 w-5 h-5 rounded-full tc-11 text-white flex items-center justify-center absolute top-0.5 right-1 px-1">{this.props.user?.manager_board_count}</div>
                                    : 
                                      null
                                    }
                                  </a>
                                :
                                  <Link
                                    key={item.name}
                                    to={this.getHref(item)}
                                    className={classNames(
                                      item.current ? 'sidebar-active-tab-colour text-gray-50 text-opacity-80' : 'text-gray-50 hover:text-white sidebar-hover-tab-colour:hover text-opacity-80', "relative",
                                      'group flex items-center pl-2.5 pr-2 py-3.5 text-sb text-opacity-80 font-semibold rounded-lg'
                                    )}
                                  >
                                    {!(insightLinks.includes(item.alt_name)) &&
                                      <img src={item.icon} className="tailwind_sidebar_icon" />
                                    }
                                    <span className="flex-1">{(item.name == 'Sub Schedule' && user?.role_display == "Fitness Instructor") ? 'My Sub Schedule' : item.name} {(this.linksCount(item) && this.linksCount(item) > 0) ? `(${this.linksCount(item)})`
                                  : null}</span>
                                  </Link>
                                }
                              </Fragment>
                            }
                          </div>
                        </div>
                      </ React.Fragment>
                    ))}
                  </nav>
                </div>
              </div>
            </Transition.Child>
            <div className="flex-shrink-0 w-14" aria-hidden="true">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden bg-black md:flex md:flex-shrink-0">
          <div className="flex flex-col w-42">
            {/* Sidebar component, swap this element with another sidebar if you like */}
            <div className="flex flex-col flex-grow pb-4 overflow-y-auto overflow-x-hidden">            
              <div className="flex-1 flex flex-col pt-4">
                <div className="flex items-center justify-center mt-2 mb-4">
                  <div className="flex flex-col items-center justify-center">
                    <a href="/">
                      {business?.custom_logo &&
                        <img src={business?.profile_url} alt="logo" className="max-h-12 max-w-28" />
                      }
                      {!business?.custom_logo &&
                        <img src={window.location.origin + '/assets/logo.png'} alt="logo" className="max-h-12 max-w-28" />
                      }
                    </a>
                    {user.role_display === "Netgym Employee" &&
                      <a href="/my-requests" className="mt-2 text-xs text-white">Internal NG</a>
                    }
                  </div>
                </div>
                <nav className="mx-1 flex-1 space-y- font-open-sans">
                  {navigation.map((item, i) => (
                    <React.Fragment key={i}>
                      <div className={`${(insightLinks.includes(item.alt_name) && !insightsOpen) ? "hidden" : insightLinks.includes(item.alt_name) ? "relative" : "relative "} mb-0.5`}>
                        <div className={this.showLink(item.name)}>
                          {(item.drop_down && user?.role_display !== 'Fitness Instructor') ?
                            <Fragment>
                              <Accordion 
                                className=''
                                defaultActiveKey={'0'}
                              >
                                <Card className="border-0">
                                  <Accordion.Toggle 
                                    eventKey="0" 
                                    className={classNames(
                                      'text-gray-50 text-opacity-80', "relative tailwind_sidebar_link",
                                      'group flex items-center pl-2.5 pr-2 py-3.5 pb-2 text-sb text-opacity-80 font-semibold rounded-lg w-full text-left cursor-default'
                                    )}
                                    disabled
                                  >
                                    {!(insightLinks.includes(item.alt_name)) &&
                                      <img src={item.icon} className="tailwind_sidebar_icon" />
                                    }
                                    <span className="flex-1">{item.name}</span>
                                  </Accordion.Toggle>
                                  <Accordion.Collapse eventKey="0">
                                    <Card.Body className="p-0">
                                      <div className="mt-0.5 ml-4 relative">
                                        <div className={`absolute w-px h-full left-0 top-0 rounded-full bg-white opacity-80`}></div>
                                        {subBoardItems?.map((subItem, j) => 
                                          <React.Fragment key={j}>
                                            <div className={`${(insightLinks.includes(subItem.alt_name) && !insightsOpen) ? "hidden" : insightLinks.includes(subItem.alt_name) ? "relative" : "relative "} ml-3 mb-0.5`}>
                                              <div className={this.showLink(subItem.name)}>
                                                <Link
                                                  key={subItem.name}
                                                  to={this.getHref(subItem)}
                                                  className={classNames(
                                                    ((subItem.alt_name == 'My Requests' && item.current) || (subItem.alt_name == 'All Requests' && this.checkSubScheduleActive())) ? 'sidebar-active-tab-colour tailwind_sidebar_link text-gray-50 text-opacity-80' : 'text-gray-50 hover:text-white sidebar-hover-tab-colour text-opacity-80', "relative tailwind_sidebar_link",
                                                    'group flex items-center px-3 py-2 text-sb text-opacity-80 font-semibold rounded-lg'
                                                  )}
                                                >
                                                  <span className="flex-1">{subItem.name} {(this.linksCount(subItem) && this.linksCount(subItem) > 0) ? `(${this.linksCount(subItem)})`
                                                  : null}</span>
                                                </Link>
                                              </div>
                                            </div>
                                          </React.Fragment>
                                        )}
                                      </div>
                                    </Card.Body>
                                  </Accordion.Collapse>
                                </Card>
                              </Accordion>
                            </Fragment>
                          :
                            <Fragment>
                              {item.old_prop ?
                                <a
                                  key={item.name}
                                  href={this.getHref(item)}
                                  className={classNames(
                                    item.current ? 'sidebar-active-tab-colour tailwind_sidebar_link text-gray-50 text-opacity-80 relative' : 'text-gray-50 hover:text-white sidebar-hover-tab-colour text-opacity-80', "relative tailwind_sidebar_link",
                                    'group flex items-center pl-2.5 pr-2 py-3.5 text-sb text-opacity-80 font-semibold rounded-lg'
                                  )}
                                >
                                  {!(insightLinks.includes(item.alt_name)) &&
                                    <img src={item.icon} className="tailwind_sidebar_icon" />
                                  }
                                  <span className="flex-1">{(item.name == 'Sub Schedule' && user?.role_display == "Fitness Instructor") ? 'My Sub Schedule' : item.name} {(this.linksCount(item) && this.linksCount(item) > 0) ? `(${this.linksCount(item)})`
                                  : null}</span>
                                  {item.name == 'Manager Board' && this.props.user?.manager_board_count && this.props.user?.manager_board_count > 0 ? 
                                    <div className="bg-red-500 w-5 h-5 rounded-full tc-11 text-white flex items-center justify-center absolute top-0.5 right-1 px-1">{this.props.user?.manager_board_count}</div>
                                  : 
                                    null
                                  }
                                </a>
                              :
                                <Link
                                  key={item.name}
                                  to={this.getHref(item)}
                                  className={classNames(
                                    item.current ? 'sidebar-active-tab-colour tailwind_sidebar_link text-gray-50 text-opacity-80' : 'text-gray-50 hover:text-white sidebar-hover-tab-colour text-opacity-80', "relative tailwind_sidebar_link",
                                    'group flex items-center pl-2.5 pr-2 py-3.5 text-sb text-opacity-80 font-semibold rounded-lg'
                                  )}
                                >
                                  {!(insightLinks.includes(item.alt_name)) &&
                                    <img src={item.icon} className="tailwind_sidebar_icon" />
                                  }
                                  <span className="flex-1">{(item.name == 'Sub Schedule' && user?.role_display == "Fitness Instructor") ? 'My Sub Schedule' : item.name} {(this.linksCount(item) && this.linksCount(item) > 0) ? `(${this.linksCount(item)})`
                                  : null}</span>
                                </Link>
                              }
                            </Fragment>
                          }
                        </div>
                      </div>
                    </ React.Fragment>
                  ))}
                </nav>
              </div>
            </div>
            <div className="flex fixed bottom-3 left-2 items-center z-50 font-open-sans">
              {/* Profile dropdown */}
              <Menu as="div" className="relative">
                <div>
                  <Menu.Button className="max-w-xs bg-black flex items-center rounded-full focus:outline-none">
                    <img className="h-8 w-8 rounded-full" alt="User Image" src= {user?.image || "/assets/default-profile.jpg" } />
                    <span className="ml-2 text-sb font-semibold text-white hidden md:block truncate max-w-20">{user.first_name}</span>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="#fff">
                      <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                    </svg>
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="origin-top-right absolute left-0 mb-2 w-64 shadow-lg bg-white ring-opacity-5 focus:outline-none bottom-full rounded-md">
                    <div className='py-2 bg-white rounded-md'>
                      <div className="accounts-scroll max-h-96 overflow-y-auto">
                        {accounts.length === 0 &&
                          <React.Fragment>
                            {userNavigation.map((item, i) => (
                              <Menu.Item key={i}>
                                <div className="relative">
                                  <a onClick={(e) => {item?.name == 'Add Account' ? addAccounts(user) : this.handleLogout(e)}} className={`flex text-xs text-gray-600 items-center p-2 ${i == 0 ? 'border-b' : ''} hover:bg-gray-100 cursor-pointer`}>
                                    {item.name}
                                  </a>
                                </div>
                              </Menu.Item>
                            ))}
                          </React.Fragment>
                        }

                        {accounts.length > 0 &&
                          <React.Fragment>
                            {accounts.map((item, i) => (
                              <Menu.Item key={i}>
                                <div className="relative">
                                  <a className="flex items-center p-2 border-b hover:bg-gray-100 cursor-pointer" onClick={() => checkAndSwitchAccount(item, user)}>
                                    <div className='w-4'>
                                      {item?.login &&
                                        <img className="w-4" src="/assets/switch-account.png" />
                                      }
                                    </div>
                                    <div className= "ml-2">
                                      <span className="text-gray-700 block text-xs font-light">{item.business}</span>
                                      <span className="text-gray-700 block text-xs font-light">{item.email}</span>
                                    </div>
                                  </a>
                                  <button className="absolute right-4 top-5" onClick={(e) => this.delete(e, item?.id)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3" viewBox="0 0 20 20" fill="currentColor">
                                      <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
                                    </svg>
                                  </button>
                                </div>
                              </Menu.Item>
                            ))}
                          </React.Fragment>
                        }                      
                      </div>
                      {accounts.length > 0 &&
                        <div className="bg-gray-50 w-64">
                          <div className="flex justify-center p-2 gap-5 shadow-lg">
                            <a className="bg-gray-100 text-center border text-xs p-2 w-24 cursor-pointer" onClick={(e) => addAccounts(user)}>Add Account</a>
                            <a href="#" className="text-center bg-gray-100 border text-xs p-2 w-24 cursor-pointer" onClick={(e) => this.handleLogout(e)}>Log Out</a>
                          </div>
                        </div>
                      }
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        </div>
        {deleteModal &&
          <CustomPopupContainerComponent
            show={deleteModal}
            close={this.closeModal}
            title={'Delete Account'}
          >
            <DeleteAccountComponent deleteAccount={this.processDelete} disableDeleteButton={disableDeleteButton}/>
          </CustomPopupContainerComponent>
        }
      </React.Fragment>
    )
  }
}