import React, { useState, useRef, useEffect } from "react";
import AngleIcon from '../../../subRequests/assets/angleIcon'
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`

export default function PdfPreview({file}) {
  const [numPages, setNumPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const containerRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState(400);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setCurrentPage(1);
  };

  const goToNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, numPages));
  };

  const goToPrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  useEffect(() => {
    if (containerRef.current) {
      setContainerWidth(containerRef.current.offsetWidth);
    }
    
    const handleResize = () => {
      if (containerRef.current) {
        setContainerWidth(containerRef.current.offsetWidth);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div
      ref={containerRef}
      classNames={"w-full"}
    >
      <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
        <Page pageNumber={currentPage} width={Math.min(containerWidth, 900)}/>
      </Document>

      {numPages > 1 &&
        <div className="month-selector-filter flex items-center" style={{ marginTop: "10px" }}>
          <div className="flex">
            <button className="month-change-button month-change-left-button" onClick={goToPrevPage} disabled={currentPage === 1}>
              <AngleIcon classNames={'ml-auto text-gray-900 h-5 w-5'}/>
            </button>
            <span> Page {currentPage} of {numPages} </span>
            <button className="month-change-button month-change-right-button ml-2" onClick={goToNextPage} disabled={currentPage === numPages}>
              <AngleIcon classNames={'ml-auto text-gray-900 h-5 w-5'}/>
            </button>
          </div>
        </div>
      }
    </div>
  );
};