import React, { Fragment, useState, useEffect } from "react";
import { PushNotificationIcon, TextNotificationIcon, MailNotificationIcon, InfoIcon } from './../../../../subRequestDetails/assets';
import ReactTooltip from "react-tooltip";
import { SendIcon } from "./../../../../subRequests/assets";
import { LoadingIcon } from './../../../../subRequests/assets';

export default function InstructorTableComponent(props) {
  const { instructors, instructorsList, instructorMessage, setNewSubRequest, showDisableInstructors, business, newSubRequest, invalidUserIds, requestFrom, handleNotifyManager, loadingId, showLoading} = props
  const [ selectedUser, setSelectedUser ] = useState([])
  const [ usersIds, setUsersIds ] = useState([])
  const [ inviteMoreUsers, setInviteMoreUsers ] = useState(instructors);

  useEffect(() => {
    setInviteMoreUsers(instructors);
    if (instructors) {
      setUsersIds(instructors.filter(item => !item?.invalid_user).map(item => item.id));
    }
  }, [instructors])

  useEffect(() => {
    if (instructorsList && !newSubRequest?.allowPersonalInvites) {
      setSelectedUser(instructorsList?.map(item => item.id) || [])
    } else if (instructorsList && newSubRequest?.allowPersonalInvites && requestFrom == 'step_2_button' ) {
      setSelectedUser([])
    }
  }, [instructorsList])

  useEffect(() => {
    let insts = instructorsList?.filter(item => selectedUser.includes(item.id) && !item?.invalid_user)
    handleUpdateInstructor(insts)
  }, [selectedUser])

  const checkAllPeopleSelect = () => {
    let check = (usersIds.every(v => selectedUser.includes(v)) && usersIds?.length !== 0)
    return check
  }

  const handleSelectAllPeople = (e) => {
    if (checkAllPeopleSelect()) {
      setSelectedUser(selectedUser.filter(id => !usersIds.includes(id)));
    } else {
      setSelectedUser([...new Set([...selectedUser, ...usersIds])]);
    }
  }

  const handleSelectPeople = (e) => {
    const userId = parseInt(e.target.value);
    const isChecked = e.target.checked;

    if (isChecked) {
      setSelectedUser(prevSelectedUsers => [...prevSelectedUsers, userId]);
    } else {
      setSelectedUser(prevSelectedUsers => prevSelectedUsers.filter(id => id !== userId));
    }
  };

  const handleUpdateInstructor = (instructors) => {
    setNewSubRequest(prevNewSubRequest => ({
      ...prevNewSubRequest,
      invitedInstructors: instructors
    }))
  }

  const checkInvalidUser = (id) => {
    return invalidUserIds.includes(parseInt(id));
  }

  return(
    <Fragment>
      <div className="relative max-h-72 shadow border overflow-auto border-b border-gray-200 rounded-2xl mb-4">
        {showLoading &&
          <div className={`w-6 absolute left-0 right-0 mx-auto top-1/2 z-10 -mt-3`}>
            <LoadingIcon classNames={'animate-spin h-6 w-6 text-black'}/>
          </div>
        }
        {inviteMoreUsers.length > 0 ?
          <table className='min-w-full divide-y divide-gray-200'>
            <thead className='bg-gray-50'>
              <tr>
                {(business?.invite_all_default || newSubRequest?.allowPersonalInvites) &&
                  <th className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    <div>
                      <input
                        type="checkbox"
                        className={`w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-1 ${showDisableInstructors ? 'cursor-not-allowed opacity-50' : ''}`} 
                        checked={checkAllPeopleSelect() == true ? true : false}
                        value={'all'}
                        onChange={(e) => handleSelectAllPeople(e)}
                        disabled={showDisableInstructors}
                      />
                    </div>
                  </th>
                }
                <th className={`px-3 py-3 text-left tracking-wider whitespace-nowrap`}>
                  <div className='flex items-center gap-1'>
                  {(business?.invite_all_default || newSubRequest?.allowPersonalInvites) &&
                    <span className='font-semibold text-sm text-gray-900'>Select All</span>
                  }
                    <span className='font-semibold text-xs text-gray-500 mt-0.5'>{`${checkAllPeopleSelect() ? 'All Instructors Selected' : ''}`}</span>
                  </div>
                </th>
                <th className={`px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap`}>
                  <div className='flex items-center'>
                    <span className=''>Matched Skills</span> &nbsp; &nbsp;
                  </div>
                </th>
                <th className={`px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider`}>
                  <div className='flex items-center'>
                    <span className=''>Location</span> &nbsp; &nbsp;
                  </div>
                </th>
                <th className={`px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap`}>
                  <div className='flex items-center'>
                    <span className=''>Notification Preference</span> &nbsp; &nbsp;
                  </div>
                </th>
                {newSubRequest?.allowPersonalInvites &&
                  <th className={`px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap`}>
                    <div className='flex items-center'>
                      <span className=''>Alerts</span> &nbsp; &nbsp;
                    </div>
                  </th>
                }
              </tr>
            </thead>
            <tbody className='divide-y divide-gray-200'>
              {inviteMoreUsers.map((usr, i) => 
                <tr key={i} className={`bg-white relative ${checkInvalidUser(usr?.id) ? 'bg-gray-50' : ''}`}>
                  {(business?.invite_all_default || newSubRequest?.allowPersonalInvites) &&
                    <td className='px-3 py-4 text-sm font-medium text-gray-900'>
                      <input
                        type="checkbox"
                        className={`w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-1 ${showDisableInstructors ? 'cursor-not-allowed opacity-50' : ''}`}
                        value={usr?.id}
                        checked={checkInvalidUser(usr?.id) ? false : selectedUser.includes(usr?.id)}
                        onChange={(e) => handleSelectPeople(e)}
                        disabled={checkInvalidUser(usr?.id) || showDisableInstructors}
                      />
                    </td>
                  }
                  <td className='px-3 py-3'>
                    <div className="flex gap-2">
                      <div className="w-6 h-6 rounded-full bg-gray-300 shrink-0">
                        <img className="w-full h-full rounded-full" src={usr?.image ||"/assets/default-profile.jpg"}/>
                      </div>
                      <div className="flex flex-wrap items-center gap-2">
                        <div className='text-sm font-medium text-gray-900'>
                          {usr.full_name}
                        </div>
                        {!usr?.active &&
                          <div className="text-sm text-gray-600 -ml-1">
                            Inactive
                          </div>
                        }
                        {usr?.home_club_name && usr?.home_club_name !== '' &&
                          <div className="text-sm font-light text-gray-900">
                            {`(${usr?.home_club_name})`}
                          </div>
                        }
                      </div>
                    </div>
                  </td>
                  <td className='px-3 py-3'>
                    <div className='text-sm text-gray-900 font-medium whitespace-nowrap'>
                      {usr?.user_matched_skills !== '' ? usr?.user_matched_skills : '-'}
                    </div>
                  </td>
                  <td className='px-3 py-3'>
                    <div className='text-sm font-medium text-gray-900'>
                      {usr?.location_name !== '' ? usr?.location_name : '-'}
                    </div>
                  </td>
                  <td className='px-3 py-3'>
                    <div className='flex gap-x-2 items-center text-gray-900'>
                      <div data-tip data-for={usr.id.toString()+'user_notification_preference'}>
                        <InfoIcon classNames={"h-3 w-3 shrink-0"}/>
                      </div>
                      <ReactTooltip className='max-w-xs' id={usr.id.toString()+'user_notification_preference'} place="top" effect="solid">
                        {usr?.daily_notifications == 'Immediate' ? 'Receiving every sub request invite immediately at the time it was sent.' : 'Receiving sub request invites once per day as a daily summary at 6pm.'}
                      </ReactTooltip>
                      <Fragment>
                        <div className='text-sm font-medium'>
                          {usr?.daily_notifications}
                        </div>
                        {usr?.push_notifications &&
                          <Fragment>
                            <div className="flex items-center justify-center w-6 h-6 rounded-full border border-gray-300 shrink-0" data-tip data-for={usr.id.toString()+'user_push_notification_preference'}>
                              <PushNotificationIcon classNames={`w-3 h-3 shrink-0`}/>
                            </div>
                            <ReactTooltip className='max-w-xs' id={usr.id.toString()+'user_push_notification_preference'} place="top" effect="solid">
                              {'Push Notifications'}
                            </ReactTooltip>
                          </Fragment>
                        }
                        {usr?.sms_notifications &&
                          <Fragment>
                            <div className="flex items-center justify-center w-6 h-6 rounded-full border border-gray-300 shrink-0" data-tip data-for={usr.id.toString()+'user_text_notification_preference'}>
                              <TextNotificationIcon classNames={`w-3 h-3 shrink-0`}/>
                            </div>
                            <ReactTooltip className='max-w-xs' id={usr.id.toString()+'user_text_notification_preference'} place="top" effect="solid">
                              {'Text Notifications'}
                            </ReactTooltip>
                          </Fragment>
                        }
                        {usr?.email_notifications &&
                          <Fragment>
                            <div className="flex items-center justify-center w-6 h-6 rounded-full border border-gray-300 shrink-0" data-tip data-for={usr.id.toString()+'user_mail_notification_preference'}>
                              <MailNotificationIcon classNames={`w-3 h-3 shrink-0`}/>
                            </div>
                            <ReactTooltip className='max-w-xs' id={usr.id.toString()+'user_mail_notification_preference'} place="top" effect="solid">
                              {'Email Notifications'}
                            </ReactTooltip>
                          </Fragment>
                        }
                      </Fragment>
                    </div>
                  </td>
                  {newSubRequest?.allowPersonalInvites &&
                    <td className='px-3 py-3'>
                      <div className='flex gap-x-2 items-center text-gray-900'>
                        {checkInvalidUser(usr?.id) ?
                          <Fragment>
                            <div data-tip data-for={usr.id.toString()+'notify_manager'}>
                              <InfoIcon classNames={"h-3 w-3 shrink-0"}/>
                            </div>
                            <ReactTooltip className='max-w-xs' id={usr.id.toString()+'notify_manager'} place="top" effect="solid">
                              {usr?.invalid_user_msg}
                            </ReactTooltip>
                            <button className={`relative flex items-center px-4 py-2 rounded-2xl text-xs font-semibold h-8 gap-2 bg-dark-blue text-white shrink-0 ${loadingId !== null ? 'cursor-not-allowed' : ''} ${loadingId === usr?.id ? 'opacity-50' : ''}`}
                              onClick={() => handleNotifyManager(usr?.id)}
                              disabled={loadingId !== null}
                            >
                              Notify Manager
                              {loadingId === usr?.id ? 
                                <div className="flex items-center right-1 justify-center">
                                  <svg className="animate-spin h-3.5 w-3.5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                  </svg>
                                </div>
                              :
                                <SendIcon classNames={'w-3.5 h-3.5 transform text-white -rotate-45 mb-0.5 ml-0.5 shrink-0'}/>
                              }
                            </button>
                          </Fragment>
                        :
                          <Fragment>
                            {usr?.unavailable_classes_count > 0 &&
                              <Fragment>
                                <div data-tip data-for={usr.id.toString()+'unavailable_classes'}>
                                  <InfoIcon classNames={"h-3 w-3"}/>
                                </div>
                                <ReactTooltip className='max-w-xs' id={usr.id.toString()+'unavailable_classes'} place="top" effect="solid">
                                  {usr?.unavailable_classes}
                                </ReactTooltip>
                              </Fragment>
                            }
                            <div className='text-sm font-medium'>
                              {usr?.unavailable_classes_count > 0 ?
                                `${usr?.unavailable_classes_count} Unavailable Class${usr?.unavailable_classes_count > 1 ? 'es' : ''}`
                              :
                                '-'
                              }
                            </div>
                          </Fragment>
                        }
                      </div>
                    </td>
                  }
                </tr>
              )}
            </tbody>
          </table>
        :
          <div className='text-sm font-medium text-gray-900 px-4 py-4'>
            No users found
          </div>
        }
      </div>
    </Fragment>
  )
}