import React, { Fragment, useState } from "react";
import { axiosRequest } from '../../../subRequests/helpers/helpers';
import { formatTimestampToDate } from '../../helpers';
import { useAnalytics } from '../../../utils/analytics';
import { SendIcon } from '../../assets';
import LoadingIcon from '../../../subRequests/assets/loadingIcon';
import { apiRoutes } from '../../../constants';

export default function SubmitButton(props) {
  const { documentation, handleShowAlert, submitComplete, pageType, fileToUpload } = props
  const { track } = useAnalytics();
  const [ submitBtnDisabled, setSubmitBtnDisabled ] = useState(false)

  const checkDisabledCondition = () => {
    return(pageType == 'view' || submitBtnDisabled || (documentation?.key_url == '' && fileToUpload?.status !== "uploaded") || documentation?.name === '' || (documentation?.category?.expiration_date_required && documentation?.expiration_date == ''))
  }

  const processUploadResource = () => {
    const formData = new FormData();
    formData.append("file", fileToUpload?.file);
    formData.append("id", documentation?.id);
    
    return (axiosRequest(apiRoutes.documentationUpload, 'post', formData, 'content').then((response) => {
      return response;
    })
    .catch((error) => {
      return ({error: error, status: 500});
    }))
  }

  const handleSubmitDocumentation = async (e) => {
    e.preventDefault();
    setSubmitBtnDisabled(true)
    let response = {status: 200};
    if(Boolean(fileToUpload) && fileToUpload?.status == "uploaded"){
      response = await processUploadResource();
    }
		if(response.status === 200) {
      processSubmit()
		}else{
			handleShowAlert(true, response?.error, 'danger')
      setTimeout(() => {
        handleShowAlert(false, '', '')
        setSubmitBtnDisabled(false)
      }, 3000)			
		}
  }

  const processSubmit = () => {
    let params = {
      id: documentation?.id,
      name: documentation?.name,
      expiration_date: documentation?.expiration_date == '' ? '' : formatTimestampToDate(documentation?.expiration_date),
      action_type: pageType
    }
    axiosRequest(apiRoutes.documentationUpdateStatus, 'POST', params, 'data').then((response) => {
      if(response.status === 200){
        handleShowAlert(true, response?.message, 'success')
        track('certification-submitted');
        setTimeout(() => {
          handleShowAlert(false, '', '')
          submitComplete('view', response?.documentation)
          setSubmitBtnDisabled(false)
        }, 3000)
      }else{
        handleShowAlert(true, response?.error, 'danger')
        setSubmitBtnDisabled(false)
        setTimeout(() => {
          handleShowAlert(false, '', '')
        }, 3000)
      }
    })
    .catch((error) => {
      handleShowAlert(true, error, 'danger')
      setSubmitBtnDisabled(false)
      setTimeout(() => {
        handleShowAlert(false, '', '')
      }, 3000)
    })
  }

  return(
    <Fragment>
      <button className={`flex bg-dark-blue border border-gray-900 sm:text-base text-sm text-white inline-block px-5 py-2 border shadow rounded-lg relative gap-2 items-center ${(checkDisabledCondition()) ? 'cursor-not-allowed opacity-50' : 'hover:bg-gray-800'}`} onClick={(e) => handleSubmitDocumentation(e)} disabled={checkDisabledCondition()}>
        {submitBtnDisabled ?
          <LoadingIcon classNames={'animate-spin h-4 w-4 text-white'}/>
        :
          <SendIcon classNames={'text-white h-4 w-4'}/>
        }
        <div className='text-white text-base'>Submit</div>
      </button>
    </Fragment>
  )
}