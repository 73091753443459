import React, { Fragment } from "react";
import BreadCrumbs from './breadCrumbs';
import TopButtons from './topButtons';
import SubmitButton from './submitButton';
import DiscardButton from './discardButton';
import RenewButton from './renewButton';
import EditButton from './editButton';

export default function HeaderComponent(props) {
  const { loaded, pageType, documentation, handleShowAlert, handleRedirect, handleDiscard, viewChange, submitComplete, setFileToUpload, fileToUpload } = props
  return (
    <Fragment>
      <BreadCrumbs />
      <div className="flex justify-between gap-2 mb-5 flex-wrap">
        <TopButtons name={'Back'}/>
        {loaded ?
          <div className="flex item-center gap-2 flex-wrap">
            {(!['view', 'add'].includes(pageType)) && <DiscardButton onClick={(e) => handleDiscard()}/>}
            {pageType === 'view' &&
              <Fragment>
                <RenewButton status={documentation?.documentation_status} viewChange={viewChange}/>
                {documentation?.documentation_status == 'Submitted' && <EditButton viewChange={viewChange}/>}
              </Fragment> 
            }
            {pageType === 'add' && <TopButtons name={'Discard Changes'}/>}
            <SubmitButton
              documentation={documentation}
              handleShowAlert={handleShowAlert}
              handleRedirect={handleRedirect}
              submitComplete={submitComplete}
              pageType={pageType}
              fileToUpload={fileToUpload}
            />
          </div>
          :
          <div></div>
        }
      </div>
    </Fragment>
  )
}