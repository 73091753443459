import React, { Fragment } from "react";
import DocumentationIcon from '../../assets/documentationIcon';

export default function EmptyCategories({type}) {

  const pageText = {
    'my-documentation': 'No required documentation.',
    'instructor-documentation': '0 users have been assigned documentation categories. Go to Settings > Users to assign categories to users.',
    'categories': 'No categories found'
  }

  return(
    <Fragment>
      <div className='bg-white shadow-md empty-screen-table-custom-height border border-gray-300 px-6 pt-7 flex justify-center rounded-b-lg items-center'>
        <div className='flex flex-col items-center w-full justify-center h-56'>
          <div className='flex flex-col items-center'>
            <div className='flex flex-col mb-4'>
              <div className='w-28 rounded p-1 relative'>
                <DocumentationIcon classNames={'w-28 h-28'} strokeColor="#6B7280"/>
              </div>
            </div>
            <div>
              <div className='text-gray-500 text-sm mb-3'>{pageText[type]}</div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}