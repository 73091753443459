import React from "react";

export const currentYear = new Date().getFullYear();
export const allMonths = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

export const checkCurentMonth = (today) => {
  return today.toLocaleString('default', { month: 'long' })
}

export const checkMonthNameAfterAddition = (today, value) => {
  let dateCopy = new Date(today.getTime());
  let currentMonthName = dateCopy.toLocaleString('default', { month: 'long' })
  let all_months = allMonths
  let indexOfCurrentMonth = all_months.indexOf(currentMonthName)
  let data = (indexOfCurrentMonth + 1) + parseInt(value)
  if (data > all_months.length){
    data = data - (all_months.length)
  }
  let index = 0
  if( data > 0){
    index = data - 1
  }
  return all_months[index]
}

export const checkMonthNameAfterCertainAddition = (today, value, prevAdditionalMonth) => {
  let dateCopy = new Date(today.getTime());
  let currentMonthName = dateCopy.toLocaleString('default', { month: 'long' })
  let all_months = allMonths
  let indexOfCurrentMonth = all_months.indexOf(currentMonthName)
  let data = (indexOfCurrentMonth + 1) + parseInt(value) + parseInt(prevAdditionalMonth)
  if (data > all_months.length){
    data = data - (all_months.length)
  }
  let index = 0
  if( data > 0){
    index = data - 1
  }
  return all_months[index]
}

export const defaultPolicy = (business) => {
  let businessType = fetchBusinessType(business);

  return ({
    id: '',
    name: 'Default',
    allowCertainDate: false,
    enableRequestSendingTimeCheck: false,
    additionalMonths: thresholdMap()[businessType],
    additionalMonthsName: checkMonthNameAfterAddition( new Date(), 0 ),
    certainDate: 1,
    certainDateAdditionalMonth: 0,
    certainDateAdditionalMonthName: checkMonthNameAfterCertainAddition( new Date(), 0, 0 ),
    timeBeforeClass: 1,
    timeBeforeClassType: 'days',
    enableBlackoutDates: false,
    blackoutDates: [],
    skillIds: [],
    default: false
  })
}

export const getRenderedSubPolicies = (timeFrames) => {
  return (timeFrames.map((subPolicy) => {return {
            id: subPolicy?.id,
            name: subPolicy?.name,
            allowCertainDate: subPolicy?.allow_certain_date,
            enableRequestSendingTimeCheck: subPolicy?.enable_request_sending_time_check,
            additionalMonths: subPolicy?.additional_months,
            additionalMonthsName: checkMonthNameAfterAddition( new Date(), subPolicy?.additional_months ),
            certainDate: subPolicy?.certain_date,
            certainDateAdditionalMonth: subPolicy?.certain_date_additional_month,
            certainDateAdditionalMonthName: checkMonthNameAfterCertainAddition( new Date(), subPolicy?.certain_date_additional_month, subPolicy?.additional_months ),
            timeBeforeClass: subPolicy?.time_before_class,
            timeBeforeClassType: subPolicy?.time_before_class_type,
            enableBlackoutDates: subPolicy?.blackout_date_check,
            blackoutDates: subPolicy?.blackout_dates.map((item, i) => {return { 'id': i, 'startDate': new Date(item), 'endDate': new Date(item), color: 'yellow' }}),
            skillIds: subPolicy?.skill_ids,
            default: subPolicy?.default
          }})
  )
}

export const removeSkillsExceptAtIndex = (timeframes, index, skillIdsToRemove) => {
  timeframes.forEach((hash, i) => {
    if (i === index) return;
    hash.skillIds = hash.skillIds.filter(skillId => !skillIdsToRemove.includes(skillId));
  });
  return timeframes
}

export const timeframesToSave = (timeframes) => {
  return (timeframes.map((subPolicy) => {return {
            id: subPolicy?.id,
            name: subPolicy?.name,
            additional_months: parseInt(subPolicy?.additionalMonths),
            allow_certain_date: subPolicy?.allowCertainDate,
            certain_date: parseInt(subPolicy?.certainDate),
            certain_date_additional_month: parseInt(subPolicy?.certainDateAdditionalMonth),
            enable_request_sending_time_check: subPolicy?.enableRequestSendingTimeCheck,
            time_before_class: parseInt(subPolicy?.timeBeforeClass),
            time_before_class_type: subPolicy?.timeBeforeClassType,
            blackout_dates: subPolicy?.blackoutDates.map((item) => item?.startDate.getFullYear().toString()+'-'+(item?.startDate.getMonth()+1).toString()+'-'+item?.startDate.getDate().toString()),
            blackout_date_check: subPolicy?.enableBlackoutDates,
            skill_ids: subPolicy?.skillIds,
            default: subPolicy?.default
          }})
  )
}

export const basicBusiness = (business) => {
  return !(business?.mbo_integration || business?.abc_integration || business?.clubready_integration || business?.fisikal_integration || business?.marianatek_integration || business?.crunch_integration)
}

export const integratedBusiness = (business) => {
  return (business?.mbo_integration || business?.abc_integration || business?.clubready_integration || business?.fisikal_integration || business?.marianatek_integration)
}

export const fetchCertainAddedMonthVal = (value, subPolicy, certainMonthVal) => {
  return (parseInt(value) + (subPolicy?.allowCertainDate ? certainMonthVal : 0))
}

export const fetchAddedMonthVal = (value, addedMonthVal) => {
  return (parseInt(value) + addedMonthVal)
}

export const fetchBusinessType = (business) => {
  return (
    integratedBusiness(business) ? "integrated" 
    : basicBusiness(business) ? "basic" 
    : business?.crunch_integration ? "crunch" 
    : null
  )
}

export const calculateAddedMonthValue = (business, value, subPolicy, certainDateAdditionalMonthVal) => {
  let businessType = fetchBusinessType(business);

  if (businessType) {
    const threshold = thresholdMap()[businessType];
    const addedMonthVal = fetchCertainAddedMonthVal(value, subPolicy, certainDateAdditionalMonthVal);
    
    if (addedMonthVal > threshold) {
      return Math.max(threshold - (subPolicy?.allowCertainDate ? certainDateAdditionalMonthVal : 0), 0);
    }
  }

  return value.replace(/^0+/, '');
};

export const calculateCertAdditionalMonthValue = (business, value, addedMonthVal) => {
  let businessType = fetchBusinessType(business);

  if (!businessType) {
    return Math.max(parseInt(value.replace(/^0+/, ''), 10) || 0, 0);
  }

  const threshold = thresholdMap()[businessType];
  const addedMonthValue = fetchAddedMonthVal(value, addedMonthVal);

  if (addedMonthValue > threshold) {
    return Math.max(threshold - addedMonthVal, 0);
  }

  if (value > threshold) {
    return Math.max(threshold - addedMonthVal, 0);
  }

  return Math.max(parseInt(value.replace(/^0+/, ''), 10) || 0, 0);
};

const thresholdMap = () => {
  return ({
    integrated: 3,
    basic: 5,
    crunch: 1
  })
}

export function checkDefaultTimeframe(timeframes) {
  return(
    timeframes.some((timeframe) => timeframe?.default)
  )
}