import React, { Fragment, useState } from "react";
import ResetSelectionComponent from '../../../../../settings-v4/users/usersPageIntegrationComponents/components/filtersAndButtons/resetSelectionComponent/resetSelectionComponent';
import AlertPopup from '../../../../../messenger/pages/chatComponents/assets/alertPopup';
import AssignPopupContentComponent from '../../assignPopupContentComponent';
import CancelMassActionPopupComponent from './cancelMassActionPopupComponent';
import AssignMassSubComponent from './assignMassSubComponent';
import CancelMassSubComponent from './cancelMassSubComponent';

export default function SubRequestMassActionBar(props) {
  const { selectedEvents, setSelectedEvents, selectEventLocations, setSelectEventLocations, disabledEventIds, handleUpdateListData } = props
  const [ showCancelOptionsPopup, setShowCancelOptionsPopup ] = useState(false)
  const [ showAlert, setShowAlert ] = useState(false)
  const [ alertMessage, setAlertMessage ] = useState('')
  const [ alertType, setAlertType ] = useState('')
  const [ showAssignPopup, setShowAssignPopup ] = useState(false)
  const [ btnDisabled, setBtnDisabled ] = useState(selectedEvents?.length == 0 || false)

  const checkEligibleEventsSelected = () => {
    return (selectedEvents?.length > 0 && disabledEventIds?.length > 0 && selectedEvents?.some(v => disabledEventIds?.includes(v)))
  }

  const checkEligibleLocationSelected = () => {
    return (selectEventLocations?.length > 1)
  }

  const handleSetClear = () => {
    setSelectedEvents([])
    setSelectEventLocations([])
  }

  const closeAlert = () => {
    setShowAlert(false)
    setAlertMessage('')
    setAlertType('')
  }

  const closeAssignPopup = (data) => {
    setShowAssignPopup(false)
  }

  const handleSetAlert = (show, message, type) => {
    setShowAlert(show)
    setAlertMessage(message)
    setAlertType(type)
  }

  const handleUpdateEvents = (data) => {
    handleUpdateListData()
  }

  const closeCancelOptionsPopup = (data) => {
    setShowCancelOptionsPopup(false)
  }

  return(
    <Fragment>
      {showAlert &&
        <AlertPopup alert_type={alertType} alert_message={alertMessage} closeAlert={closeAlert} />
      }
      {showAssignPopup &&
        <AssignPopupContentComponent show={showAssignPopup} eventId={selectedEvents} close={closeAssignPopup} assignPopupType={'mass-assign-action'} handleSetAlert={handleSetAlert} approvedUserId={selectEventLocations[0] || ''} processFetchEventData={handleUpdateEvents} type={'mass-assign-action'} calendarEvent={false} handleUpdateCalendarEvent={() => {}}/>
      }
      {showCancelOptionsPopup &&
        <CancelMassActionPopupComponent showCancelOptionsPopup={showCancelOptionsPopup} closeCancelOptionsPopup={closeCancelOptionsPopup} handleSetAlert={handleSetAlert} handleUpdateEvents={handleUpdateEvents} eventIds={selectedEvents}/>
      }
      <tr className="bg-dark-blue">
        <td colSpan={`10`}>
          <div className="flex justify-between px-8 items-center bg-dark-blue text-white py-3">
            <div className="text-sm">{selectedEvents?.length} request(s) selected</div>
            <div className="flex items-center gap-2">
              <AssignMassSubComponent setShowAssignPopup={setShowAssignPopup} dueToInvalidEvents={checkEligibleEventsSelected()} dueToNotEligibleLocation={checkEligibleLocationSelected()}/>
              <CancelMassSubComponent setShowCancelActionsPopup={setShowCancelOptionsPopup} dueToInvalidEvents={checkEligibleEventsSelected()}/>
              <ResetSelectionComponent setTheState={handleSetClear}/>
            </div>
          </div>
        </td>
      </tr>
    </Fragment>
  )
}