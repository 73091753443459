function getHeaders(token, subdomain){  
  if (token !== undefined) {
    let obj = {
      'Accept': 'application/vnd.netgym.v3',
      'Content-Type': 'application/json',
      'Authorization': eval(`${token}`),
      'subdomain': localStorage.getItem("businessID")
    }
    return obj
  }  
}

export const getClasses = async (user_token, subdomain, page) => {    
  return await fetch(`/api/v3/business_classes?page=${page}&pageSize=30`,{
    headers: getHeaders(user_token, subdomain)
  });  
}

export const createClass = async (user_token, subdomain, cls) => {  
  return await fetch(`/api/v3/business_classes`,{
    method: "POST",
    headers: getHeaders(user_token, subdomain),
    body: JSON.stringify({ business_class: {name: cls.name, description: cls.description, external_id: cls.external_id, mindbody_id: cls.mindbody_id, mbo_site_id: cls.mbo_site_id}, business_category: cls.business_category, second_skill: cls.second_skill })
  });
}

export const updateClass = async (user_token, subdomain, cls) => {  
  return await fetch(`/api/v3/business_classes/${cls.id}`,{
    method: "PATCH",
    headers: getHeaders(user_token, subdomain),
    body: JSON.stringify({ business_class: {name: cls.name, description: cls.description, external_id: cls.external_id, mindbody_id: cls.mindbody_id, mbo_site_id: cls.mbo_site_id}, business_category: cls.business_category, second_skill: cls.second_skill })
  });
}

export const deleteClass = async (user_token, subdomain, cls) => {  
  return await fetch(`/api/v3/business_classes/${cls.id}`,{
    method: "DELETE",
    headers: getHeaders(user_token, subdomain)
  });
}

export const syncClasses = async (user_token, subdomain, type, siteId) => {    
  let url = "";
  switch(type){
    case 'mbo':
      url = `/api/v3/mindbody/fetch_classes?site_id=${siteId}&time_frame=today`
      break;
    case 'marinatek': 
      url = '/api/v3/marianatek/fetch_classes'
      break;
    case 'abc': 
      url = `/api/v3/abc/fetch_classes?locations=${siteId}`;
      break;
  }
  return await fetch(url,{
    headers: getHeaders(user_token, subdomain)
  });
}

export const processSyncClasses = async (user_token, subdomain, type, siteId, classes) => {    
  let url = "";
  switch(type){
    case 'mbo':
      url = '/api/v3/upload_selected_mbo_classes'
      break;
    case 'marinatek':
      url = '/api/v3/upload_selected_marianatek_classes'
      break;
    case 'abc':
      url = '/api/v3/upload_selected_abc_classes'
      break;
  }

  if(type === 'abc'){
    return await fetch(`${url}`,{
      headers: getHeaders(user_token, subdomain),
      method: "POST",
      body: JSON.stringify({ classes: classes,locations: siteId})
    });
  }else{
    return await fetch(`${url}`,{
      headers: getHeaders(user_token, subdomain),
      method: "POST",
      body: JSON.stringify({ classes: classes,site_id: siteId})
    });
  }  
}

export const getAbcRegions = async (user_token, subdomain) => {
  return await fetch(`/api/v3/get_all_regions`,{
    headers: getHeaders(user_token, subdomain)
  });  
}

export const getRegionLocations = async (user_token, subdomain, id) => {
  return await fetch(`/api/v3/get_location_business_regions?business_region_id=${id}`,{
    headers: getHeaders(user_token, subdomain)
  });  
}

export const exportClasses = async (user_token, subdomain) => {
  let url = "/api/v3/export_classes";  
  return await fetch(`${url}`,{
    headers: getHeaders(user_token, subdomain)    
  });
}

export const downloadClasses = async (user_token, subdomain, type, siteId) => {
  let url = "";
  switch(type){
    case 'mbo':
      url = '/api/v3/upload_selected_mbo_classes'
      break;
    case 'marinatek':
      url = '/api/v3/export_marianatek_classes'
      break;
    case 'abc':
      url = `/api/v3/export_abc_classes`
      break;
  }
  if(type == 'abc'){
    return await fetch(`${url}`,{
      headers: getHeaders(user_token, subdomain),
      method: "POST",
      body: JSON.stringify({ locations: siteId})
    });
  }else{
    return await fetch(`${url}`,{
      headers: getHeaders(user_token, subdomain)    
    });
  }
  
}

export const filterClasses = async (user_token, subdomain, page, text, sortType, sortReverse, siteIds) => {    
  return await fetch(`/api/v3/get_filtered_classes?text=${text}&page=${page}&pageSize=30&sortType=${sortType}&sortReverse=${sortReverse}&siteIds=${siteIds}`,{
    headers: getHeaders(user_token, subdomain)
  });  
}

export const uploadClasses = async (user_token, subdomain, file) => {
  const formData = new FormData();
  formData.append(
    "uploadFile",
    file,
    file.name
  );
    
  return await fetch(`/api/v3/upload_classes`,{
    method: "POST",    
    body: formData,
    headers: {
      'Accept': 'application/vnd.netgym.v3',
      'Authorization': eval(`${user_token}`),
      'subdomain': localStorage.getItem("businessID")
    }
  });
}

export const downloadRemoteData = async (user_token, subdomain, type, siteId, remoteClasses) => {
  let url = "";
  switch(type){
    case 'mbo':
      url = '/api/v3/export_mbo_classes'
      break;
    case 'marinatek':
      url = '/api/v3/export_marianatek_classes'
      break;
    case 'abc':
      url = `/api/v3/export_abc_classes`
      break;
  }
  
  return await fetch(`${url}`,{
    headers: getHeaders(user_token, subdomain),
    method: "POST",
    body: JSON.stringify({ site_id: siteId, classes: remoteClasses })
  });
}

export const getSkills = async (user_token, subdomain) => {    
  return await fetch(`/api/v3/business_categories`,{
    headers: getHeaders(user_token, subdomain)
  });  
}

export const createSkill = async (user_token, subdomain, business_category) => {  
  return await fetch(`/api/v3/business_categories`,{
    method: "POST",
    headers: getHeaders(user_token, subdomain),
    body: JSON.stringify({ business_category: business_category })
  });
}

export const syncBusinessClasses = async (user_token, subdomain, business) => {
  const url = business?.clubready_integration
    ? "/api/v3/clubready/sync_details?sync_type=classes"
    : business?.fisikal_integration
    ? "/api/v3/fisikal/sync_details?sync_type=classes"
    : "/api/v3/crunch/sync_classes";
  
  const response = await fetch(url, {
    method: "GET",
    headers: getHeaders(user_token, subdomain)
  });
  
  return response.json();
}

export const checkFilterSaved = (userId, subdomain, pageType) => {
  const lastFilters = JSON.parse(localStorage.getItem(`${pageType}Filters_${subdomain}`));
  return lastFilters?.uid === userId;
}

export const handleSaveFilters = (data, filterName, userId, subdomain, pageType) => {
  const defaultFilters = {
    sort: { type: '', order: '' },
    mboSiteIds: [],
    search: '',
    uid: userId
  };

  let lastFilters = checkFilterSaved(userId, subdomain, pageType)
    ? JSON.parse(localStorage.getItem(`${pageType}Filters_${subdomain}`)) || defaultFilters
    : defaultFilters;

  const filterMap = {
    sort: () => (lastFilters.sort = data),
    search: () => (lastFilters.search = data),
    mbosites: () => (lastFilters.mboSiteIds = data),
  };

  filterMap[filterName]?.();

  localStorage.setItem(`${pageType}Filters_${subdomain}`, JSON.stringify(lastFilters));
}