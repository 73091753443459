import React, { Fragment } from "react";
import { defaultPolicy } from '../helpers';

export default function AddTimeFrameComponent({subbingTimeFrames, setSubbingTimeFrames, disabled, business}) {

  const handleAddTimeFrame = (e) => {
    e.preventDefault();
    setSubbingTimeFrames([...subbingTimeFrames, defaultPolicy(business)])
  }

  return(
    <Fragment>
      <div className="mt-4 mb-0 sm:-mb-14 flex justify-end">
        <button className={`border rounded-md border-gray-300 shadow-sm text-gray-700 hover:bg-gray-100 text-sm py-2 px-4 block cursor-pointer ${disabled ? 'cursor-not-allowed opacity-50' : ''}`} onClick={(e) => handleAddTimeFrame(e)} disabled={disabled}>
          + Timeframe
        </button>
      </div>
    </Fragment>
  )
}