import React, { Fragment, useEffect, useState } from "react";
import HeadingComponent from "./headingComponent";
import BreadCrumbsComponent from "./breadCrumbsComponent";
import FirstStepComponents from '../firstStepComponents';
import SecondStepComponents from '../secondStepComponents';
import FinalStepComponents from '../finalStepComponents';
import { getLastSavedStep, getLastSavedNewSubRequest, getEditSubRequestData } from '../../helpers';
import LoaderComponent from '../../../subRequests/pages/components/loaderComponent/loaderComponent';
import TopButtonComponent from '../topButtonComponent';
import { useParams, Redirect } from 'react-router-dom';
import AlertPopup from '../../../messenger/pages/chatComponents/assets/alertPopup';
import { axiosGetRequest, integratedBusiness } from '../../../subRequests/helpers/helpers';

export default function CreatePageSectionComponent(props) {
  const { user, business, path, permissions } = props
  const { id } = useParams();
  const [ step, setStep ] = useState(getLastSavedStep(business?.id, user?.id, path) || 1)
  const [ loaded, setLoaded ] = useState(false)
  let lastSavedNewSubRequest = getLastSavedNewSubRequest(business?.id, user?.id)
  const [ newSubRequest, setNewSubRequest ] = useState((path !== 'edit-sub-request' && Object.keys(lastSavedNewSubRequest).length > 0) ? lastSavedNewSubRequest : {
    requestingInstructor: {id: user?.id, full_name: user?.full_name},
    approvedUser: {id: '', full_name: '', image: ''},
    locationId: '',
    timeFrame: '4_months',
    previousWeek: false,
    reason: {
      reason: '',
      otherReason: ''
    },
    schedules: [],
    comment: '',
    invitedInstructors: [],
    secondSkillCheck: [],
    mboSiteId: { site_id: '', site_name: '' },
    editSubRequest: path == 'edit-sub-request',
    allowPersonalInvites: false
  })
  const [ redirect, setRedirect ] = useState(false)
  const [ redirectUrl, setRedirectUrl ] = useState('')
  const [ showAlert, setShowAlert ] = useState(false)
  const [ alertType, setAlertType ] = useState('')
  const [ alertMessage, setAlertMessage ] = useState('')

  const redirectToHome = () => {
    setRedirectUrl('/my-requests')
    setRedirect(true)
  }

  const checkAndFetchSubRequest = async() => {
    let url = `/api/v3/business_events/${parseInt(id)}?preview=false`
    axiosGetRequest(url).then((result) => {
      if(result?.status === 200){
        if(!result?.event?.canEdit){
          redirectToHome()
          return;
        }
        let newData = getEditSubRequestData(newSubRequest, result?.event)
        setNewSubRequest(newData)
        setLoaded(true)
      }else{
        setAlertMessage(result?.error)
        setAlertType('danger')
        setShowAlert(true)
        setTimeout(()=>{
          setShowAlert(false)
          redirectToHome()
        },3000)
      }
    })
  }

  useEffect(() => {
    if (path == 'edit-sub-request') {
      if (!id || id == '' || id == 0 || integratedBusiness(business)) {
        redirectToHome();
        return;
      }
      setStep(1)
      checkAndFetchSubRequest()
    }else{
      setLoaded(true)
    }
  }, [])

  const closeAlert = (data) => {
    setShowAlert(data)
    setAlertType('')
    setAlertMessage('')
  }

  return (
    <Fragment>
      {redirect && <Redirect to={`${redirectUrl}`} />}
      {showAlert &&
        <AlertPopup alert_type={alertType} alert_message={alertMessage} closeAlert={closeAlert} />
      }
      <TopButtonComponent user={user} step={step} setStep={setStep} newSubRequest={newSubRequest} actionType={'exit'}/>
      <div className="w-full px-0 xl:px-24 special-container-class">
        <div className="rounded-2xl border-2 border-gray-300 w-full bg-white py-6 px-5 md:px-8 h-full create-sub-request-page-custom-width">
          {!loaded &&
            <LoaderComponent loadingMessage={''}/>
          }
          {loaded &&
            <Fragment>
              <HeadingComponent step={step} path={path}/>
              {path !== 'edit-sub-request' && <BreadCrumbsComponent step={step}/>}
              {step == 1 &&
                <FirstStepComponents 
                  user={user} 
                  business={business} 
                  newSubRequest={newSubRequest}
                  setNewSubRequest={setNewSubRequest}
                  setStep={setStep}
                  permissions={permissions}
                />
              }
              {step == 2 &&
                <SecondStepComponents 
                  user={user} 
                  business={business}
                  newSubRequest={newSubRequest}
                  setNewSubRequest={setNewSubRequest}
                  setStep={setStep}
                  step={step}
                />
              }
              {step == 3 &&
                <FinalStepComponents
                  newSubRequest={newSubRequest}
                  user={user}
                  setStep={setStep}
                  business={business}
                  step={step}
                />
              }
            </Fragment>
          }
        </div>
      </div>
    </Fragment>
  );
}