import React, { Fragment, useState } from 'react'
import { DateRangePicker } from 'react-date-range';
import { DownloadIcon } from './../../../assets'
import { checkFiltersState, axiosDownloadRequest } from '../../../helpers/helpers';
import DownloadCustomPopup from './downloadCustomPopup';

export default function DownloadComponent(props) {
  const { business, user, selectedStatus, selectedLocations, selectedInstructors, selectedClasses, selectedSkills } = props
  const currentDate = new Date();
  const [ startDate, setStartDate ] = useState(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1))
  const [ endDate, setEndDate ] = useState(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0))
  const [ showDownloadPopup, setShowDownloadPopup ] = useState(false)
  const [dateRange, setDateRange] = useState({
    startDate: startDate,
    endDate: endDate,
    key: 'selection',
  });

  const checkStartEndDate = () => {
    if(startDate === '' || endDate === ''){
      return false
    }else if(endDate < startDate){
      return false
    }else{
      return true
    }
  }

  const handleSelect = (ranges) => {
    setDateRange(ranges.selection);
    setStartDate(ranges.selection.startDate);
    setEndDate(ranges.selection.endDate);
  };

  const handleStart = (startDate) => {
    const formattedStartDate = startDate.toLocaleDateString('en-US', {
      weekday: 'short',
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    });
    return formattedStartDate
  }

  const handleCancel = (data) => {
    setShowDownloadPopup(data)
  }

  const handleSubmit = () => {
    if(startDate == '' || endDate == ''){
      return
    }
    let start_date = startDate.toLocaleDateString('en-GB')
    let end_date = endDate.toLocaleDateString('en-GB')
    let file_name = `${business?.subdomain}_future_requests.csv`
    let selectedInstructorsIds = selectedInstructors?.map(function(a) {return a?.id});
    let selectedClassesIds = selectedClasses?.map(function(a) {return a?.id});
    let selectedSkillsIds = selectedSkills?.map(function(a) {return a?.id});
    let instructor_status = ['all_selected', 'no_selected'].includes(checkFiltersState(user?.id, 'instructor'))
    let class_status = ['all_selected', 'no_selected'].includes(checkFiltersState(user?.id, 'class'))
    let skill_status = ['all_selected', 'no_selected'].includes(checkFiltersState(user?.id, 'skill'))
    let params = {
      start_date: start_date,
      end_date: end_date,
      location_ids: selectedLocations,
      instructor_ids: selectedInstructorsIds,
      class_ids: selectedClassesIds,
      statuses: selectedStatus,
      all_instructors: instructor_status,
      all_classes: class_status,
      all_skills: skill_status,
      skills_ids: selectedSkillsIds
    }
    let api = `/api/v3/business_events/export_sub_requests`
    axiosDownloadRequest(api, 'POST', params).then((result) => {
      setShowDownloadPopup(false)
      const url = window.URL.createObjectURL(result)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', file_name)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    })
  }

  return (
    <Fragment>
      {showDownloadPopup && 
        <DownloadCustomPopup show={showDownloadPopup} close={handleCancel}>
          <Fragment>
            <div className='flex'>
              <DateRangePicker
                ranges={[dateRange]}
                onChange={handleSelect}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                months={2}
                direction="horizontal"
                rangeColors={['#111827']}
              />
              <div className='w-60'>
                <div className='h-15 w-full bg-gray-900 text-white flex items-center justify-center rounded-tr-2xl'>
                  Date range
                </div>
                <div className='flex flex-col justify-center mt-10 gap-3 pr-6 pl-2'>
                  <input type='text' className='w-48 h-9 px-2 text-center border border-gray-300 rounded text-sm' value={handleStart(dateRange?.startDate)} readOnly={true} />
                  <input type='text' className='w-48 h-9 px-2 text-center border border-gray-300 rounded text-sm' value={handleStart(dateRange?.endDate)} readOnly={true} />
                </div>
                <div className='flex item-center justify-center gap-3 mt-16 pl-2 pr-6'>
                  <button className={`bg-white border rounded-2xl text-gray-800 flex items-center justify-center w-full py-2 text-sm hover:bg-gray-100 font-semibold shadow`} onClick={() => handleCancel(false)}>Cancel</button>
                  <button className={`bg-gray-900 hover:bg-gray-700 rounded-2xl text-white flex items-center justify-center w-full py-2 text-sm font-semibold ${!checkStartEndDate() ? 'opacity-50 cursor-not-allowed' : ''}`} onClick={() => handleSubmit()} disabled={!checkStartEndDate()}>Download</button>
                </div>
              </div>
            </div>
          </Fragment>
        </DownloadCustomPopup>
      }
      <button className="w-11 h-11 rounded-lg flex items-center justify-center border-2 border-gray-300 bg-gray-custom-50" onClick={() => setShowDownloadPopup(true)}>
        <DownloadIcon classNames={`w-6 h-6 ${'text-black'}`}/>
      </button>
    </Fragment>
  )
}