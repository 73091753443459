import React, { Fragment } from "react";
import { checkSelctedRegions } from "../../subRequests/helpers/helpers";

export function categoryInitialState() {
  return {
    name: "",
    expiration_date_required: false,
    add_in_new_users: false,
    send_documentation_reminder: false,
    submit_reminder_days: 1,
    send_expiration_reminders: false
  };
}

export const instructorDocumentationFilterSaved = (userId) => {
  const lastFilters = JSON.parse(localStorage.getItem('instructorDocumentationFilter'));
  if(lastFilters && lastFilters?.uid && lastFilters?.uid == userId){
    return true
  }else{
    return false
  }
}

export const handleSaveDocumentationFilters = (data, state, filterName, userId) => {
  let lastFilters = {
    statuses: [],
    statusesState: '',
    instructors: [],
    instructorsState: '',
    categories: [],
    categoriesState: '',
    locations: [],
    regions: [],
    locationsState: '',
    uid: userId
  }
  if(instructorDocumentationFilterSaved(userId)){
    lastFilters = JSON.parse(localStorage.getItem('instructorDocumentationFilter'));
  }
  if(filterName == 'status'){
    lastFilters.statuses = data
    lastFilters.statusesState = state
  }else if(filterName == 'instructor'){
    lastFilters.instructors = data
    lastFilters.instructorsState = state
  }else if(filterName == 'categories'){
    lastFilters.categories = data
    lastFilters.categoriesState = state
  }else if(filterName == 'location'){
    lastFilters.locations = data?.locations
    lastFilters.regions = data?.regions
    lastFilters.locationsState = state
  }
  localStorage.setItem('instructorDocumentationFilter', JSON.stringify(lastFilters));
}

export const LoadingSpinner = () => {
  return (
    <Fragment>
      <div className="msg-loading flex flex-col items-center w-full justify-center h-screen">
        <img className = "w-9" src="/assets/business/loading.gif" />
      </div>
    </Fragment>
  )
}

export const checkFiltersState = (userId, filterName) => {
  if(instructorDocumentationFilterSaved(userId)){
    let lastFilters = JSON.parse(localStorage.getItem('instructorDocumentationFilter'));
    if(filterName == 'status'){
      return ((lastFilters?.statusesState && lastFilters?.statusesState !== '' ) ? lastFilters?.statusesState : 'all_selected' )
    }else if(filterName == 'instructor'){
      return ((lastFilters?.instructorsState && lastFilters?.instructorsState !== '' ) ? lastFilters?.instructorsState : 'all_selected' )
    }else if(filterName == 'categories'){
      return ((lastFilters?.categoriesState && lastFilters?.categoriesState !== '' ) ? lastFilters?.categoriesState : 'all_selected' )
    }else if(filterName == 'location'){
      return ((lastFilters?.locationsState && lastFilters?.locationsState !== '' ) ? lastFilters?.locationsState : 'all_selected' )
    }
  }else{
    return 'all_selected'
  }
}

export const checkCategories = (comingCategoryIds, savedCategories, user_id) => {
  if(savedCategories.length == 0){
    handleSaveDocumentationFilters(comingCategoryIds, (comingCategoryIds?.length > 0 ? 'all_selected' : 'no_selected'), 'categories', user_id)
    return comingCategoryIds
  }else{
    let newCategories = []
    newCategories = savedCategories.filter((id) => comingCategoryIds.includes(id))
    if(newCategories.length == 0){
      handleSaveDocumentationFilters(comingCategoryIds, (comingCategoryIds?.length > 0 ? 'all_selected' : 'no_selected'), 'categories', user_id)
      newCategories = comingCategoryIds
    }else{
      handleSaveDocumentationFilters(newCategories, (comingCategoryIds?.length == newCategories?.length ? 'all_selected' : newCategories?.length > 0 ? 'some_selected' : 'no_selected'), 'categories', user_id)
    }
    return newCategories
  }
}

export const checkLocations = (comingLocationIds, comingRegions, savedLocations, regionLocations, user_id) => {
  if(savedLocations.length == 0){
    let data = {
      locations: comingLocationIds,
      regions: comingRegions
    }
    handleSaveDocumentationFilters(data, (comingLocationIds?.length > 0 ? 'all_selected' : 'no_selected'), 'location', user_id)
    return [comingLocationIds, comingRegions]
  }else{
    let newLocations = []
    let newRegion = []
    newLocations = savedLocations.filter((id) => comingLocationIds.includes(id))
    if(newLocations.length == 0){
      let data = {
        locations: comingLocationIds,
        regions: comingRegions
      }
      handleSaveDocumentationFilters(data, (comingLocationIds?.length > 0 ? 'all_selected' : 'no_selected'), 'location', user_id)
      newLocations = comingLocationIds
      newRegion = comingRegions
    }else{
      newRegion = checkSelctedRegions(regionLocations, newLocations)
      let data = {
        locations: newLocations,
        regions: newRegion
      }
      handleSaveDocumentationFilters(data, (comingLocationIds?.length == newLocations?.length ? 'all_selected' : newLocations?.length > 0 ? 'some_selected' : 'no_selected'), 'location', user_id)
    }
    return [newLocations, newRegion]
  }
}

export const allStatuses = ['submitted', 'not_submitted', 'expired', 'expiring']

export const getDocumentationId = (documentation) => {
  let categoryId = (documentation?.documentation_category?.id && documentation?.documentation_category?.id !== '') ? `ca${documentation?.documentation_category?.id}` : ''
  let userId = (documentation?.business_user?.id && documentation?.business_user?.id !== '') ? `u${documentation?.business_user?.id}` : ''
  let documentId = (documentation?.documentation?.id && documentation?.documentation?.id !== '') ? `ce${documentation?.documentation?.id}` : ''

  return [categoryId, userId, documentId].filter(Boolean).join('-');
}

export function parseIdsUpdated(formattedString) {
  const match = formattedString.match(/^(ca\d+)?-?(u\d+)?-?(ce\d+)?$/);
  if (!match) {
    return { categoryId: "", userId: "", documentId: "" };
  }

  return ({
    categoryId: match[1] ? match[1].replace("ca", "") : "",
    userId: match[2] ? match[2].replace("u", "") : "",
    documentId: match[3] ? match[3].replace("ce", "") : ""
  });
}

export function formatTimestampToDate(timestamp) {
  const date = new Date(timestamp);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}

export const getFileType = (url) => {
  const extension = url.split(".").pop().toLowerCase();
  if (["jpg", "jpeg", "png", "gif", "bmp", "webp"].includes(extension)) {
    return "image";
  } else if (extension === "pdf") {
    return "pdf";
  } else {
    return "unknown";
  }
};

export const statusColours = {
  submitted: 'bg-green-100 text-green-800',
  "not submitted": 'bg-gray-100 text-cool-gray-500',
  expired: 'bg-red-100 text-red-800',
  expiring: 'bd-orange-100 text-orange-800',
  not_submitted: 'bg-gray-100 text-cool-gray-500'
};