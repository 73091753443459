import React, { Fragment, useState, useEffect, useCallback } from "react";
import { SearchIcon } from './../../../assets';
import { debounce } from "lodash";

export default function SearchComponent(props) {
  const { instructorsList, setInstructors, fetchInstructors, newSubRequest } = props
  
  const [ searchValue, setSearchValue ] = useState('')

  // useEffect(() => {
  //   setInstructors(instructorsList)
  // }, [instructorsList])

  const handleSearchInstructor = (e) => {
    let val = e.target.value
    if (e.key === ' ') {
      e.preventDefault();
      val += ' ';
    }
    setSearchValue(val)
    searchInstructor(val)
  }

  const searchInstructor = useCallback(
    debounce(val => {
      if (newSubRequest?.allowPersonalInvites) {
        fetchInstructors(val.trim(), newSubRequest?.allowPersonalInvites, '', true)
      } else {
        performSearchInstructor(val.trim())
      }
    }, 800),
    []
  );

  const performSearchInstructor = (text) => {
    if (text === '') {
      setInstructors(instructorsList)
      return
    }

    let newInstructorsList = instructorsList.filter(function (user) {
      return user?.full_name?.toLowerCase().includes(text?.toLowerCase())
    })
    setInstructors(newInstructorsList);
  }

  return (
    <Fragment>
      <div className='bg-gray-custom-50 rounded-2xl px-4 mb-4'>
        <div className='flex items-center'>
          <SearchIcon classNames={'h-5 w-5'}/>
          <input type="text" className="ml-1.5 focus:outline-none w-full bg-transparent h-12" placeholder="Search for Instructor" value={searchValue} onChange={(e) => {handleSearchInstructor(e)}} onKeyDown={(e) => {handleSearchInstructor(e)}}></input>
        </div>
      </div>
    </Fragment>
  )
}