import React, { Fragment } from "react";

export default function EditButtonIcon({classNames, strokeColor='black'}) {
  return(
    <Fragment>
      {classNames.includes('old-icon') ?
        <svg xmlns="http://www.w3.org/2000/svg" className={classNames} viewBox="0 0 20 20" fill="currentColor">
          <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
        </svg>
        :
        <svg className={classNames} viewBox="0 0 14 14" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.91699 1.74985C10.0702 1.59664 10.2521 1.47511 10.4523 1.39219C10.6524 1.30928 10.867 1.2666 11.0837 1.2666C11.3003 1.2666 11.5149 1.30928 11.7151 1.39219C11.9152 1.47511 12.0971 1.59664 12.2503 1.74985C12.4035 1.90306 12.5251 2.08494 12.608 2.28512C12.6909 2.4853 12.7336 2.69985 12.7336 2.91652C12.7336 3.13319 12.6909 3.34774 12.608 3.54791C12.5251 3.74809 12.4035 3.92998 12.2503 4.08318L4.37533 11.9582L1.16699 12.8332L2.04199 9.62485L9.91699 1.74985Z" stroke={strokeColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      }
    </Fragment>
  )
}