import React, { Fragment, useState, useEffect } from 'react';
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/outline'
import { hideItems, handleSaveFilters } from './../../../helpers/helpers'
import SearchIcon from './../../../assets/searchIcon'

export default function StatusFilterComponent(props){
  const { selectedStatus, setSelectedStatus, business, approvedCount, cancelledCount, managerCount, noShowCount, openCount, pendingCount, user, deletedCount } = props
  const [ statusValues, setStatusValues ] = useState(selectedStatus)
  const allStatusList  = [
    {value: 'mgr', label: 'Manager Board'},
    {value: 'open', label: 'Open'},
    {value: 'pending', label: 'Pending'},
    {value: 'approved', label: 'Approved'},
    {value: 'no_show', label: 'No Show'},
    {value: 'cancelled', label: 'Cancelled'},
    {value: 'deleted', label: 'Deleted'}
  ]
  const withoutMGR = allStatusList.filter((item) => item.value !== 'mgr')
  const statusList = business?.manager_request_gate ? allStatusList : withoutMGR

  const checkTypeName = (status) => {
    return statusList.filter((item) => status.includes(item.value)).map((item) => item.label).join(', ')
  }

  const handleChangeStatus = (e, status) => {
    var __FOUND = statusValues.includes(status)
    if(e.target.checked){
      if(!__FOUND){
        let newStatus = [...statusValues, status]
        setStatusValues(newStatus)
      }
    }else{
      if(__FOUND){
        let newStatus = statusValues.filter((tag) => tag !== status)  
        setStatusValues(newStatus)
      }      
    }
  }

  const processSetSelectedStatus = () => {
    setSelectedStatus(statusValues)
    handleSaveFilters(statusValues, (checkSelectAll() ? 'all_selected' : statusValues?.length > 0 ? 'some_selected' : 'no_selected' ), 'status', user?.id)
    hideItems('statusspecial')
  }

  const cancelStatusFilter = () => {
    if(!$('.statusspecial_items').hasClass("hidden")){
      processOpenLocationsFilter()
    }
    hideItems('statusspecial')
  }
  
  const handleSelectAll = () => {
    if(checkSelectAll()){
      setStatusValues([])
    }else{
      let all_value = statusList.map((item) => item?.value)
      setStatusValues(all_value)
    }
  }

  const checkSelectAll = () => {
    let all_value = statusList.map((item) => item?.value)
    return statusValues.length === all_value.length
  }

  const processOpenLocationsFilter = () => {
    setStatusValues(selectedStatus)
  }

  useEffect(() => {
    if(statusValues?.length > 0 && statusList?.length !== statusValues?.length){
      $('.statusspecial_items_button').addClass('filter-apply-border')
    }else {
      $('.statusspecial_items_button').removeClass('filter-apply-border')
    }
  }, [statusValues])
  
  return(
    <Fragment>
      <div className="relative filters_box"> 
        <div className="hidden my-status-special-div" onClick={() => processOpenLocationsFilter()}></div>
        <Menu as="div" className="relative block text-left">
          <div>
            <Menu.Button className="flex justify-center w-full rounded-lg pl-4 pr-2 py-2.5 bg-gray-custom-50 text-sm text-gray-900 focus:outline-nonefocus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 statusspecial_items_button border-2 border-gray-300 need-border font-medium">
              {statusValues.length === 0 ? 
                "Select a status"
              : 
                (statusValues.length === statusList?.length ?
                  "All Statuses"
                :
                  <p className = "text-gray-900 truncate">{checkTypeName(statusValues)}</p>
                )
              }
              <ChevronDownIcon className="ml-auto text-gray-900 h-5 w-5" aria-hidden="true" />
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            show={true}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95">
            <Menu.Items className="origin-top-right z-30 right-0 w-full rounded-xl bg-white ring-2 ring-gray-500 focus:outline-none mt-2 hidden statusspecial_items filter_items absolute">
              <div className="py-2">
                <div className='flex items-center justify-between px-4 py-2 mb-1'>
                  <label className="inline-flex items-center text-sm">
                    <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-2" checked = {checkSelectAll()} onChange = {(e) => handleSelectAll(e)}/>
                    Select all
                  </label>
                  <a onClick={() => cancelStatusFilter()} className='custom-red text-sm cursor-pointer'>Cancel</a>
                </div>
                <div>
                  {statusList.map((type, i) => 
                    <Menu.Item key = {i}>
                      {({ active }) => (
                        <label className="flex items-center px-4 text-gray-900 text-sm mb-2">
                          <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 cursor-pointer mr-2" onChange={(e) => handleChangeStatus(e, type?.value)} value={type?.value} checked={statusValues.includes(type?.value)}/>
                          <div className={`flex items-center gap-2 cursor-pointer ${type?.value === 'mgr' ? 'status-mgr' : (type?.value === 'open' ? 'status-open' : type?.value === 'pending' ? 'status-pending' : type?.value === 'approved' ? 'status-approved' : type?.value === 'no_show' ? 'status-no-show' : type?.value === 'cancelled' ? 'status-canceleld' : type?.value === 'deleted' && 'status-deleted' )} px-3 py-0.5 rounded-2xl`}>
                            {`${type?.label} ${type?.value === 'mgr' ? (managerCount > 0 ? `(${managerCount})` : '') : (type?.value === 'open' ? (openCount > 0 ? `(${openCount})` : '') : type?.value === 'pending' ? (pendingCount > 0 ? `(${pendingCount})` : '') : type?.value === 'approved' ? (approvedCount > 0 ? `(${approvedCount})` : '') : type?.value === 'no_show' ? (noShowCount > 0 ? `(${noShowCount})` : '') : type?.value === 'cancelled' ? (cancelledCount > 0 ? `(${cancelledCount})` : '') : type?.value === 'deleted' && (deletedCount > 0 ? `(${deletedCount})` : ''))}`}
                          </div>
                        </label>
                      )}
                    </Menu.Item>
                  )}
                </div>
              </div>
              <div className="flex items-center p-2 border-t">
                <div className="flex justify-center items-center w-full px-3 gap-3">
                  <div className=''>
                    <a className='bg-dark-blue text-white text-sm rounded-md py-2 px-4 font-medium h-9 block cursor-pointer' onClick={(e) => processSetSelectedStatus()}>Apply</a>
                  </div>
                </div>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </Fragment>
  )
}