import React from "react";

export default function ClassesMassActionBar(props) {
  const { selectedClassesForMassAction, user, handleAssignSkills, resetSelection } = props;

  return (
    <tr className="bg-dark-blue">
      <td colSpan={'9'}>
        <div className="flex justify-between px-8 items-center bg-dark-blue text-white py-3">
          <div className="text-sm">{selectedClassesForMassAction?.length} classes selected</div>
          <div className="flex items-center gap-2">
            <button className="bg-dark-blue text-white py-1.5 px-4 rounded-full gap-2 text-sm flex items-center border border-white hover:bg-gray-800" onClick={() => handleAssignSkills(true)}>Assign Skill(s)</button>
            <div className="relative filters_box">
              <button className={`bg-dark-blue text-white py-1.5 px-4 rounded-full gap-2 text-sm flex items-center border border-white hover:bg-gray-800`} onClick={(e) => resetSelection(e)}>
                Clear Selection
              </button>
            </div>
          </div>
        </div>
      </td>
    </tr>
  )
};