import React, { Fragment } from "react";
import CustomPopupContainerComponent from './../../../../subRequestDetails/components/customPopupContainerComponent';

export default function SuccessPopupComponent(props) {
  const { showSuccessPopup, closeSuccessPopup, successMessage } = props

  return(
    <Fragment>
      <CustomPopupContainerComponent show={showSuccessPopup} close={closeSuccessPopup} title={''} customWidth={'sm:max-w-5xl'} showCloseIcon={false}>
        <div className="flex items-center justify-center flex-col">
          <div className="text-2xl text-black font-bold">Sub Request Sent!</div>
          <div className="gap-2 px-8 py-4">
            <div className="flex justify-center items-center text-xl text-black font-semibold mb-3">
              {successMessage}
            </div>
            <div>
              <div className="flex text-center mt-8 mb-10 justify-center h-42">
                <img src="/assets/smile-with-star.png" alt="success" className="w-48 h-42"/>
              </div>
            </div>
            <div className="flex justify-center">
              <button className="bg-gray-900 text-white text-center text-sm font-semibold rounded-2xl px-8 py-3.5" onClick={() => closeSuccessPopup(false)}>
                Back to Sub Board
              </button>
            </div>
          </div>
        </div>
      </CustomPopupContainerComponent>
    </Fragment>
  )
}