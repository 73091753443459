import React, { Fragment } from "react";
import ListIcon from '../../../assets/list_icon';
import CalendarIcon from '../../../assets/calendar_icon';

export default function CalendarListTab(props) {
  const { activeTab, setActiveTab } = props

  const handleSetActivePage = (data) => {
    setActiveTab(data)
  }

  return(
    <Fragment>
      <div className='flex items-center justify-center border-2 border-gray-300 rounded-lg gap-x-1 px-0.5 py-0.5 bg-white'>
        <a className={`flex items-center text-sm gap-x-1 py-2 px-4 justify-center text-dark-blue font-medium cursor-pointer ${activeTab === 'calendar' ? 'rounded-lg  bg-gray-200 border border-gray-200' : ''}`} onClick={() => handleSetActivePage('calendar')}>
          Calendar
          <div>
            <CalendarIcon classNames={'w-4 h-4'}/>
          </div>
        </a>
        <a className={`flex items-center text-sm gap-x-1 py-2 px-4 justify-center text-dark-blue font-medium cursor-pointer ${activeTab === 'list' ? 'rounded-lg  bg-gray-200 border border-gray-200' : ''}`} onClick={() => handleSetActivePage('list')}>
          List
          <div>
            <ListIcon classNames={'w-4 h-4'}/>
          </div>
        </a>
      </div>
    </Fragment>
  )
}