import React, { Fragment } from "react";

export default function HistoryCard(props) {
  const { history } = props;

  const formatTitle = () => {
    if (!history?.title || !history?.file_name) return <span>{history?.title || '-'}</span>;

    const titleParts = history.title.split(history.file_name);

    return (
      <span>
        {titleParts[0]}
        <a
          href={history.file_url}
          target="_blank"
          className="underline"
        >
          {history.file_name}
        </a>
        {titleParts[1] || ''}
      </span>
    );
  };

  return(
    <Fragment>
      <div>
        <div className="pb-2 text-xs font-semibold text-cool-gray-500">
          {history?.created_at}
        </div>
        <div className="pl-3">
          <div className="flex items-center">
            <div  className='w-3 h-3 rounded-full bg-gray-400 shrink-0'>
            </div>
            <div className="pl-5 font-semibold text-base truncate">{formatTitle()}</div>
          </div>
          <div className="border-l border-gray-200 pl-4 ml-1.5 flex flex-col gap-y-1.5 pt-1.5 pb-10">
            <div className="text-base text-gray-500 flex items-center font-medium pl-2.5 gap-1">
              <p className="whitespace-nowrap">Document Name:</p><p className="truncate">{history?.document_name}</p>
            </div>
            <div className="text-base text-gray-500 flex items-center font-medium pl-2.5">
              Expiration Date: {history?.expiration_date || '-'}
            </div>
            <div className="text-base flex items-center font-medium pl-2.5 pt-1 gap-2">
              <div className="w-5 h-5 bg-gray-300 rounded-full">
                <img className="w-5 h-5 rounded-full" src={history?.action_performed_by?.image} />
              </div>
              <div className="font-semibold fs-15">
                by {history?.action_performed_by?.full_name}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}