import React, { Fragment, useState, useRef, useEffect } from "react";
import { NotepadIcon } from '../../../../subRequestCreate/assets';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/outline';
import ReactTooltip from "react-tooltip";
import { InfoIcon } from '../../../../subRequestDetails/assets';
import { reasonList } from '../../../../subRequestCreate/helpers';

export default function ReopenReasonComponent(props) {
  const { reason, setReason, user } = props
  const menuButtonRef = useRef(null);

  const handleChangeReason = (e) => {
    handleApplyFilter(e.target.value, reason?.otherReason)
  }

  const setRef = node => {
    if (node) {
      $('.reason-need-border').addClass('filter-dynamic-border')
    }else if (node === null) {
      $('.reason-need-border').removeClass('filter-dynamic-border')
    }
  };

  const handleApplyFilter = (selectedReason, otherReason) => {
    setReason({reason: selectedReason, otherReason: otherReason})
    menuButtonRef.current.click()
  }

  return(
    <Fragment>
      <div className="flex flex-col gap-2 filter-column justify-end">
        <div className="flex text-base text-black font-semibold items-center gap-2 flex-wrap">
          Reason for Reopening the Request
          <div className="flex items-center gap-2">
            {!user?.request_reason_optional && <span className="text-black text-sm font-semibold">(*Required)</span>}
            <div data-tip="true" data-for={`tooltip_for_sub-request-reopened_reason`} className="profile-notification-container relative">
              <InfoIcon classNames={"h-4 w-4"} customColour={"#4b5563"}/>
              <div className="profile-notification-tooltip text-white bg-gray-900 shadow-md border rounded-md p-2 absolute bottom-6 -left-16 text-xs font-semibold w-40">
                The reason is only visible to management
              </div>
            </div>
          </div>
        </div>
        <div className="relative filters_box"> 
          <Menu as="div" className="relative block text-left">
            <div>
              <Menu.Button className="flex justify-center w-full rounded-2xl pl-4 pr-2 py-2.5 bg-gray-custom-50 text-sm text-gray-900 focus:outline-none focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 h-11 gap-1 items-center opacity-c-100 border-2 border-transparent reason-need-border" ref={menuButtonRef}>
                <NotepadIcon classNames={'w-5 h-5 shrink-0'} />
                <p className="truncate filter-text-field text-left">
                  {reason?.reason == '' ?
                    'Select reason for reopening the sub request'
                  :
                    reason?.reason
                  }
                </p>
                <ChevronDownIcon className="ml-auto text-gray-900 h-5 w-5" aria-hidden="true" />
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              // show={true}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95">
              <Menu.Items ref={setRef} className="origin-top-right z-30 right-0 w-full rounded-2xl bg-white shadow-md border bordar-gray-200 focus:outline-none mt-2 filter_items">
                <div className="my-4 px-4 max-h-60 overflow-y-auto">
                  {reasonList?.map((reason, i) => 
                    <label key = {i} className="flex text-gray-900 text-sm mb-2 justify-between cursor-pointer">
                      <div className="flex text-left font-medium text-base text-gray-900 pr-2">
                        {reason}
                      </div>
                      <div className="assign-checkbox flex mt-1">
                        <input
                          type="checkbox"
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 cursor-pointer"
                          value={reason}
                          checked={reason?.reason == reason}
                          onChange={(e) => handleChangeReason(e)}
                        />
                      </div>
                    </label>
                  )}
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>
    </Fragment>
  )
}