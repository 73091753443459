import React, { Fragment } from "react";

export default function ShowAllEventsButtonComponent(props) {
  const { showAllEvents, handleSetShowAllEvents, path } = props

  return(
    <Fragment>
      <button className={`flex justify-center items-center ${path == 'all-requests' ? 'rounded-lg border-2 border-gray-300' : 'rounded-xl'} px-3 py-4 h-11 w-24 bg-gray-custom-50 text-sm text-gray-900 hover:bg-gray-100`} onClick={() => handleSetShowAllEvents(!showAllEvents)}>
        {showAllEvents ? `Show less` : `Show all`}
      </button>
    </Fragment>
  )
}