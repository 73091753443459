import React, { Fragment, useEffect, useState } from "react";
import ConfirmUserComponent from '../components/confirmUserComponent';
import Footer from '../../availability/footer';
import HeaderComponent from '../components/headerComponent';
import UserInfoComponent from '../components/userInfoComponent';
import NetgymLogoComponent from '../components/netgymLogoComponent'
import StepsComponent from '../components/stepsComponent';
import { setTitle } from '../../helpers';
import AlertPopup from '../../messenger/pages/chatComponents/assets/alertPopup';
import { Redirect } from "react-router-dom";
import PhoneVerifyComponent from '../components/phoneVerifyComponent';
import NotificationComponent from '../components/notificationComponent';
import LocationsComponent from '../components/locationsComponent';
import { updatePageUrl } from '../../subRequestCreate/helpers';
import DailyNotificationsComponent from '../components/dailyNotificationsComponent';
import ProfilePicComponent from '../components/profilePicComponent';

export default function SignUpLayout(props) {
  const { path, handleUpdateUser, subdomainBusinessImage, user, updateUserDataAndRedirect, handleReIntializeAllApiCalls, pageLoaded } = props
  const [ loading, setLoading ] = useState(true);
  const [ step, setStep ] = useState(0);
  const [ showAlert, setShowAlert ] = useState(false);
  const [ alertType, setAlertType ] = useState('');
  const [ alertMessage, setAlertMessage ] = useState('');
  const [ redirect, setRedirect ] = useState(false);
  const [ redirectToUrl, setRedirectToUrl ] = useState('');
  const [ pagePath, setPagePath ] = useState(path);
  const [ currentUser, setCurrentUser ] = useState(user);

  useEffect(() => {
    setTitle(pagePath == 'email-confirmation' ? pagePath : 'sign-up')
    if(['user-info', 'phone-verify', 'notifications', 'locations', 'daily-notifications', 'profile-pic'].includes(pagePath)) {
      handleUpageStepper(pagePath)
      setLoading(false)
    }
  }, [])

  const setAlert = (showAlert, alertType, alertMessage) => {
    setShowAlert(showAlert)
    setAlertType(alertType)
    setAlertMessage(alertMessage)
  }

  const closeAlert = (data) => {
    setAlert(data, '', '')
  }

  const updateUserDataAndSwitchPage = (data, newPath) => {
    setLoading(true)
    setCurrentUser(data)
    let comingPath = newPath.replace('/', '')
    updatePageUrl(comingPath)
    handleUpageStepper(comingPath)
    setPagePath(comingPath)
    window.setTimeout(() => {
      setLoading(false)
    }, 100)
  }

  const handleUpageStepper = (pagePath) => {
    if (pagePath == 'user-info') setStep(0)
    if (['phone-verify', 'notifications', 'locations', 'daily-notifications'].includes(pagePath)) setStep(1)
    if (pagePath == 'profile-pic') setStep(2)
  }

  const renderSwitch = (providePath) => {
    switch(providePath){
      case 'user-info':
        return (
          <UserInfoComponent
            user={currentUser}
            updateUserDataAndRedirect={updateUserDataAndRedirect}
            setAlert={setAlert}
            handleUpdateUser={updateUserDataAndSwitchPage}
          />
        )
      case 'phone-verify':
        return (
          <PhoneVerifyComponent
            user={currentUser}
            updateUserDataAndRedirect={updateUserDataAndRedirect}
            setAlert={setAlert}
            handleUpdateUser={updateUserDataAndSwitchPage}
          />
        )
      case 'notifications':
        return (
          <NotificationComponent 
            user={currentUser}
            updateUserDataAndRedirect={updateUserDataAndRedirect}
            setAlert={setAlert}
            handleUpdateUser={updateUserDataAndSwitchPage}
          />
        )
      case 'locations':
        return (
          <LocationsComponent 
            user={currentUser}
            setAlert={setAlert}
            handleUpdateUser={updateUserDataAndSwitchPage}
          />
        )
      case 'daily-notifications':
        return (
          <DailyNotificationsComponent
            user={currentUser}
            setAlert={setAlert}
            handleUpdateUser={updateUserDataAndSwitchPage}
          />
        )

      case 'profile-pic':
        return (
          <ProfilePicComponent
            user={currentUser}
            setAlert={setAlert}
            handleUpdateUser={updateUserDataAndSwitchPage}
            updateUserDataAndRedirect={updateUserDataAndRedirect}
          />
        )
    }
  }
  
  return(
    <Fragment>
      {redirect && <Redirect to={redirectToUrl} />}
      {showAlert &&
        <AlertPopup alert_type={alertType} alert_message={alertMessage} closeAlert={closeAlert}/>
      }
      {pageLoaded &&
        <Fragment>
          {['confirm-user', 'email-confirmation'].includes(pagePath) &&
            <ConfirmUserComponent
              handleUpdateUser={updateUserDataAndSwitchPage}
              updateUserDataAndRedirect={updateUserDataAndRedirect}
              handleReIntializeAllApiCalls={handleReIntializeAllApiCalls}
              setLoading={setLoading}
              pagePath={pagePath}
              setAlert={setAlert}
            />
          }
          {loading &&
            <div className="msg-loading flex flex-col items-center w-full justify-center h-screen">
              <img className = "w-9" src="/assets/business/loading.gif" />
            </div>
          }
          {!loading &&
            <div className='min-h-screen font-open-sans relative overflow-auto'>
              <HeaderComponent subdomainBusinessImage={subdomainBusinessImage}/>
              <div className={`flex items-center justify-center w-full h-full bg-white pt-32 pb-32 md:pb-16 px-4 md:px-8`}>
                <div className="w-full flex flex-col items-center justify-start">
                  <NetgymLogoComponent />
                  <StepsComponent step={step}/>
                  {renderSwitch(pagePath)}
                </div>
              </div>
              <div className="absolute bottom-0 w-full">
                <Footer />
              </div>
            </div>
          }
        </Fragment>
      }
      {!pageLoaded &&
        <div className="msg-loading flex flex-col items-center w-full justify-center h-screen">
          <img className = "w-9" src="/assets/business/loading.gif" />
        </div>
      }
    </Fragment>
  )
}