import React, { Fragment, useState, useEffect } from "react";
import HistoryCard from "./historyCard";
import { ChevronDownIcon } from '@heroicons/react/outline'

export default function HistoryComponent(props) {
  const { documentation } = props;
  const [ showAll, setShowAll ] = useState(false)
  const [ histories, setHistories ] = useState([]);

  useEffect(() => {
    setHistories(showAll ? documentation?.histories || [] : documentation?.histories?.slice(0, 2) || []);
  }, [showAll, documentation]);

  return(
    <Fragment>
      <div className="w-full p-4 bg-white shadow border border-gray-100 rounded-lg mt-4">
        <p className="text-lg font-bold break-all pb-3">
          History
        </p>
        {histories?.map((item, i) =>
          <div key={i}>
            <HistoryCard history={item} />
          </div>
        )}
        {documentation?.histories?.length > 2 &&
          <button className="flex custom-blue-200 text-sm font-semibold ml-3 py-2 gap-3 items-center"
            onClick={() => setShowAll(!showAll)}
          >
            <ChevronDownIcon className={`mr-auto custom-blue-200 h-4 w-4 ${showAll ? 'transform rotate-180' : ''}`} aria-hidden="true" />
            {`${showAll ? 'Hide' : 'Show'} Older`}
          </button>
        }
      </div>
    </Fragment>
  )
}