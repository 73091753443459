import React, { Fragment } from 'react';
import { CloseIcon } from './../../../messenger/pages/chatComponents/assets/closeIcon';
import { Dialog, Menu, Transition } from '@headlessui/react'

export default function CustomPopupContainerComponent(props) {
  const { children, show, close, title, customWidth, showCloseIcon=true } = props

  return(
    <Fragment>
      <Transition.Root show={show} as={Fragment}>
        <Dialog as="div" className="fixed z-40 inset-0 overflow-y-auto font-open-sans" onClose={(e) => close(false)}>
          <div className="flex items-start justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className={`inline-block align-bottom bg-white rounded-2xl pt-5 pb-4 text-left ${(customWidth && customWidth !== '' && customWidth.includes('assign-skills-popup')) ? '' : 'overflow-hidden'} shadow-xl transform transition-all my-20 sm:align-middle ${(customWidth && customWidth !== '') ? customWidth : 'sm:max-w-xl'} w-full mt-20 opacity-100 translate-y-0 sm:scale-100`}>
                {showCloseIcon &&
                  <div className=" absolute top-0 right-0 pt-4 pr-4">
                    <button
                      type="button"
                      className="bg-white rounded-md text-gray-400 hover:text-gray-500"
                      onClick={(e) => close(false)}
                    >
                      <span className="sr-only">Close</span>
                      <CloseIcon size={"6"} />
                    </button>
                  </div>
                }
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-semibold text-gray-900 px-7">
                      {title}
                    </Dialog.Title>
                    {children}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </Fragment>
  )
}