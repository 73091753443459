import React from "react";
import SendActivationEmailComponent from '../filtersAndButtons/sendActivationEmailComponent/sendActivationEmailComponent';
import ResetSelectionComponent from '../filtersAndButtons/resetSelectionComponent/resetSelectionComponent';

export default function MassActionBar(props) {
  const { selectedPeopleForMassActions, handleAssignDocumentation, pageTab, user, showAlert, reloadDataAfterSendActivationEmail, setTheState, usersIds, handleAssignSkills, handleAssignLocations } = props

  return (
    <tr className="bg-dark-blue">
      <td colSpan={`${pageTab == '' ? '8' : '7'}`}>
        <div className="flex justify-between px-8 items-center bg-dark-blue text-white py-3">
          <div className="text-sm">{selectedPeopleForMassActions?.length} user(s) selected</div>
          <div className="flex items-center gap-2">
            <button className="bg-dark-blue text-white py-1.5 px-4 rounded-full gap-2 text-sm flex items-center border border-white hover:bg-gray-800" onClick={() => handleAssignSkills(true)}>Assign Skill(s)</button>
            {!['crunch', 'clubready', 'fisikal'].includes(user?.user_biz_type?.toLowerCase()) &&
              <button className="bg-dark-blue text-white py-1.5 px-4 rounded-full gap-2 text-sm flex items-center border border-white hover:bg-gray-800" onClick={() => handleAssignLocations(true)}>Assign Location(s)</button>
            }
            {user?.documentations_feature &&
              <button className="bg-dark-blue text-white py-1.5 px-4 rounded-full gap-2 text-sm flex items-center border border-white hover:bg-gray-800" onClick={() => handleAssignDocumentation(true)}>Assign Documentation</button>
            }
            {(pageTab == 'inactive') &&
              <SendActivationEmailComponent selectedPeople={selectedPeopleForMassActions} showAlert={showAlert} reloadDataAfterSendActivationEmail={reloadDataAfterSendActivationEmail} setTheState={setTheState} usersIds={usersIds}/>
            }
            <ResetSelectionComponent setTheState={setTheState}/>
          </div>
        </div>
      </td>
    </tr>
  )
};