import React, { Fragment, useState, useEffect } from 'react';
import AssignPopupComponent from '../../../../../subRequests/pages/components/assignPopupComponent';
import { isCancelOption, isCancelOptionForOther, checkDisabledApplyButton } from '../../../../../subRequests/helpers/supportHelpers';
import { axiosRequest } from './../../../../../subRequests/helpers/helpers';
import { Redirect } from 'react-router-dom';

export default function CancelOptionsPopupComponent(props){
  const { eventData, showCancelOptionsPopup, closeShowCancelOptionsPopup, handleSetAlert, handleUpdateEvent, optionListKeys, cancelOptionKeys, backPath, type, sentBucket, calendarEvent, handleUpdateCalendarEvent, user = {} } = props
  const [ selectedOption, setSelectedOption ] = useState('')
  const [ cancelActionOptions, setCancelActionOptions ] = useState(eventData?.more_action_detail?.filter((item) => cancelOptionKeys.includes(item?.key)) || [])
  const [ disableConfirmBtn, setDisableConfirmBtn ] = useState(false)
  const [ actionText, setActionText ] = useState('')
  const [ confirmationText, setConfirmationText ] = useState('')
  const [ permanentlyCancel, setPermanentlyCancel ] = useState(false);
  const [ redirect, setRedirect ] = useState(false)

  useEffect(() => {
    if(eventData){
      setCancelActionOptions(eventData?.more_action_detail?.filter((item) => cancelOptionKeys.includes(item?.key)) || [])
    }
  }, [eventData])

  const handleOptionSelection = (e, action) => {
    if (disableConfirmBtn) return;
    const value = e.target.value;

    setPermanentlyCancel(false);
    if(selectedOption === value) {
      setSelectedOption('');
      setActionText('');
      setConfirmationText('');
    }else{
      setSelectedOption(value);
      setActionText(action?.message);
      setConfirmationText(action?.confirmation_text);
    }
  }

  const handleClosePopup = (data) => {
    if(!disableConfirmBtn){
      closeShowCancelOptionsPopup(data)
    }
  }

  const handleConfirm = () => {
    setDisableConfirmBtn(true)
    let url = `/api/v3/business_events/${eventData?.id}/perform_more_actions`
    let params = {
      key: selectedOption,
      preview: type !== 'detail',
      bucket_type: (type !== 'detail' ? sentBucket : ''),
      calendar_event: calendarEvent
    }
    if(selectedOption == 'delete_without_sync'){
      params = {...params, reason: "Schedule Updated"}
    }else if(selectedOption == 'delete_class'){
      params = {...params, reason: "No longer need a sub"}
    }else if(selectedOption == 'delete_class_original_instructor'){
      params = {...params, reason: "No longer need a sub original instructor is teaching"}
    }else if(selectedOption == 'delete_class_duplicate'){
      params = {...params, reason: 'Duplicate Sub Request'}
    }else if(selectedOption == 'delete_class_no_longer'){
      params = {...params, reason: 'No longer need a sub'}
    }else if(selectedOption == 'delete_class_other'){
      params = {...params, reason: 'Other'}
    }else if(selectedOption == 'crunch_event_delete'){
      params = {...params, reason: 'Duplicate - new default/override'}
    }else if(selectedOption == 'cancel_sub_request'){
      params = {...params, reason: cancelActionOptions.find((item) => item?.key == selectedOption)?.value || ''}
    }
    if(selectedOption == ''){
      setDisableConfirmBtn(false);
      return
    }
    axiosRequest(url, 'PATCH', params, 'data').then((result) => {
      if(result?.status === 200){
        handleSetAlert(true, result?.success, 'success');
        if(['cancel_sub_request', 'rescind_class'].includes(selectedOption)){
          setDisableConfirmBtn(false);
          closeShowCancelOptionsPopup(false);
        }
        setTimeout(() => {
          handleSetAlert(false, '', '');
          if(['cancel_sub_request', 'rescind_class'].includes(selectedOption)){
            handleUpdateEvent(result?.event);
            if(calendarEvent){
              handleUpdateCalendarEvent(result?.calendar_event, 'updated');
            }
          }else{
            if(type == 'detail'){
              if((Object.keys(user).length > 0 && user?.role_display == 'Fitness Instructor') || (Object.keys(user).length == 0)){
                setRedirect(true)
              }else{
                closeShowCancelOptionsPopup(false);
                handleUpdateEvent(result?.event);
              }
            }else{
              if (location.pathname.includes('all-requests')) {
                handleUpdateEvent(result?.event);
                if(calendarEvent) handleUpdateCalendarEvent(result?.calendar_event, `setDeleted_${eventData?.id}`);
              }else {
                handleUpdateEvent('deleted');
                if(calendarEvent) handleUpdateCalendarEvent('', `deleted_${eventData?.id}`);
              }
              closeShowCancelOptionsPopup(false);
            }
          }
        }, 3000)
      }else{
        setDisableConfirmBtn(false);
        handleSetAlert(true, (selectedOption == 'crunch_event_delete' ? result?.success : result?.error), 'danger');
        setTimeout(() => {
          handleSetAlert(false, '', ''); 
        }, 3000)
      }
    })
  }

  return(
    <Fragment>
      {redirect &&
        <Redirect to={`${backPath || '/my-requests'}`} />
      }
      <AssignPopupComponent show={showCancelOptionsPopup} close={handleClosePopup} title={""}>
        <div className="px-5 pt-3 font-open-sans">
          <div className="text-center font-bold text-xl mb-4 font-open-sans">
            {`Select Reason for ${(cancelActionOptions.length === 1 && cancelActionOptions[0].key === "delete_without_sync") ? 'Deleting' : 'Canceling'}`}
          </div>
          {cancelActionOptions?.map((action, i) => (
            <div
              key={i}
              className="flex items-center gap-2 mb-3"
            >
              <div className="assign-checkbox flex items-center">
                <input
                  id={action?.key+'_'+i}
                  type="checkbox"
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 cursor-pointer"
                  value={action?.key}
                  checked={selectedOption == action?.key}
                  onChange={(e) => handleOptionSelection(e, action)}
                />
              </div>
              <label className="flex items-center gap-2 cursor-pointer" htmlFor={action?.key+'_'+i}>
                <div className="text-sm">{action?.value}</div>
              </label>
            </div>
          ))}
          {(selectedOption !== '' && (isCancelOption(selectedOption) || isCancelOptionForOther(selectedOption))) &&
            <Fragment>
              {actionText !== '' &&
                <div className="text-sm font-medium mt-3.5 text-left">
                  {actionText}
                </div>
              }
              <div>
                <label htmlFor="permanently_delete_cancel" className="flex text-sm cursor-pointer gap-2 text-left mt-3.5">
                  <input
                    id={`permanently_delete_cancel`}
                    type="checkbox"
                    className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mt-0.5 cursor-pointer"
                    onChange={() => {setPermanentlyCancel(!permanentlyCancel)}}
                    checked={permanentlyCancel}
                  />
                  {confirmationText}
                </label>
              </div>
            </Fragment>
          }
          <div className="mt-4">
            <button 
              className={`bg-gray-900 rounded-xl text-white flex items-center justify-center w-full py-2 text-sm h-11 font-semibold ${checkDisabledApplyButton(selectedOption, permanentlyCancel, disableConfirmBtn) ? 'opacity-50 cursor-not-allowed' : ''}`}
              onClick={() => handleConfirm()}
              disabled={checkDisabledApplyButton(selectedOption, permanentlyCancel, disableConfirmBtn)}
            >
              Apply
            </button>
          </div>
        </div>
      </AssignPopupComponent>
    </Fragment>
  )
}