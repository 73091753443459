import React, { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import Cookies from 'js-cookie';
import { getSudomain, logOut, removeAccount, addAccounts, DeleteAccountComponent, checkAndSwitchAccount } from './../helpers'
import CustomPopupContainerComponent from '../subRequestDetails/components/customPopupContainerComponent';
import { Redirect } from 'react-router-dom';
import IntercomManager from '../intercom/intercomManager';

const userNavigation = [
  { name: 'Add Account', href: '#' },
  { name: 'Log Out', href: '#' },
]

export default class Header extends React.Component {  
  constructor(props){
    super(props);
    this.state = {
      deleteModal: false,
      deletableAccount: 0,
      path: '',
      disableDeleteButton: false,
      loggedOut: false
    }
  }

  componentDidMount = () => {
  }

  componentWillUnmount() {
    this.setState = (state,callback)=>{
      return;
    };
  }

  delete = (e, id) => {
    e.preventDefault()
    this.setState({
      deleteModal: true,
      deletableAccount: id
    })    
  }

  processDelete = () => {
    this.setState({disableDeleteButton: true})
    removeAccount(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), this.state.deletableAccount)
    .then(response => response.json())
    .then(result => {
      if(result.status == 200){
        this.setState({disableDeleteButton: false})
        this.closeModal()
        window.location.reload()
      }
    })
  }

  closeModal = () => {
    this.setState({
      deleteModal: false,
      deletableAccount: 0
    })
  }

  setOpen = () => {
    this.props.setSidebarOpen()
  }

  handleLogout = (e) => {
    e.preventDefault()
    logOut(Cookies.get(`currentUser.${getSudomain()}`), getSudomain())
    .then(response => response.json())
    .then(result => { 
      if(result.status == 200){
        let domain = "."+window.location.hostname.replace(getSudomain() + '.', '' )
        Cookies.remove(`currentUser.${getSudomain()}`, { path: '/', domain: domain })
        Cookies.remove('currentEmail', { path: '/', domain: domain })
        Cookies.remove('switch_account', { path: '/', domain: domain })
        Cookies.remove('switch_account_owner', { path: '/', domain: domain })
        Cookies.remove('switch_account_business', { path: '/', domain: domain })
        Cookies.remove('switch_account_email', { path: '/', domain: domain })
        this.setState({loggedOut: true})
      }
    })
  }

  render() {
    const { sidebarOpen, user, business, path, accounts } = this.props
    const { deleteModal, disableDeleteButton, loggedOut } = this.state
    return (
      <React.Fragment>
        {loggedOut &&
          <Fragment>
            <IntercomManager isLoggedIn={!loggedOut} user={user} signInPage={true}/>
            <Redirect to={'/signin'} />
          </Fragment>
        }
        <div className="fixed w-full z-40 flex-shrink-0 flex h-12 md:h-0 bg-white shadow">
          <button
            type="button"
            className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
            onClick={(e) => this.setOpen()}>
            <span className="sr-only">Open sidebar</span>
            <img src="/assets/sidebar-bars.png" />
          </button>
          <div className="flex-1 px-4 flex md:hidden justify-between">
            <div className="flex-1 flex">
            </div>
            <div className="flex items-center">
              
              {/* Profile dropdown */}
              <Menu as="div" className="relative">
                <div>
                  <Menu.Button className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none">
                    <span className="sr-only">Open user menu</span>
                    <span className="ml-2 font-light hidden lg:block">{user.first_name}</span>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                    </svg>
                    <img className="h-8 w-8 rounded-full" alt="User Image" src= {user?.image || "/assets/default-profile.jpg" } />
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="origin-top-right absolute right-0 mt-2 w-64 shadow-lg bg-white ring-opacity-5 border-t-2 border-gray-900 focus:outline-none">
                    <React.Fragment>
                      <div className="accounts-scroll max-h-96 overflow-y-auto">
                        {accounts.length === 0 &&
                          <React.Fragment>
                            {userNavigation.map((item, i) => (
                              <Menu.Item key={i}>
                                <div className="relative">
                                  <a onClick={(e) => {item?.name == 'Add Account' ? addAccounts(user) : this.handleLogout(e)}} className="flex text-xs text-gray-600 items-center p-2 border-b hover:bg-gray-100 cursor-pointer">
                                    {item.name}
                                  </a>
                                </div>
                              </Menu.Item>
                            ))}
                          </React.Fragment>
                        }

                        {accounts.length > 0 &&
                          <React.Fragment>
                            {accounts.map((item, i) => (
                              <Menu.Item key={i}>
                                <div className="relative">
                                  <a className="flex items-center p-2 border-b hover:bg-gray-100 cursor-pointer" onClick={() => checkAndSwitchAccount(item, user)}>
                                    <div className='w-4'>
                                      {item?.login &&
                                        <img className="w-4" src="/assets/switch-account.png" />
                                      }
                                    </div>
                                    <div className= "ml-2">
                                      <span className="text-gray-700 block text-xs font-light">{item.business}</span>
                                      <span className="text-gray-700 block text-xs font-light">{item.email}</span>
                                    </div>
                                  </a>
                                  <button className="absolute right-4 top-5" onClick={(e) => this.delete(e, item?.id)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3" viewBox="0 0 20 20" fill="currentColor">
                                      <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
                                    </svg>
                                  </button>
                                </div>
                              </Menu.Item>
                            ))}
                          </React.Fragment>
                        }                      
                      </div>
                      {accounts.length > 0 &&
                        <div className="bg-gray-50 w-64">
                          <div className="flex justify-center p-2 gap-5 shadow-lg">
                            <a className="bg-gray-100 text-center border text-xs p-2 w-24 cursor-pointer" onClick={(e) => addAccounts(user)}>Add Account</a>
                            <a href="#" className="text-center bg-gray-100 border text-xs p-2 w-24 cursor-pointer" onClick={(e) => this.handleLogout(e)}>Log Out</a>
                          </div>
                        </div>
                      }
                      
                    </React.Fragment>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        </div>
        {deleteModal &&
          <CustomPopupContainerComponent
            show={deleteModal}
            close={this.closeModal}
            title={'Delete Account'}
          >
            <DeleteAccountComponent deleteAccount={this.processDelete} disableDeleteButton={disableDeleteButton}/>
          </CustomPopupContainerComponent>
        }
      </React.Fragment>
    )
  }
}