import React, { Fragment, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/outline';
import { calendarTypeList, hideItems, handleSaveFilters } from './../../../helpers/helpers';
import ReactTooltip from "react-tooltip";

export default function CalenderViewFilter(props){
  const { calendarViewType, handleCalendarViewChange, path, user } = props
  const typeList = calendarTypeList
  const [ calendarType, setCalendarType ] = useState(calendarViewType)

  const checkLableName = (type) => {
    return typeList.filter((item) => type.includes(item.value)).map((item) => item.label).join(', ')
  }

  const handleCalendarTypeChange = (type) => {
    setCalendarType(type)
    handleCalendarViewChange(type)
    handleSaveFilters(type, '', 'calendarViewType', user?.id)
    hideItems('class')
  }

  return(
    <Fragment>
      <div className="relative filters_box"> 
        <Menu as="div" className="relative block text-left">
          <div>
            <Menu.Button className={`flex justify-center w-full ${path == 'all-requests' ? 'rounded-lg' : 'rounded-xl'} pl-4 pr-2 py-2.5 bg-gray-custom-50 text-sm text-gray-900 focus:outline-nonefocus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 class_items_button border-2 border-transparent filter-apply-border need-border font-medium`}>
              {checkLableName(calendarType)}
              <ChevronDownIcon className="ml-auto text-gray-900 h-5 w-5" aria-hidden="true" />
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            show={true}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95">
            <Menu.Items className={`origin-top-right z-30 right-0 w-full ${path == 'all-requests' ? 'rounded-xl' : 'rounded-2xl'} bg-white shadow-md ring-2 ring-gray-500 focus:outline-none mt-2 hidden class_items filter_items absolute`}>
              <div className="py-2 max-h-60 overflow-y-auto">
                <div>
                  {calendarTypeList.map((type, i) => (
                    <Menu.Item key={i}>
                      {({ active }) => (
                        <a className={`flex items-center justify-between px-3 text-gray-900 text-sm mx-2 py-2 rounded-md cursor-pointer ${calendarType == type?.value ? 'bg-custom-gray2' : ''}`} onClick={() => handleCalendarTypeChange(type?.value)}>
                          {type?.label}
                        </a>
                      )}
                    </Menu.Item>
                  ))}
                </div>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </Fragment>
  )
}