import React, { Component, Fragment } from 'react'
import Cookies from 'js-cookie';
import ReactTooltip from "react-tooltip";
import Pagination from "react-js-pagination";
import { debounce } from "lodash";
import Creatable, { components } from "react-select";
import {StickyTable, Row, Cell} from 'react-sticky-table';
import { Dialog, Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon, ExclamationIcon, XIcon } from '@heroicons/react/outline'
import { getClasses, syncClasses, processSyncClasses, createClass, deleteClass, z, downloadClasses, getAbcRegions, getRegionLocations, exportClasses, filterClasses, uploadClasses, downloadRemoteData, getSkills, createSkill, updateClass, syncBusinessClasses, handleSaveFilters, checkFilterSaved } from './helpers'
import { getSudomain, setTitle, checkSettingsPermissios } from './../../helpers'
import ClassesMassActionBar from './components/classesMassActionBar';
import AssignSkillsPopup from './components/assignSkillsPopup';

const menu = (props) => {
  const optionSelectedLength = props.getValue().length || 0;
  return (
    <components.Menu {...props}>
      {optionSelectedLength < 3 ? (
        props.children
      ) : (
        <div style={{ margin: 15 }}>You can select up to 3 locations at a time for this report.</div>
      )}
    </components.Menu>
  );
};

function hideItems(cls){
  $('.filter_items').each(function(i, obj) {
    if($(obj).hasClass(`${cls}_items`)){
      $(`.${cls}_items`).toggleClass("hidden")
    }else{
      $(obj).addClass("hidden")
    }
  });
}

export default class Classes extends Component {
  constructor(props){
    super(props);
    this.state = {
      classes: [],
      show: false,
      alert_type: '',
      alert_message: '',
      loaded: false,
      site_ids: [],
      direct_sync: false,
      remote_classes: [],
      syncModal: false,
      sync_type: '',
      classestoAdd: [],
      selectedSiteId: 0,
      classModal: false,
      classFormType: '',
      skills: [],
      abcRegions: [],
      locations: [],
      regionLocations: [],
      selectedAbcLocations: [],
      class: {
        id: 0,
        name: '',
        description: '',
        business_category: {
          id: ''
        },
        second_skill: {
          id: ''
        },
        external_id: '',
        mindbody_id: '',
        mbo_site_id: '',
      },      
      deleteClass: {
        id: 0,
        name: ''
      },
      deleteModal: false,
      abcClassesLoading: false,
      activePage: 1,
      total: 0,
      downloadModal: false,
      classes_noskill_count: 0,
      baseCount: 0,
      sortType: '',
      sortReverse: false,
      search: '',
      formInvalid: true,
      fetching: false,
      syncing: false,
      allSelected: false,
      classesToDownload: [],
      newSkill: false,
      newSkillName: '',
      selectedLocs: [],
      activeCheck: [],
      selectedActive: false,
      selectedFilterSiteId: [],
      dataLoaded: true,
      syncClassesDisabled: false,
      selectedClassesForMassAction: [],
      showAssignSkillPopup: false,
      massActionSkills: []
    }
  }

  getAll = (pageNumber) => {
    getClasses(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), pageNumber).then(      
      response => response.json()
    )
    .then(result => {
      if(result.status === 200){
        this.setState({
          classes: result.results,
          loaded: true,
          dataLoaded: true,
          site_ids: [],
          direct_sync: false,
          remote_classes: [],
          syncModal: false,
          sync_type: '',
          classestoAdd: [],
          selectedSiteId: 0,
          classModal: false,
          classFormType: '',
          skills: [],
          class: {
            id: 0,
            name: '',
            description: '',
            business_category: {
              id: ''
            },
            second_skill: {
              id: ''
            },
            external_id: '',
            mindbody_id: '',
            mbo_site_id: '',
          },
          deleteClass: {
            id: 0,
            name: ''
          },
          deleteModal: false,
          classes_noskill_count: result.classes_noskill_count,
          total: result.total,
          baseCount: (pageNumber-1)*30,
          allSelected: false,
          selectedFilterSiteId: [],
          selectedClassesForMassAction: []
        })
      }else{
        this.setState({
          alert_message: result.error,
          alert_type: 'danger',
          show: true
        })
        this.setState({show:true},()=>{
          window.setTimeout(()=>{
            this.setState({show:false})
          },3000)
        });
      }
    })
  }

  componentDidMount = () => {
    setTitle('classes')
    // window.setSidebarActive('classes')
    if(Object.keys(this.props?.user).length !== 0 && Object.keys(this.props?.permissions).length !== 0){
      checkSettingsPermissios('classes', this.props)
    }
    if(this.props.pageLoaded){
      this.checkLastFilters()
      this.fetchSkills()
    }
  }

  fetchSkills = () => {
    getSkills(Cookies.get(`currentUser.${getSudomain()}`), getSudomain()).then(      
      response => response.json()
    )
    .then(result => {
      if(result.status === 200){
        this.setState({
          massActionSkills: result.results,
        })
      }else{
        this.setState({
          alert_message: result.error,
          alert_type: 'danger',
          show: true
        })
        this.setState({show: true}, ()=>{
          window.setTimeout(()=>{
            this.setState({show:false})
          },3000)
        });
      }
    })
  }

  checkLastFilters = () => {
    let subdomain = this.props?.user?.subdomain
    if(checkFilterSaved(this.props?.user?.id, subdomain, 'classes')){
      let lastFilters = JSON.parse(localStorage.getItem(`classesFilters_${subdomain}`));
      if(lastFilters){
        let sortType = lastFilters?.sort?.type || ''
        let sortReverse = lastFilters?.sort?.order || false
        let search = lastFilters?.search || ''
        let siteIds = lastFilters?.mboSiteIds || []
        this.setState({
          sortType: sortType,
          sortReverse: sortReverse,
          search: search,
          selectedFilterSiteId: siteIds
        })
        if(siteIds.length == 0 && sortType == '' && search == ''){
          this.getAll(1)
        }else{
          this.processFilter(1, search, sortType, sortReverse, siteIds)
        }
      }else{
        this.getAll(1)
      }
    }else{
      localStorage.removeItem(`classesFilters_${subdomain}`);
      this.getAll(1)
    }
  }

  handlePageChange(pageNumber) {
    this.setState({
      activePage: pageNumber,
      dataLoaded: false,
      selectedClassesForMassAction: []
    });
    this.paginatedResult(pageNumber)
  }

  componentWillUnmount() {
    this.setState = (state,callback)=>{
      return;
    };
  }

  setClasses = (classes) => {
    let req_classes = []
    let down_classes = []
    let check_active = []
    for(var i=0; i < classes.length; i++){      
      var o = {}
      var obj = {}
      var o_check = {}
      switch(this.state.sync_type){
        case 'mbo':
          if(classes[i].existing !== true){
            o[classes[i].mindbody_id] = classes[i].existing;
            obj[classes[i].mindbody_id] = classes[i].existing;
            o_check[classes[i].mindbody_id] = classes[i].existing;
            o_check['active'] = classes[i].active;
          }else{
            obj[classes[i].mindbody_id] = classes[i].existing;
            o[classes[i].mindbody_id] = classes[i].existing;
          }
          break;
        case 'marinatek':
          if(classes[i].existing !== true){
            o[classes[i].marianatek_id] = classes[i].existing;
            obj[classes[i].marianatek_id] = classes[i].existing;
            o_check[classes[i].marianatek_id] = classes[i].existing;
            o_check['active'] = classes[i].active;
          }else{
            obj[classes[i].marianatek_id] = classes[i].existing;
            o[classes[i].marianatek_id] = classes[i].existing;
          }
          break;
        case 'abc':
          if(classes[i].existing !== true){
            o[classes[i].abc_id] = classes[i].existing;
            obj[classes[i].abc_id] = classes[i].existing;
          }else{
            obj[classes[i].abc_id] = classes[i].existing;
            o[classes[i].abc_id] = classes[i].existing;
          }
          break;   
      }     
      req_classes.push(o)
      down_classes.push(obj)
      check_active.push(o_check)
    }
    this.setState({
      classestoAdd: req_classes,
      classesToDownload: down_classes,
      activeCheck: check_active
    })
  }

  sync_classes = (e, sync_type) => {
    e.preventDefault()
    let site_ids = []
    e.target.classList.add("btn-disabled");
    const elem = e.target
    this.setState({
      fetching: true
    })

    switch(sync_type){
      case 'mbo':
        site_ids = this.props.user.assigned_mbo_site_ids
        break;
      case 'marinatek': 
        site_ids = ['']
        break;
      case 'abc':
        site_ids = ['']
        break;
    }

    this.setState({ site_ids, sync_type })

    if(sync_type === 'abc'){
      getAbcRegions(Cookies.get(`currentUser.${getSudomain()}`), getSudomain()).then(      
        response => response.json()
      )
      .then(result => {
        elem.classList.remove("btn-disabled");
        if(result.status === 200){
          if(result.results.length > 0){
            this.setState({
              abcRegions: result.results,
              syncModal: true,
              fetching: false
            })
            if(result.results.length === 1){
              this.filterLocations(result.results[0].id)
            }
          }else{            
            this.showClasses()
          }
        }else{
          this.setState({
            alert_message: result.error,
            alert_type: 'danger',
            show: true
          })
          this.setState({show:true},()=>{
            window.setTimeout(()=>{
              this.setState({show:false})
            },3000)
          });
        }
      })
    }else{
      if(site_ids.length > 1){
        this.setState({
          direct_sync: false,
          syncModal: true,
          fetching: false
        })
        elem.classList.remove("btn-disabled");
      }else{
        this.setState({
          direct_sync: true,
          selectedSiteId: site_ids[0]
        })
        syncClasses(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), sync_type, site_ids[0]).then(      
          response => response.json()
        )
        .then(result => {   
          elem.classList.remove("btn-disabled");
          if(result.status === 200){
            this.setClasses(result.classes)
            this.setState({
              remote_classes: result.classes,
              syncModal: true,
              fetching: false
            })
          }else{
            this.setState({
              alert_message: result.error,
              alert_type: 'danger',
              show: true,
              syncModal: false,
              fetching: false
            })
            this.setState({show:true},()=>{
              window.setTimeout(()=>{
                this.setState({show:false})
              },3000)
            });
          }
        })
      }
    }
  }

  closeModal = (e) => {
    this.setState({
      syncModal: false,
      site_ids: [],
      sync_type: '',
      classModal: false,
      deleteModal: false,
      skills: [],
      abcRegions: [],
      locations: [],
      regionLocations: [],
      selectedAbcLocations: [],
      classestoAdd: [],
      remote_classes: [],
      allSelected: false,
      downloadModal: false,
      newSkill: false,
      newSkillName: '',
      syncing: false
    })
  }

  toggleRequestClass = (e, cls) => {
    if(cls.existing){
      return;
    }
    
    let existingClasses = this.state.classestoAdd;
    let existingDownload = this.state.classesToDownload
    let checked = e.target.checked;
    
    for(var i=0; i < existingClasses.length; i++){
      switch(this.state.sync_type){
        case 'mbo':
          if (typeof existingClasses[i][cls.mindbody_id] !== 'undefined' ) {
            existingClasses[i][cls.mindbody_id] = checked
            existingDownload[i][cls.mindbody_id] = checked
          }
          break;
        case 'marinatek':
          if (typeof existingClasses[i][cls.marianatek_id] !== 'undefined' ) {
            existingClasses[i][cls.marianatek_id] = checked
            existingDownload[i][cls.marianatek_id] = checked
          }
          break;
        case 'abc':
          if (typeof existingClasses[i][cls.abc_id] !== 'undefined' ) {
            existingClasses[i][cls.abc_id] = checked
            existingDownload[i][cls.abc_id] = checked
          }
          break;
      }
    }
    this.setState({
      classestoAdd: existingClasses,
      classesToDownload: existingDownload
    })
  }

  objectChecked = (cls) => {
    let existingClasses = this.state.classestoAdd;
    let checked = false
    for(var i=0; i < existingClasses.length; i++){
      if(cls.existing === true){
        checked = true
      }else{
        switch(this.state.sync_type){
          case 'mbo':
            if (typeof existingClasses[i][cls.mindbody_id] !== 'undefined' ) {
              checked = existingClasses[i][cls.mindbody_id] === true
            }
            break;
          case 'marinatek':
            if (typeof existingClasses[i][cls.marianatek_id] !== 'undefined' ) {
              checked = existingClasses[i][cls.marianatek_id] === true
            }
            break;
          case 'abc':
            if (typeof existingClasses[i][cls.abc_id] !== 'undefined' ) {
              checked = existingClasses[i][cls.abc_id] == true
            }
          break;
        }
      }
      
    }
    return checked
  }

  processSync = (e) => {
    e.preventDefault()
    e.target.classList.add("btn-disabled");
    const elem = e.target
    let site_ids = this.state.selectedSiteId
    this.setState({
      syncing: true
    })
    if(this.state.sync_type == 'abc'){
      site_ids = this.state.selectedAbcLocations
    }
    
    processSyncClasses(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), this.state.sync_type, site_ids, this.state.classestoAdd).then(      
      response => response.json()
    )
    .then(result => {
      elem.classList.remove("btn-disabled");
      if(result.status === 200){
        this.setState({
          alert_message: 'Classes Synced Successfully',
          alert_type: 'success',
          syncModal: false,
          syncing: false
        })
        this.getAll(this.state.activePage)
      }else{
        this.setState({
          alert_message: result.error,
          alert_type: 'danger',
          syncModal: false,
          syncing: false
        })
      }
      this.setState({show:true},()=>{
        window.setTimeout(()=>{
          this.setState({show:false})
        },3000)
      });
    })
  }

  setAdd = (e, type) => {
    e.preventDefault()
    getSkills(Cookies.get(`currentUser.${getSudomain()}`), getSudomain()).then(      
      response => response.json()
    )
    .then(result => {
      if(result.status === 200){
        this.setState({
          skills: result.results,
          loaded: true,
          classModal: true,
          classFormType: type,
          class: {
            name: '',
            description: '',
            business_category: {
              id: ''
            },
            second_skill: {
              id: ''
            },
            external_id: '',
            mindbody_id: '',
            mbo_site_id: '',
          }
        })
      }else{
        this.setState({
          alert_message: result.error,
          alert_type: 'danger',
          show: true
        })
        this.setState({show:true},()=>{
          window.setTimeout(()=>{
            this.setState({show:false})
          },3000)
        });
      }
    })
  }

  getAllSkills(name){
    getSkills(Cookies.get(`currentUser.${getSudomain()}`), getSudomain()).then(      
      response => response.json()
    ).then(result => {
      if(result.status === 200){
        this.setState({
          skills: result.results,
          newSkill: false
        }, () => {
          this.setSkillSelected(name);
        })
      }else{
        this.setState({
          alert_message: result.error,
          alert_type: 'danger',
          show: true
        })
        this.setState({show:true},()=>{
          window.setTimeout(()=>{
            this.setState({show:false})
          },3000)
        });
      }
    })
  }

  handleChange = (e) => {
    // let valid = faCloudShowersHeavy
    let { business } = this.props
    const { name, value } = e.target;
    this.setState({
      class: {
        ...this.state.class,
        [name]: value
      }
    })
    let formclass = this.state.class
    // if(formclass.name === '' || formclass.description === ''){
    //   valid = false
    // }else{
    //   valid = true
    // }
  }

  setSkill = (id) => {
    this.setState({
      class: {
        ...this.state.class,
        business_category: {
          id: id
        }
      }
    })
  }

  setSecondSkill = (e) => {
    e.preventDefault()
    this.setState({
      class: {
        ...this.state.class,
        second_skill: {
          id: e.target.value
        }
      }
    })
  }

  addClass = (e) => {
    e.preventDefault()
    createClass(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), this.state.class).then(
      response => response.json()
    )
    .then(result => {
      if(result.status === 200){
        this.setState({
          alert_message: 'Business Class successfully created',
          alert_type: 'success',          
          loaded: false,
          classModal: false
        })
        this.processFilter(this.state.activePage, this.state.search, this.state.sortType, this.state.sortReverse, this.state.selectedFilterSiteId)
      }else{
        this.setState({
          alert_message: result.error,
          alert_type: 'danger'          
        })
      }
      this.setState({show:true},()=>{
        window.setTimeout(()=>{
          this.setState({show:false})
        },3000)
      });
    })
  }

  setDelete = (e, cls) => {
    e.preventDefault()
    this.setState({
      deleteClass: {
        id: cls.id,
        name: cls.name
      },
      deleteModal: true
    })
  }

  processDelete = (e) => {
    e.preventDefault()
    deleteClass(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), this.state.deleteClass).then(
      response => response.json()
    )
    .then(result => {
      if(result.status === 200){
        this.setState({
          alert_message: 'Business Class successfully Deleted',
          alert_type: 'success',          
          loaded: false,          
        })
        this.processFilter(this.state.activePage, this.state.search, this.state.sortType, this.state.sortReverse, this.state.selectedFilterSiteId)
      }else{
        this.setState({
          alert_message: result.error,
          alert_type: 'danger'
        })
      }
      this.setState({show:true},()=>{
        window.setTimeout(()=>{
          this.setState({show:false})
        },3000)
      });
    })
  }

  setEdit = (e, type, cls) => {
    e.preventDefault()
    getSkills(Cookies.get(`currentUser.${getSudomain()}`), getSudomain()).then(      
      response => response.json()
    )
    .then(result => {
      if(result.status === 200){
        let second_skill = {}
        if(this.props.business.second_skill_enabled){
          second_skill = cls.second_skill
        }
        this.setState({
          skills: result.results,
          loaded: true,
          classModal: true,
          classFormType: type,
          class: {
            id: cls.id,
            name: cls.name,
            description: cls.description,
            business_category: {
              id: cls.business_category === null ? "" : cls.business_category.id
            },
            external_id: cls.external_id,
            second_skill: second_skill,
            mindbody_id: cls.mindbody_id,
            mbo_site_id: cls.mbo_site_id,
          }
        })
      }else{
        this.setState({
          alert_message: result.error,
          alert_type: 'danger',
          show: true
        })
        this.setState({show:true},()=>{
          window.setTimeout(()=>{
            this.setState({show:false})
          },3000)
        });
      }
    })
  }

  editClass = (e) => {
    e.preventDefault()
    updateClass(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), this.state.class).then(
      response => response.json()
    )
    .then(result => {
      if(result.status === 200){
        this.setState({
          alert_message: 'Business Class successfully updated',
          alert_type: 'success',          
          loaded: false,
          classModal: false
        })
        this.processFilter(this.state.activePage, this.state.search, this.state.sortType, this.state.sortReverse, this.state.selectedFilterSiteId)
      }else{
        this.setState({
          alert_message: result.error,
          alert_type: 'danger'
        })
      }
      this.setState({show:true},()=>{
        window.setTimeout(()=>{
          this.setState({show:false})
        },3000)
      });
    })
  }

  download = (e, type) => {
    e.preventDefault()
    downloadClasses(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), type, this.state.selectedAbcLocations).then(      
      response => response.blob()
      .then(result => { 
        const url = window.URL.createObjectURL(result)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', getSudomain() + "_classes.csv")
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)        
      })
    )
  }

  filterLocations = (val) => {
    if(val === ""){
      this.setState({
        regionLocations: []
      })
      return;
    }
    this.setState({
      selectedAbcLocations: [],
      selectedLocs: []
    })
    getRegionLocations(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), val).then(      
      response => response.json()
    )
    .then(result => {
      if(result.status === 200){
        this.setState({
          regionLocations: result.results
        })
      }else{
        this.setState({
          alert_message: result.error,
          alert_type: 'danger',
          show: true
        })
        this.setState({show:true},()=>{
          window.setTimeout(()=>{
            this.setState({show:false})
          },3000)
        });
      }
    })
  }

  handleSelectionChange = (val) => {
    let selectedAbcLocations = val.map(a => a.value);
    this.setState({ 
      selectedAbcLocations: selectedAbcLocations,
      selectedLocs: val
    })
  }

  showAbcData = (e) => {
    e.preventDefault()
    let locations = this.state.selectedAbcLocations.join(",")
    this.setState({
      abcClassesLoading: true
    })
    syncClasses(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), this.state.sync_type, locations).then(      
      response => response.json()
    )
    .then(result => {   
      if(result.status === 200){
        this.setClasses(result.classes)
        this.setState({
          remote_classes: result.classes,
          abcClassesLoading: false
        })
      }else{
        this.setState({
          alert_message: result.error,
          alert_type: 'danger',
          show: true,
          syncModal: false
        })
        this.setState({show:true},()=>{
          window.setTimeout(()=>{
            this.setState({show:false})
          },3000)
        });
      }
    })
  }

  selectActive = (e) => {
    let existingClasses = this.state.classestoAdd;
    let check_active = this.state.activeCheck;
    let val = e.target.checked
    for(var i=0; i < check_active.length; i++){
      // if(check_active[i] == existingClasses[i]){
      //   console.log(check_active[i])
      // }
      if(check_active[i].active && check_active[i].active === true){
        Object.keys(existingClasses[i]).map((property) => {
          existingClasses[i][property] = val;
        });
      }
    }
    this.setState({
      classestoAdd: existingClasses,
      selectedActive: val
    })
  }

  checkAll = (e, val) => {
    e.preventDefault()
    this.selectAll(val)      
  }

  selectAll = (val) => {
    let existingClasses = this.state.classestoAdd;
    let existingDownload = this.state.classesToDownload
    for(var i=0; i < existingClasses.length; i++){
      Object.keys(existingClasses[i]).map((property) => {
        existingClasses[i][property] = val;
        existingDownload[i][property] = val;
      });
    }    
    this.setState({
      classestoAdd: existingClasses,
      allSelected: val,
      classesToDownload: existingDownload
    }) 
  }

  export = (e, business) => {
    e.preventDefault()        
    exportClasses(Cookies.get(`currentUser.${getSudomain()}`), getSudomain()).then(      
      response => response.blob()
      .then(result => { 
        const url = window.URL.createObjectURL(result)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', "classes.csv")
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)        
      })
    )
  }

  downloadAbc = (e) => {
    e.preventDefault()
    getAbcRegions(Cookies.get(`currentUser.${getSudomain()}`), getSudomain()).then(      
      response => response.json()
    )
    .then(result => {
      if(result.status === 200){
        if(result.results?.length > 0){
          this.setState({
            abcRegions: result.results,
            syncModal: true,
            downloadModal: true,
            sync_type: 'abc'
          })
          if(result.results?.length === 1){
            this.filterLocations(result.results[0].id)
          }
        }else{   
          this.setState({
            downloadModal: true,
            sync_type: 'abc'
          })         
          this.showClasses()
        }
      }else{
        this.setState({
          alert_message: result.error,
          alert_type: 'danger',
          show: true
        })
        this.setState({show:true},()=>{
          window.setTimeout(()=>{
            this.setState({show:false})
          },3000)
        });
      }
    })
  }

  search = (e) => {
    e.preventDefault();
    this.setState({
      search: e.target.value
    })
    this.filter(e.target.value)
  }

  sort = (e, param, type) => {
    e.preventDefault()
    let sort_desc = false
    if(type === 'asc'){
      let sortRev =  this.state.sortReverse
      if(sortRev === false){
        sort_desc = true
      }
      else{
        sort_desc = false
      }
    }else{
      sort_desc = true
    }

    this.setState({
      sortType: param,
      sortReverse: sort_desc,
      dataLoaded: false
    })
    let data = {
      type: param,
      order: sort_desc
    }
    this.updateFilterInState(data, 'sort')
    this.processFilter(1, this.state.search, param, sort_desc, this.state.selectedFilterSiteId)
  }

  debounceTest = debounce(val => {
    this.performSearch(val)
  }, 800);

  performSearch = (search) => {
    this.updateFilterInState(search, 'search')
    this.processFilter(1, search, this.state.sortType, this.state.sortReverse, this.state.selectedFilterSiteId)
  }

  paginatedResult = (pageNumber) => {
    this.processFilter(pageNumber, this.state.search, this.state.sortType, this.state.sortReverse, this.state.selectedFilterSiteId)
  }

  filter = (search) => {
    this.setState({
      dataLoaded: false
    })
    this.debounceTest(search)
  }

  selectMboSite = (e) => {
    this.setState({
      abcClassesLoading: true,
      skills: [],
      abcRegions: [],
      locations: [],
      regionLocations: [],
      selectedAbcLocations: [],
      classestoAdd: [],
      remote_classes: [],
      selectedSiteId: e.target.value
    })
    syncClasses(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), 'mbo', e.target.value).then(      
      response => response.json()
    )
    .then(result => {   
      if(result.status === 200){
        this.setClasses(result.classes)
        this.setState({
          remote_classes: result.classes,
          syncModal: true,
          abcClassesLoading: false
        })
      }else{
        this.setState({
          alert_message: result.error,
          alert_type: 'danger',
          show: true,
          syncModal: false
        })
        this.setState({show:true},()=>{
          window.setTimeout(()=>{
            this.setState({show:false})
          },3000)
        });
      }
    })
  }

  setMboSiteIDSkill = (e) => {
    this.setState({
      class: {
        ...this.state.class,
        mbo_site_id: e.target.value
      }
    })
  }

  upload = (e) => {
    e.preventDefault()
    this.setState({
      dataLoaded: false
    })
    uploadClasses(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), e.target.files[0]).then(      
      response => response.json()
      .then(result => { 
        if(result.status === 200){
          this.setState({
            alert_message: 'Classes successfully uploaded!',
            alert_type: 'success',            
            loaded: true
          })
          this.getAll(this.state.activePage)
        }else{
          this.setState({
            alert_message: result.error,
            alert_type: 'danger',            
            loaded: true
          })
        }
        this.setState({show:true},()=>{
          window.setTimeout(()=>{
            this.setState({show:false})
          },3000)
        });
      })
    )
  }

  showClasses = () => {    
    getRegionLocations(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), '').then(      
      response => response.json()
    )
    .then(result => {
      if(result.status === 200){
        this.setState({
          regionLocations: result.results,
          syncModal: true,
          fetching: false
        })
      }else{
        this.setState({
          alert_message: result.error,
          alert_type: 'danger',
          show: true
        })
        this.setState({show:true},()=>{
          window.setTimeout(()=>{
            this.setState({show:false})
          },3000)
        });
      }
    })
  }

  downloadRemote = (e, type) => {
    e.preventDefault()
    downloadRemoteData(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), type, this.state.selectedSiteId, this.state.classesToDownload).then(      
      response => response.blob()
      .then(result => { 
        const url = window.URL.createObjectURL(result)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${type}_classes.csv`)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
    )
  }

  setNewSkill = (e, val) => {
    this.setState({
      newSkill: val,
      newSkillName: ''
    })
  }

  handleNewSkillChange = (e) => {
    e.preventDefault()
    this.setState({
      newSkillName: e.target.value
    })
  }

  saveNewSkill = (e) => {
    let a = {name: this.state.newSkillName}
    let skillName = a.name
    if(this.state.newSkillName === ''){
      this.setState({
        alert_message: 'Please enter Skill Name',
        alert_type: 'danger'
      })
      this.setState({show:true},()=>{
        window.setTimeout(()=>{
          this.setState({show:false})
        },2000)
      });
      return;
    }
    e.preventDefault()
    createSkill(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), a).then(
      response => response.json()
    )
    .then(result => {
      if(result.status === 200){
        this.setState({
          alert_message: 'Skill successfully created',
          alert_type: 'success',
        })
        this.getAllSkills(skillName)        
      }else{
        this.setState({
          alert_message: result.error,
          alert_type: 'danger'          
        })
      }
      this.setState({show:true},()=>{
        window.setTimeout(()=>{
          this.setState({show:false})
        },2000)
      });
    })
  }

  setSkillSelected = (name) => {
    let skills = this.state.skills
    var selected = skills.find(obj => {
      return obj.name === name
    })
    this.setSkill(selected.id)
  }

  selectSiteIds = (e) => {
    let val = parseInt(e.target.value)
    var __FOUND = this.state.selectedFilterSiteId.includes(val)
    if(e.target.checked){
      if(!__FOUND){
        let newSite = [...this.state.selectedFilterSiteId, val]
        this.setState(state => ({ selectedFilterSiteId: newSite }));
      }
    }else{
      if(__FOUND){
        const { selectedFilterSiteId } = this.state;
        let newSite = selectedFilterSiteId.filter((tag, index) => tag !== val)  
        this.setState({
          selectedFilterSiteId: newSite        
        }); 
      }      
    }
  }

  checkSelectedAllSiteIds = () => {
    let check = this.state.selectedFilterSiteId?.length === this.props.user.assigned_mbo_sites?.length
    return check
  }

  selectedAllSiteIds = (e) => {
    if(e.target.checked){
      let all = this.props.user.assigned_mbo_sites
      let selectedFilterSiteId = []
      for(var i=0; i<all.length;i++){     
        selectedFilterSiteId.push(all[i]['site_id'])
      }
      this.setState({
        selectedFilterSiteId: selectedFilterSiteId
      })
    }else{
      this.setState({
        selectedFilterSiteId: []
      })
    }
  }  

  setSelSites = (e) => {
    this.setState({loaded: false})
    this.updateFilterInState(this.state.selectedFilterSiteId, 'mbosites')
    this.processFilter(1, this.state.search, this.state.sortType, this.state.sortReverse, this.state.selectedFilterSiteId)
  }

  cancelLocationFilter = () => {
    hideItems('location')
  }

  isDisabled = () => {
    if(this.props.business?.clubready_integration){
      if(this.state.classFormType === 'edit'){
        // if(this.props.user?.role_display === "Netgym Employee"){
        // return false
        // }
        // else{
          return true
        // }
      }
      else{
        return false
      }
    }else if(this.props.business?.fisikal_integration){
      if(this.state.classFormType === 'edit'){
        // if(this.props.user?.role_display === "Netgym Employee"){
        // return false
        // }
        // else{
          return true
        // }
      }
      else{
        return false
      }
    }else{
      return false
    }
  }

  isDisabledIntegratedIdField = () => {
    if(this.props.business?.fisikal_integration || this.props.business?.clubready_integration){
      if(this.state.classFormType === 'edit'){
        if(this.props.user?.role_display === "Netgym Employee"){
        return false
        }
        else{
          return true
        }
      }
      else{
        return false
      }
    }else{
      return false
    }
  }

  syncClassesIntegrations = (e, business) => {
    e.preventDefault();
    this.setState({syncClassesDisabled: true})
    syncBusinessClasses(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), business).then(result => {
      if(result.status === 200){
        this.setState({
          alert_message: (result?.message && result?.message !== '') ? result?.message : 'Your classes will be synced shortly',
          alert_type: 'success',
          show: true,
          syncClassesDisabled: false
        })
        window.setTimeout(() => { this.setState({show: false}) }, 3000);
      }else{
        this.setState({
          alert_message: result.error,
          alert_type: 'danger',
          show: true,
          syncClassesDisabled: false
        })
        window.setTimeout(() => { this.setState({show: false}) }, 3000);
      }
    })
  }

  processFilter = (page, text, sortType, sortReverse, selectedFilterSiteId) => {
    filterClasses(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), page, text, sortType, sortReverse, selectedFilterSiteId).then(      
      response => response.json()
    )
    .then(result => {
      if(result.status === 200){
        this.setState({
          classes: result.results,
          dataLoaded: true,
          loaded: true,
          activePage: parseInt(result.page),
          baseCount: (parseInt(result.page)-1)*30,
          classes_noskill_count: result.classes_noskill_count,
          total: result.total
        })
      }else{
        this.setState({
          alert_message: result.error,
          dataLoaded: true,
          alert_type: 'danger',
          show: true
        })
        this.setState({show:true},()=>{
          window.setTimeout(()=>{
            this.setState({show:false})
          },3000)
        });
      }
    })
  }

  updateFilterInState = (data, filterName) => {
    handleSaveFilters(data, filterName, this.props?.user?.id, this.props?.user?.subdomain, 'classes')
  }

  handleSelectAll = (event) => {
    const { classes } = this.state;
    const { selectedClassesForMassAction } = this.state;
  
    if (event.target.checked) {
      const newSelection = [
        ...new Set([...selectedClassesForMassAction, ...classes.map((cls) => cls.id)]),
      ];
      this.setState({ selectedClassesForMassAction: newSelection });
    } else {
      const updatedSelection = selectedClassesForMassAction.filter(
        (id) => !classes.some((cls) => cls.id === id)
      );
      this.setState({ selectedClassesForMassAction: updatedSelection });
    }
  };

  handleSelectOne = (event, classId) => {
    let { selectedClassesForMassAction } = this.state;
  
    if (event.target.checked) {
      selectedClassesForMassAction = [...new Set([...selectedClassesForMassAction, classId])];
    } else {
      selectedClassesForMassAction = selectedClassesForMassAction.filter((id) => id !== classId);
    }
  
    this.setState({ selectedClassesForMassAction });
  };
  
  isSelectAllChecked = () => {
    const { classes } = this.state;
    const { selectedClassesForMassAction } = this.state;
    return classes.length > 0 && classes.every((cls) => selectedClassesForMassAction.includes(cls.id));
  };

  resetSelection = () => {
    this.setState({ selectedClassesForMassAction: [] });
  };

  completeMassAssignActions = () => {
    this.setState({
      showAssignSkillPopup: false,
      selectedClassesForMassAction: [],
      dataLoaded: false
    })
    this.processFilter(this.state.activePage, this.state.search, this.state.sortType, this.state.sortReverse, this.state.selectedFilterSiteId)
  }

  handleAssignSkills = (data) => {
    this.setState({
      showAssignSkillPopup: data
    })
  }

  closeAssignSkillsPopup = () => {
    this.setState({
      showAssignSkillPopup: false
    })
  }

  showAlert = (show, type, message) => {
    this.setState({
      show: show,
      alert_type: type,
      alert_message: message
    })
  }

  showSelectCheckbox = (business) => {
    const clubreadyFisikalIntegration = business?.clubready_integration || business?.fisikal_integration;
    
    return !clubreadyFisikalIntegration || business?.second_skill_enabled;
  }  

  render() {
    const { loaded, classes, show, alert_message, alert_type, syncModal, remote_classes, sync_type, classestoAdd, classModal, classFormType, skills, deleteModal, deleteClass, abcRegions, regionLocations, options, abcClassesLoading, downloadModal, classes_noskill_count, baseCount, sortReverse, sortType, site_ids, formInvalid, fetching, syncing, allSelected, selectedAbcLocations, newSkill, selectedLocs, selectedActive, selectedFilterSiteId, dataLoaded, search, syncClassesDisabled, selectedClassesForMassAction, showAssignSkillPopup, massActionSkills } = this.state
    const formClass = this.state.class
    const { user, business, permissions, loadingMessage } = this.props
    const isValidNewOption = (inputValue, selectValue) => inputValue.length > 0 && selectValue.length < 3;

    
    return (
      <React.Fragment>
        {show &&
          <React.Fragment>
            {alert_type === 'success' &&
              <div className="w-3/5 flex items-center bg-green-50 rounded-md px-4 py-3.5 mb-1 fixed top-1 z-50 left-0 right-0 mx-auto">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-green-400 mr-3.5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                </svg>
                <div className="text-sm text-green-800 font-medium">
                  {alert_message}
                </div>
                <a className="ml-auto">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </a>
              </div>
            }
            {alert_type === 'danger' &&
              <div className="w-3/5 flex items-center bg-red-100 rounded-md px-4 py-3.5 mb-1 fixed top-1 z-50 left-0 right-0 mx-auto">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-red-300 mr-3.5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                </svg>
                <div className="text-sm text-red-700 font-medium">
                  {alert_message}
                </div>
                <a className="ml-auto">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-red-300" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </a>
              </div>
            }  
          </React.Fragment>
        }
        {showAssignSkillPopup && <AssignSkillsPopup showAssignSkillPopup={showAssignSkillPopup} closeAssignSkillsPopup={this.closeAssignSkillsPopup} skills={massActionSkills} selectedClassesForMassAction={selectedClassesForMassAction} showAlert={this.showAlert} completeMassAssignActions={this.completeMassAssignActions} business={business} />}
        <div className='w-full min-h-screen bg-gray-50'>
          <div className='h-full'>
            {!loaded && 
              <div className="msg-loading flex flex-col items-center w-full justify-center h-screen">
                <p className="mb-1 text-center px-5">{loadingMessage}</p>
                <img className = "w-9" src="/assets/business/loading.gif" />
              </div>
            }
            {loaded && 
              <div className='max-w-8xl mx-auto h-full'>
                <div className='hidden mt-2 mb-5'>
                  <div className='bg-white break-words p-3 border border-gray-200 rounded-lg'>
                    {classes_noskill_count > 0 &&
                      <p className="text-center text-gray-500 text-sm font-normal mb-2">
                        <span className="settings-text class-detail-count">
                          You have <strong>{classes_noskill_count}</strong> classes without skill.
                        </span>
                      </p>
                    }
                    <p className="text-gray-500 text-sm font-normal">
                    Add your classes and their descriptions right here! It will make it easier when entering sub request details while also ensuring the info is correct.
                    </p>
                  </div>
                </div>
                <div className='hidden bg-blue-50 p-4 rounded-md gap-3'>
                  <div className='flex'>
                    <div>
                      <svg xmlns="http://www.w3.org/2000/svg" className="text-blue-400 h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd" />
                      </svg>
                    </div>
                    <div className='flex flex-col ml-3.5'>
                      <div className='text-blue-800 text-sm'><span className='font-semibold'>4 new Classes</span> has been detected.</div>
                      <div className='text-blue-800 text-sm'>You can now activate them into NetGym</div>
                    </div>
                  </div>
                </div>
                <div className='w-full md:pt-3 px-0 lg:flex flex items-center gap-2 flex-wrap'>
                  
                  <div className='w-full lg:w-44 relative bg-gray-300 rounded-md'>
                    <div className='w-full bg-white border border-gray-300 shadow-sm rounded-md h-9 flex items-center pr-1.5'>
                      <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-400 h-4 w-4 ml-3.5" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd"></path></svg>
                      <input type="text" className="text-sm w-full border-0 h-9 ml-1.5 focus:outline-none bg-transparent" placeholder="Search for classes" onChange = {(e) => this.search(e)} value={search}/>
                    </div>
                  </div>
                  {(permissions?.business_classes?.write || user.role_display === "Netgym Employee") &&
                    <React.Fragment>
                      {(business?.abc_integration || business?.marianatek_integration || business?.mbo_integration) ?
                        <div className=''>
                          <a className='bg-dark-blue text-white text-sm rounded-md py-2 px-4 font-medium h-9 block cursor-pointer hover:bg-gray-800' onClick={(e) => this.sync_classes(e, business?.mbo_integration ? 'mbo' : business?.marianatek_integration ? 'marinatek' : business?.abc_integration ? 'abc' : '')}>
                            {fetching &&
                              <React.Fragment>
                                <div className="flex items-center justify-center">
                                  <svg className="animate-spin -ml-1 mr-1.5 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                  </svg>
                                  <p className="text-center">{window.getStartSyncSlogan()}</p>
                                </div>
                              </React.Fragment>
                            }
                            {!fetching &&
                              <React.Fragment>
                                Add Classes from {business?.mbo_integration ? 'MBO' : business?.marianatek_integration ? 'MT' : business?.abc_integration ? 'ABC' : ''}
                              </React.Fragment>
                            }
                          </a>
                        </div>
                        :
                        ((!business.crunch_integration && !business?.clubready_integration && !business?.fisikal_integration) ?
                          <div>
                            <a className="bg-dark-blue hover:bg-gray-800 text-white text-sm rounded-md py-2 px-4 font-medium h-9 block cursor-pointer" onClick={(e) => this.setAdd(e, 'add')}>Add New Class</a>
                          </div>
                          :
                          (user.role_display === "Netgym Employee" && !business?.fisikal_integration && !business?.clubready_integration &&
                            <div>
                              <a className="bg-dark-blue hover:bg-gray-800 text-white text-sm rounded-md py-2 px-4 font-medium h-9 block cursor-pointer" onClick={(e) => this.setAdd(e, 'add')}>Add New Class</a>
                            </div>
                          )
                        )
                      }
                    </React.Fragment>
                  }
                  <div>
                    <a className="border rounded-md hover:bg-gray-100 border-gray-300 shadow-sm text-gray-700 text-sm py-2 px-4 h-9 block cursor-pointer" onClick={(e) => this.export(e, business)}>Export Classes</a>
                  </div>
                  {user.role_display === "Netgym Employee" &&
                    <React.Fragment>
                      <div>
                        <div className="border rounded-md hover:bg-gray-100 border-gray-300 shadow-sm text-gray-700 text-sm py-2 px-4 relative h-9 block cursor-pointer">Upload CSV<input type="file" className="absolute w-full top-0 left-0 bottom-0 opacity-0" accept=".csv" onChange={(e) => this.upload(e)}/></div>
                      </div>
                    </React.Fragment>
                  }

                  {(business?.clubready_integration || business?.fisikal_integration || business?.crunch_integration) && (permissions?.business_classes?.write || user.role_display === "Netgym Employee") &&
                    <React.Fragment>
                      <div>
                        <button
                          className={`border rounded-md hover:bg-gray-100 border-gray-300 shadow-sm text-gray-700 text-sm py-2 px-4 h-9 block ${((!business.show_sync_classes && (business?.clubready_integration || business?.fisikal_integration)) || syncClassesDisabled) ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'}`}
                          disabled={(!business?.show_sync_classes && (business?.clubready_integration || business?.fisikal_integration)) || syncClassesDisabled}
                          onClick={(e) => this.syncClassesIntegrations(e, business)}
                        >
                          Sync Classes
                        </button>
                      </div>
                    </React.Fragment>
                  }
                  
                  {(business?.abc_integration || business?.marianatek_integration || business?.mbo_integration) &&
                    <React.Fragment>
                      {(user.role_display === "Netgym Employee") &&
                        <div>
                          <a className='border rounded-md border-gray-300 shadow-sm text-gray-700 text-sm py-2 px-4 h-9 block cursor-pointer hover:bg-gray-100' onClick={(e) => this.setAdd(e, 'add')}>+ Class (manual)</a>
                        </div>
                      }
                      {(!business?.mbo_integration && user.role_display === "Netgym Employee") &&
                        <div>
                          <a className='border rounded-md border-gray-300 shadow-sm text-gray-700 text-sm py-2 px-4 h-9 block cursor-pointer hover:bg-gray-100' onClick={(e) => {business?.abc_integration ? this.downloadAbc(e) : this.download(e, 'marinatek')}}>Download Classes from {business?.marianatek_integration ? 'MT' : business?.abc_integration ? 'ABC' : ''}</a>
                        </div>
                      }
                    </React.Fragment>
                  }
                  {(business.mbo_integration && user.assigned_mbo_sites?.length > 1) &&
                    <div className="relative filters_box  w-full lg:w-64">
                    <Menu as="div" className="relative block text-left">
                      <div>
                        <Menu.Button className="inline-flex items-center justify-center w-full rounded border border-gray-300 shadow-sm pl-4 pr-2 py-2 bg-white text-sm text-gray-400 hover:bg-gray-50 focus:outline-none focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 location_items_button">
                          Select MBO Site
                          <ChevronDownIcon className="ml-auto text-gray-400 w-4 mr-0.5" aria-hidden="true" />
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        show={true}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95">
                        <Menu.Items className="origin-top-right z-10 absolute right-0 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none hidden location_items filter_items">
                          <div className="flex items-center p-2 border-b">
                            <div className=" ml-auto">
                              <label className="inline-flex items-center text-gray-400 text-sm mb-2">
                                Select All
                                <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 ml-1" checked = {this.checkSelectedAllSiteIds()} onChange = {(e) => this.selectedAllSiteIds(e)}/>
                              </label>
                            </div>
                          </div>
                          <div className="p-2 max-h-60 overflow-y-auto">
                            {user.assigned_mbo_sites.map((detail, i) =>
                              <div className="py-1" key = {i}>
                                <Menu.Item>
                                  {({ active }) => (
                                    <label className="flex items-center text-gray-900 text-sm mb-2">
                                      <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-1" onChange={(e) => this.selectSiteIds(e)} value={detail?.site_id} checked={selectedFilterSiteId.includes(detail.site_id)}/>
                                      {`${detail.site_name} (${detail.site_id})`}
                                    </label>
                                      
                                  )}
                                </Menu.Item>
                              </div>
                            )}
                          </div>
                          <div className="flex items-center p-2 border-t">
                            <div className="flex justify-center items-center w-full px-3 gap-3">
                              <div className=''>
                                <a className='bg-dark-blue text-white text-sm rounded-md py-2 px-4 font-medium h-9 block cursor-pointer' onClick={(e) => this.setSelSites(e)}>Save</a>
                              </div>
                              <div className=''>
                                <a className='bg-white text-gray-700 border border-gray-200 text-sm rounded-md py-2 px-4 font-medium h-9 block cursor-pointer' onClick={() => this.cancelLocationFilter()}>Cancel</a>
                              </div>
                            </div>
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                  }
                  
                </div>
                <div className='mt-3 overflow-x-auto sm:-mx-6 lg:-mx-8 mb-4'>
                  <div className='py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8'>
                    {!dataLoaded &&
                      <div className="msg-loading flex flex-col items-center w-full justify-center h-screen">
                        <p className="mb-1 text-center px-5">{loadingMessage}</p>
                        <img className = "w-9" src="/assets/business/loading.gif" />
                      </div>
                    }
                    {dataLoaded &&
                      <React.Fragment>
                        <div className='shadow border overflow-hidden border-b border-gray-200 sm:rounded-lg'>
                          <table className='min-w-full divide-y divide-gray-200'>
                            <thead className='bg-gray-50'>
                              <tr>
                                {this.showSelectCheckbox(business) &&
                                  <th className="pl-4 pr-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center">
                                    <div>
                                      <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-1" checked={this.isSelectAllChecked()} value={'all'} onChange={(e) => this.handleSelectAll(e)}/>
                                    </div>
                                  </th>
                                }
                                <th className='hidden lg:table-cell pl-4 pr-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                                  <div className='flex items-center'>
                                    {/* <a className='cursor-pointer' onClick={(e) => this.sort(e, 'signdown', 'asc')}>
                                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                                      </svg>
                                    </a>
                                    <a className='cursor-pointer' onClick={(e) => this.sort(e, 'signup', 'desc')}>
                                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z" clipRule="evenodd" />
                                      </svg>
                                    </a> */}
                                    {['signup', 'signdown'].includes(sortType) ?
                                    <React.Fragment>
                                      {!sortReverse &&
                                        <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-500 hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sort(e, 'signup', 'desc')}>
                                          <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h5a1 1 0 000-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM13 16a1 1 0 102 0v-5.586l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 101.414 1.414L13 10.414V16z" />
                                        </svg>
                                      }
                                      {sortReverse &&
                                        <svg xmlns="http://www.w3.org/2000/svg" className="cursor-pointer hover:text-gray-400 min-w-16 text-gray-500" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sort(e, 'signdown', 'asc')}>
                                          <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h7a1 1 0 100-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                                        </svg>
                                      }
                                    </React.Fragment>  
                                  :
                                    <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sort(e, 'signup', 'desc')}>
                                      <path d="M5 12a1 1 0 102 0V6.414l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L5 6.414V12zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                                    </svg>
                                  }
                                  </div>
                                </th>
                                <th className='hidden lg:table-cell px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>#</th>
                                <th className='px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                                  <div className='flex items-center'>
                                    <span className='cursor-pointer' onClick={(e) => this.sort(e, 'name', 'asc')}>Name</span> &nbsp; &nbsp;
                                    {sortType !== 'name' &&
                                      <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sort(e, 'name', 'asc')}>
                                        <path d="M5 12a1 1 0 102 0V6.414l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L5 6.414V12zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                                      </svg>
                                    }
                                    {(sortType === 'name' && sortReverse) &&
                                      <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sort(e, 'name', 'asc')}>
                                        <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h7a1 1 0 100-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                                      </svg>
                                    }
                                    {(sortType === 'name' && !sortReverse) &&
                                      <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sort(e, 'name', 'desc')}>
                                        <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h5a1 1 0 000-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM13 16a1 1 0 102 0v-5.586l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 101.414 1.414L13 10.414V16z" />
                                      </svg>
                                    }
                                  </div>
                                </th>
                                <th className='hidden lg:table-cell px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>Description</th>
                                {(business.mbo_integration && !user?.assigned_single_site_id) &&
                                  <th className='hidden lg:table-cell px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>MBO Site Name</th>
                                }
                                <th className='px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                                  <div className='flex items-center'>
                                    <span className='cursor-pointer' onClick={(e) => this.sort(e, 'skill', 'asc')}>{business.second_skill_enabled ? 'Primary Skill' : 'Skill'}</span> &nbsp; &nbsp;
                                    {sortType !== 'skill' &&
                                      <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sort(e, 'skill', 'asc')}>
                                        <path d="M5 12a1 1 0 102 0V6.414l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L5 6.414V12zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                                      </svg>
                                    }
                                    {(sortType === 'skill' && sortReverse) &&
                                      <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sort(e, 'skill', 'asc')}>
                                        <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h7a1 1 0 100-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                                      </svg>
                                    }
                                    {(sortType === 'skill' && !sortReverse) &&
                                      <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sort(e, 'skill', 'desc')}>
                                        <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h5a1 1 0 000-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM13 16a1 1 0 102 0v-5.586l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 101.414 1.414L13 10.414V16z" />
                                      </svg>
                                    }
                                  </div>
                                </th>
                                {business.second_skill_enabled &&
                                  <th className='px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                                    <div className='flex items-center'>
                                      <span className='cursor-pointer' onClick={(e) => this.sort(e, 'second_skill', 'asc')}>Secondary Skill</span> &nbsp; &nbsp;
                                      {sortType !== 'second_skill' &&
                                        <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sort(e, 'second_skill', 'asc')}>
                                          <path d="M5 12a1 1 0 102 0V6.414l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L5 6.414V12zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                                        </svg>
                                      }
                                      {(sortType === 'second_skill' && sortReverse) &&
                                        <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sort(e, 'second_skill', 'asc')}>
                                          <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h7a1 1 0 100-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                                        </svg>
                                      }
                                      {(sortType === 'second_skill' && !sortReverse) &&
                                        <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sort(e, 'second_skill', 'desc')}>
                                          <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h5a1 1 0 000-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM13 16a1 1 0 102 0v-5.586l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 101.414 1.414L13 10.414V16z" />
                                        </svg>
                                      }
                                    </div>
                                  </th>
                                }
                                {(permissions?.business_classes?.write || user.role_display === "Netgym Employee") &&
                                  <th className='px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'></th>
                                }
                              </tr>
                            </thead>
                            <tbody className='divide-y divide-gray-200'>
                              {this.showSelectCheckbox(business) &&selectedClassesForMassAction?.length > 1 && 
                                <ClassesMassActionBar
                                  selectedClassesForMassAction={selectedClassesForMassAction}
                                  user={user}
                                  handleAssignSkills={this.handleAssignSkills}
                                  resetSelection={this.resetSelection}
                                />
                              }
                              {classes.map((cls, i) =>
                                <tr key={i} className='bg-white'>                                  
                                  {this.showSelectCheckbox(business) &&
                                    <td className='pl-4 pr-3 py-4 text-sm font-medium text-gray-900 text-center'>
                                      <input type="checkbox" className={`w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-1`} checked={selectedClassesForMassAction.includes(cls?.id)} value={cls?.id} onChange={(e) => this.handleSelectOne(e, cls?.id)}/>
                                    </td>
                                  }
                                  <td className='hidden lg:table-cell px-3 py-4 text-sm font-normal text-gray-500'>
                                    {cls.business_category === null &&
                                      <React.Fragment>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="text-red-600 h-4 w-4" viewBox="0 0 20 20" fill="currentColor" data-tip data-for="noSkill">
                                          <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                                        </svg>
                                        <ReactTooltip className='max-w-xs whitespace-nowrap' id="noSkill" place="top" effect="solid">
                                        You must assign a skill to this class
                                        </ReactTooltip>
                                      </React.Fragment>
                                    }
                                  </td>
                                  <td className='hidden lg:table-cell px-3 py-4 text-sm font-normal text-gray-500'>{baseCount + i + 1}</td>
                                  <td className='w-full max-w-0 sm:w-auto sm:max-w-none px-3 py-4 text-sm font-normal text-gray-500'>
                                    <div className='flex items-center'>
                                    <dl className='lg:hidden mr-0.5'>
                                        <dd>
                                          <div className='lg:hidden text-sm font-normal text-gray-500'>
                                            {cls.business_category === null &&
                                              <React.Fragment>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="text-red-600 h-4 w-4" viewBox="0 0 20 20" fill="currentColor" data-tip data-for="noSkill">
                                                  <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                                                </svg>
                                                <ReactTooltip className='w-52' id="noSkill" place="top" effect="solid">
                                                You must assign a skill to this class
                                                </ReactTooltip>
                                              </React.Fragment>
                                            }
                                          </div>
                                        </dd>
                                      </dl>
                                      <div className="truncate">{cls.name}</div>
                                      
                                    </div>
                                    <dl className='lg:hidden ml-0.5'>
                                      <dd className='mt-1 ml-0.5'>
                                      {(business.mbo_integration && !user?.assigned_single_site_id) &&
                                        <div className='text-sm font-normal text-gray-500'>{cls.site_name}</div>
                                      }
                                      </dd>
                                    </dl>
                                  </td>
                                  <td className='hidden lg:table-cell px-3 py-4 text-sm font-normal text-gray-500'>{cls.description}</td>
                                  {(business.mbo_integration && !user?.assigned_single_site_id) &&
                                    <td className='hidden lg:table-cell px-3 py-4 text-sm font-normal text-gray-500'>{cls.site_name}</td>
                                  }
                                  <td className='px-3 py-4 text-sm font-normal text-gray-500'>{cls.business_category === null ? "" : cls.business_category.name}</td>
                                  {business.second_skill_enabled &&
                                    <td className='px-3 py-4 text-sm font-normal text-gray-500'>{cls.second_skill === null ? "" : cls.second_skill.name}</td>
                                  }
                                  {(permissions?.business_classes?.write || user.role_display === "Netgym Employee") &&
                                    <td className='px-3 py-4 text-sm font-normal text-gray-500'>
                                      <div className='flex gap-x-2'>
                                        <div className='bg-gray-100 hover:bg-gray-200 w-8 h-8 rounded flex items-center justify-center cursor-pointer' onClick={(e) => this.setEdit(e, 'edit', cls)} data-tip data-for={`edit_button_`+cls?.id.toString()}>
                                          <a className='cursor-pointer'>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-700 h-4 w-4" viewBox="0 0 20 20" fill="currentColor"><path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"></path></svg>
                                          </a>
                                          <ReactTooltip className='max-w-xs whitespace-nowrap' id={`edit_button_`+cls?.id.toString()} place="top" effect="solid">
                                            Edit class
                                          </ReactTooltip>
                                        </div>
                                        {(!business?.clubready_integration && !business?.fisikal_integration) ?
                                          <div className='bg-gray-100 hover:bg-gray-200 w-8 h-8 rounded flex items-center justify-center cursor-pointer' onClick={(e) => this.setDelete(e, cls)}data-tip data-for={`delete_button_`+cls?.id.toString()}>
                                            <a className='cursor-pointer'>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-700 h-4 w-4" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd"></path></svg>
                                            </a>
                                            <ReactTooltip className='max-w-xs whitespace-nowrap' id={`delete_button_`+cls?.id.toString()} place="top" effect="solid">
                                              Delete class
                                            </ReactTooltip>
                                          </div>
                                        :
                                          (user.role_display === "Netgym Employee" &&
                                            <div className='bg-gray-100 hover:bg-gray-200 w-8 h-8 rounded flex items-center justify-center cursor-pointer' onClick={(e) => this.setDelete(e, cls)}data-tip data-for={`delete_button_`+cls?.id.toString()}>
                                              <a className='cursor-pointer'>
                                              <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-700 h-4 w-4" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd"></path></svg>
                                              </a>
                                              <ReactTooltip className='max-w-xs whitespace-nowrap' id={`delete_button_`+cls?.id.toString()} place="top" effect="solid">
                                                Delete class
                                              </ReactTooltip>
                                            </div>
                                          )
                                        }
                                      </div>
                                    </td>
                                  }
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                        <div className="flex justify-center w-full flex-wrap mt-4">
                          <div></div>
                          <div className="custom-pagination order-first sm:order-last mb-3 sm:mb-0 mr-7">
                            {this.state.total > 30 && 
                              <Pagination
                                activePage={this.state.activePage}
                                itemsCountPerPage={30}
                                totalItemsCount={this.state.total}
                                pageRangeDisplayed={9}
                                onChange={this.handlePageChange.bind(this)}
                                itemClassPrev='previous'
                                itemClassNext='next'
                                itemClassFirst='first'
                                itemClassLast='last'
                                hideDisabled={true}
                              />
                            }
                          </div>
                        </div>
                      </React.Fragment>
                    }
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
        <Transition.Root show={syncModal} as={Fragment}>
					<Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={(e) => this.closeModal(e)}>
						<div className="flex items-start justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0"
								enterTo="opacity-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100"
								leaveTo="opacity-0"
							>
								<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
							</Transition.Child>
							<span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
								&#8203;
							</span>
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								enterTo="opacity-100 translate-y-0 sm:scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 translate-y-0 sm:scale-100"
								leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							>
								<div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-20 sm:align-middle sm:max-w-4xl w-full sm:p-6 min-h-515 opacity-100 translate-y-0 sm:scale-100 mt-20">
									<div className="absolute top-0 right-0 pt-4 pr-4">
										<button
											type="button"
											className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
											onClick={(e) => this.closeModal(e)}
										>
											<span className="sr-only">Close</span>
											<XIcon className="h-6 w-6" aria-hidden="true" />
										</button>
									</div>
									<div className="sm:flex sm:items-start">
										<div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
											<Dialog.Title as="h4" className="text-lg leading-6 font-medium text-gray-900 border-b">
                        {sync_type === 'mbo' &&
                          <div className='flex'>
                            <p className='text-sm py-3.5'>MBO Classes Preview</p>
                            <a className="powered-by-mindbody-logo flex items-center ml-2 mb-1" href="https://company.mindbodyonline.com/" target="_blank">
                              <img className="w-28" src="/assets/powered-by-mindbody-new.png" />
                            </a>
                          </div>
                        }
                        {sync_type === 'marinatek' &&
                          <div className='flex'>
                            <p className='text-sm py-3.5'>Mariana Tek Classes Preview</p>
                          </div>
                        }
                        {sync_type === 'abc' &&
                          <div className='flex'>
                             <p className='text-sm py-3.5'>ABC Classes Preview</p>
                          </div>
                        }
											</Dialog.Title>
											<div className="mt-5">
                        {sync_type === 'abc' &&
                          <React.Fragment>
                            <div className='flex flex-wrap md:gap-4 gap-2'>
                              <div className='w-full md:w-72 gap-4 mb-2 items-center'>
                                {abcRegions.length > 0 &&
                                  <React.Fragment>
                                    <div className='relative'>
                                      <select className="form-select h-10 flex justify-center w-full rounded border border-gray-300 shadow-sm px-1 py-2 bg-white text-sm text-gray-400 focus:outline-nonefocus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500" name="abcRegion" defaultValue="" onChange={(e) => this.filterLocations(e.target.value)}>
                                        {abcRegions.length > 1 && <option value="">Select Region</option> }
                                        {abcRegions.map((region, i) =>
                                          <option key={region.id} value={region.id}>{region.name}</option>
                                        )}
                                      </select>
                                    </div>
                                  </React.Fragment>
                                }
                              </div>
                              <div className='w-full md:w-72 gap-4 mb-2 items-center'>
                                {sync_type === 'abc' &&
                                  <React.Fragment>
                                    <div className="relative flex mb-2">
                                      {regionLocations.length > 0 &&
                                        <Creatable
                                          className='w-full'
                                          components={{ menu }}
                                          isMulti
                                          isValidNewOption={isValidNewOption}
                                          options={regionLocations}
                                          closeMenuOnSelect={false}
                                          onChange={(val) => this.handleSelectionChange(val)}
                                          placeholder="Select Locations.."
                                          value={selectedLocs}
                                        />
                                      }
                                      {regionLocations.length === 0 &&
                                        <div className="no-data mt-2 text-center">
                                          <p className="mb-0">Please select a Region First!</p>
                                        </div>
                                      }
                                    </div>
                                  </React.Fragment>
                                }
                                
                              </div>
                            </div>
                            {selectedAbcLocations.length > 0 &&
                              <div className="mt-4 text-center">
                                {downloadModal &&
                                  <button className="cursor-pointer w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-dark-blue text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-blue sm:ml-3 sm:w-auto sm:text-sm" onClick={(e) => this.download(e, 'abc')} >
                                    Download
                                  </button>
                                }
                                {!downloadModal &&
                                  <button className={`cursor-pointer w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-dark-blue text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-blue sm:ml-3 sm:w-auto sm:text-sm`} onClick={(e) => this.showAbcData(e)}>
                                    Show ABC Data
                                  </button>
                                }
                              </div>
                            }
                          </React.Fragment>
                        }
                        {sync_type === 'mbo' &&
                          <div className='flex'>
                            {site_ids.length > 1 &&
                              <div className="relative filters_box  w-full lg:w-80">
                                <label className='block text-sm text-left font-medium text-gray-500 sm:mt-px sm:py-1'>Select Site Name</label>
                                <select className="form-select inline-flex justify-center w-full rounded border border-gray-300 shadow-sm pl-4 pr-8 py-2 bg-white text-sm text-gray-400 focus:outline-none focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500" name="siteID" defaultValue="" onChange={(e) => this.selectMboSite(e)}>
                                  <option value="">Select Site Name</option>
                                  {user.assigned_mbo_sites.map((detail, i) =>
                                    <option key={i} value={detail.site_id}>{`${detail.site_name} (${detail.site_id})`}</option>
                                  )}
                                </select>
                              </div>
                            }
                          </div>
                        }
                        <div>
                          {abcClassesLoading &&
                            <div className="sync-abc-preview-loading text-center">
                              <div className="loading-gif flex items-center justify-center flex-col my-4">
                                <p className="mb-1">{window.getSettingSlogan()}</p>
                                <img className='w-8 inline-block' src="/assets/business/loading.gif" />
                              </div>
                            </div>
                          }
                          {!abcClassesLoading &&
                            <div className="flex flex-col mt-3">
                              {remote_classes.length > 0 &&
                                <React.Fragment>
                                  {sync_type === 'marinatek' &&  
                                    <div className="custom-checkbox flex mb-3 mt-3 mr-1">
                                      <label className="custom-check">
                                        <input type="checkbox"
                                          checked={selectedActive}
                                          onChange = {(e) => this.selectActive(e)}
                                        />
                                        <span className= "checkmark green top-0"></span>
                                      </label>
                                      <div>Active</div>
                                    </div>
                                  }
                                  {sync_type === 'mbo' &&
                                    <div  className = "flex gap-3">
                                      <div className="custom-checkbox flex mb-3 mt-3">
                                        <label className="custom-check mr-1">
                                          <input type="checkbox"
                                            checked={selectedActive}
                                            onChange = {(e) => this.selectActive(e)}
                                          />
                                          <span className= "checkmark top-0"></span>
                                        </label>
                                        <div>Active</div>
                                      </div>
                                    </div>
                                  }
                                  <div className="flex-grow overflow-auto max-h-64 md:max-h-96">
                                    <table className="relative w-full border">
                                      <thead className='bg-white'>
                                        <tr className='bg-white'>
                                          <th className="sticky -top-1 px-2 py-3 font-semibold text-sm bg-white">
                                            <div>
                                              {allSelected &&
                                                <img className='w-5' onClick={(e) => this.checkAll(e, false)} src="/assets/icon_select_all.png" />
                                              }
                                              {!allSelected &&
                                                <img className='w-5' onClick={(e) => this.checkAll(e, true)} src="/assets/icon_select_blank.png" />
                                              }
                                            </div>
                                          </th>
                                          {sync_type === 'mbo' &&
                                            <React.Fragment>
                                              <th className="sticky -top-1 px-2 py-3 font-semibold text-sm bg-white whitespace-nowrap">MBO ID</th>
                                            </React.Fragment>
                                          }
                                          {sync_type === 'marinatek' &&
                                            <React.Fragment>
                                              <th className="sticky -top-1 px-2 py-3 font-semibold text-sm bg-white whitespace-nowrap">Marianatek ID</th>
                                            </React.Fragment>
                                          }
                                          {sync_type === 'abc' &&
                                            <React.Fragment>
                                              <th className="sticky -top-1 px-2 py-3 font-semibold text-sm bg-white whitespace-nowrap">ABC ID</th>
                                            </React.Fragment>
                                          }
                                          <th className="sticky -top-1 px-2 py-3 font-semibold text-sm bg-white whitespace-nowrap">Class Name</th>
                                          {sync_type !== 'abc' &&
                                            <React.Fragment>
                                              <th className="sticky -top-1 px-2 py-3 font-semibold text-sm w-5/12 break-words bg-white whitespace-nowrap">Class Description</th>
                                              <th className="sticky -top-1 px-2 py-3 font-semibold text-sm bg-white">Active</th>
                                            </React.Fragment>
                                          }
                                        </tr>
                                      </thead>
                                      <tbody className="divide-y">
                                        {remote_classes.map((cls, i) =>
                                          <tr key={i}>
                                            <td className="px-2 py-4 text-sm">
                                              <div>
                                                <label className='mb-3'>
                                                  <input type="checkbox"
                                                    checked={this.objectChecked(cls)}
                                                    onChange = {(e) => this.toggleRequestClass(e, cls)}
                                                  />
                                                </label>
                                              </div>
                                            </td>
                                            {sync_type === 'mbo' &&
                                              <React.Fragment>
                                                <td className="px-2 py-4 text-sm">{cls.mindbody_id}</td>
                                              </React.Fragment>
                                            }
                                            {sync_type === 'marinatek' &&
                                              <React.Fragment>
                                                <td className="px-2 py-4 text-sm">{cls.marianatek_id}</td>
                                              </React.Fragment>
                                            }
                                            {sync_type === 'abc' &&
                                              <React.Fragment>
                                                <td className="px-2 py-4 text-sm">{cls.abc_id}</td>
                                              </React.Fragment>
                                            }
                                            <td className="px-2 py-4  text-sm">{cls.name}</td>
                                            {sync_type === 'mbo' &&
                                              <td className="px-2 py-4 text-sm w-5/12 break-words">{cls.desc}</td>
                                            }
                                            {sync_type !== 'abc' &&
                                              <React.Fragment>
                                                {sync_type === 'marinatek' &&
                                                  <td className="px-2 py-4 text-center text-sm w-5/12 break-words">{cls.description}</td>
                                                }
                                                <td className="px-2 py-4 text-sm">{cls.active ? 'true' : 'false'}</td>
                                              </React.Fragment>
                                            }
                                          </tr>
                                        )}
                                      </tbody>
                                      
                                    </table>
                                  </div>
                                </React.Fragment>
                              }
                            </div>
                          }
                        </div>
											</div>
										</div>
									</div>
                  {!downloadModal &&
                    <div className="mt-5 sm:mt-4 flex flex-row-reverse gap-3 sm:gap-0">
                      {remote_classes.length > 0 &&
                        <React.Fragment>
                          {(sync_type === 'mbo' && user?.role_display === "Netgym Employee") &&
                            <button
                              type="button"
                              className="cursor-pointer w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-dark-blue sm:text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-blue sm:ml-3 sm:w-auto text-sm"
                              onClick={(e) => this.downloadRemote(e, sync_type)}
                            >
                              Download
                            </button>
                          }
                          <button
                            type="button"
                            className="cursor-pointer w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-dark-blue sm:text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-blue sm:ml-3 sm:w-auto text-sm"
                            onClick={(e) => this.processSync(e)}
                          >
                            {syncing &&
                              <React.Fragment>
                                <div className="flex items-center justify-center">
                                  <svg className="animate-spin -ml-1 mr-1.5 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                  </svg>
                                  <p className="text-center">{window.getSaveSyncSlogan()}</p>
                                </div>
                              </React.Fragment>
                            }
                            {!syncing &&
                              <React.Fragment>
                                Sync Data
                              </React.Fragment>
                            }
                          </button>
                        </React.Fragment>
                      }
                    </div>
                  }
								</div>
							</Transition.Child>
						</div>
					</Dialog>
				</Transition.Root>
        <Transition.Root show={deleteModal} as={Fragment}>
					<Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={(e) => this.closeModal(e)}>
						<div className="flex items-start justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0"
								enterTo="opacity-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100"
								leaveTo="opacity-0"
							>
								<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
							</Transition.Child>
		
							{/* This element is to trick the browser into centering the modal contents. */}
							<span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
								&#8203;
							</span>
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								enterTo="opacity-100 translate-y-0 sm:scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 translate-y-0 sm:scale-100"
								leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							>
								<div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full sm:p-6 mt-20">
									<div className="absolute top-0 right-0 pt-4 pr-4">
										<button
											type="button"
											className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
											onClick={(e) => this.closeModal(e)}
										>
											<span className="sr-only">Close</span>
											<XIcon className="h-6 w-6" aria-hidden="true" />
										</button>
									</div>
									<div className="sm:flex sm:items-start">
										<div className="mt-3 text-center sm:mt-0 sm:mx-4 sm:text-left w-full">
											<Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900 border-b pb-2">
                        Delete Class
											</Dialog.Title>
											<div className="mt-4">
                        <div className="mb-2">
                          <div className='flex gap-4 mb-2 items-center'>
                            <p className='text-gray-700 w-36 text-left'>Class Name</p>
                            <p className="text-sm text-gray-500">{deleteClass.name}</p>
                          </div>
                        </div>
											</div>
										</div>
									</div>
									<div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className="cursor-pointer w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-dark-blue text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-blue sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={(e) => this.processDelete(e)}
                    >
                      Delete Class
                    </button>
									</div>
								</div>
							</Transition.Child>
						</div>
					</Dialog>
				</Transition.Root>
        <Transition.Root show={classModal} as={Fragment}>
					<Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={(e) => this.closeModal(e)}>
						<div className="flex items-start justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0"
								enterTo="opacity-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100"
								leaveTo="opacity-0"
							>
								<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
							</Transition.Child>
		
							{/* This element is to trick the browser into centering the modal contents. */}
							<span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
								&#8203;
							</span>
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								enterTo="opacity-100 translate-y-0 sm:scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 translate-y-0 sm:scale-100"
								leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							>
								<div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-20 sm:align-middle sm:max-w-4xl sm:w-full sm:p-6 mt-20">
									<div className="absolute top-0 right-0 pt-4 pr-4">
										<button
											type="button"
											className="bg-white rounded-md text-gray-400 hover:text-gray-500"
											onClick={(e) => this.closeModal(e)}
										>
											<span className="sr-only">Close</span>
											<XIcon className="h-6 w-6" aria-hidden="true" />
										</button>
									</div>
									<div className="sm:flex sm:items-start">
										<div className="mt-3 text-center sm:mt-0 sm:mx-4 sm:text-left w-full">
											<Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900 border-b pb-2">
                        {classFormType == 'add' ? 'New Class Form' : 'Edit Class Form'}
											</Dialog.Title>
                      <div className="big-modal-scroll">
                        <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  py-4'>
                          <label htmlFor="location-name" className="block text-left text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">Class Name*</label>
                          <div className='mt-1 sm:mt-0 sm:col-span-1'>
                            <input type="text" className="lg:w-80 border rounded-md px-2 py-1.5 w-full shadow-sm text-sm h-10" defaultValue={formClass.name} onChange={(e) => this.handleChange(e)} name="name" placeholder="Enter Class Name"/>
                          </div>
                        </div>
                        <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start border-t border-gray-200 py-4'>
                          <label htmlFor="street-address" className="block text-left text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">Description</label>
                          <div className='mt-1 sm:mt-0 sm:col-span-1'>
                            <textarea className="lg:w-80 border rounded-md px-2 py-1.5 w-full shadow-sm text-sm" defaultValue={formClass.description} onChange={(e) => this.handleChange(e)} name="description" rows="4" cols="50" placeholder="Enter Description">
                            </textarea>
                          </div>
                        </div>
                        {!newSkill &&
                          <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start border-t border-gray-200 py-4'>
                            <label htmlFor="city" className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">
                              <div className='flex gap-3 items-center'>
                                {business.second_skill_enabled ? "Primary Skill*" : "Skill*"}
                                {(!business?.clubready_integration && !business?.fisikal_integration) &&
                                  <div className=''>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="light-blue h-4 w-4 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" data-tip data-for="newSkill" onClick={(e) => this.setNewSkill(e, true)}>
                                      <path fillRule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clipRule="evenodd" />
                                    </svg>
                                    <ReactTooltip id="newSkill" place="top" effect="solid">
                                      Add New Skill
                                    </ReactTooltip>
                                  </div>
                                }
                              </div>
                            </label>
                            <div className='mt-1 sm:mt-0 sm:col-span-1'>
                              <select value={formClass.business_category?.id} onChange={(e) => this.setSkill(e.target.value)} className={`form-select lg:w-80 mt-1 block w-full pl-2 pr-5 py-2 border border-gray-300  sm:text-sm rounded-md text-sm h-10 shadow-sm ${this.isDisabled() ? 'cursor-not-allowed bg-gray-100' : 'bg-white'}`} name="skill_id" disabled={this.isDisabled()}>
                                <option value="">Please select a Skill</option>
                                {skills.map((skill, i) => 
                                  <option key={i} value={skill.id}>{skill.name}</option>
                                )}
                              </select>
                            </div>
                          </div>
                        }
                        {newSkill &&
                          <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start border-t border-gray-200 py-4'>
                            <label htmlFor="city" className="block text-sm text-left font-medium text-gray-500 sm:mt-px sm:pt-2">
                              New Skill Name
                            </label>
                            <div className='mt-1 sm:mt-0 sm:col-span-2 flex gap-3'>
                              <input type="text" className="lg:w-80 border rounded-md px-2 py-1.5 w-full shadow-sm text-sm h-10" defaultValue="" onChange={(e) => this.handleNewSkillChange(e)} name="name" placeholder="Enter New Skill" />
                              <div className='flex gap-3 items-center'>
                                <div>
                                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 cursor-pointer light-blue" viewBox="0 0 20 20" fill="currentColor" data-tip data-for="saveNewSkill" onClick={(e) => this.saveNewSkill(e)}>
                                    <path d="M7.707 10.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V6h5a2 2 0 012 2v7a2 2 0 01-2 2H4a2 2 0 01-2-2V8a2 2 0 012-2h5v5.586l-1.293-1.293zM9 4a1 1 0 012 0v2H9V4z" />
                                  </svg>
                                  <ReactTooltip id="saveNewSkill" place="top" effect="solid">
                                    Save Skill
                                  </ReactTooltip>
                                </div>
                                <div>
                                  <svg xmlns="http://www.w3.org/2000/svg" className="cursor-pointer light-blue h-5 w-5" viewBox="0 0 20 20" fill="currentColor" data-tip data-for="deleteSkill" onClick={(e) => this.setNewSkill(e, false)}>
                                    <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
                                  </svg>
                                  <ReactTooltip id="deleteSkill" place="top" effect="solid">
                                    Cancel
                                  </ReactTooltip>
                                </div>
                              </div>
                            </div>
                          </div>
                        }
                        {business.second_skill_enabled &&
                          <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start border-t border-gray-200 py-4'>
                            <label htmlFor="city" className="block text-left text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">
                              Secondary Skill (Optional)
                            </label>
                            <div className='mt-1 sm:mt-0 sm:col-span-1'>
                              <select value={formClass.second_skill === null ? "" : formClass.second_skill.id} onChange={(e) => this.setSecondSkill(e)} className='form-select lg:w-80 bg-white mt-1 block w-full pl-2 pr-5 py-2  border border-gray-300  text-sm rounded-md' name="skill_id">
                                <option value="">Select a Second Skill</option>
                                {skills.map((skill, i) => 
                                  <option key={i} value={skill.id}>{skill.name}</option>
                                )}
                              </select>
                            </div>
                          </div>
                        }
                        {business.abc_integration &&
                          <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start py-4 border-t'>
                            <label htmlFor="location-name" className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">ABC ID*</label>
                            <div className='mt-1 sm:mt-0 sm:col-span-1'>
                              <input type="text" className="lg:w-80 border rounded-md px-2 py-1.5 w-full shadow-sm text-sm h-10" defaultValue={formClass.external_id} onChange={(e) => this.handleChange(e)} name="external_id" placeholder="Enter ABC ID"/>
                            </div>
                          </div>
                        }
                        {business.marianatek_integration &&
                          <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start border-t py-4'>
                            <label htmlFor="location-name" className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">Marianatek ID*</label>
                            <div className='mt-1 sm:mt-0 sm:col-span-1'>
                              <input type="text" className="lg:w-80 border rounded-md px-2 py-1.5 w-full shadow-sm text-sm h-10" defaultValue={formClass.external_id} onChange={(e) => this.handleChange(e)} name="external_id" placeholder="Enter Marianatek ID"/>
                            </div>
                          </div>
                        }
                        {business.mbo_integration &&
                          <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start border-t py-4'>
                            <label htmlFor="location-name" className="block text-sm text-left font-medium text-gray-500 sm:mt-px sm:pt-2">Mindbody ID*</label>
                            <div className='mt-1 sm:mt-0 sm:col-span-1'>
                              <input className="lg:w-80 border rounded-md px-2 py-1.5 w-full shadow-sm text-sm h-10" defaultValue={formClass.mindbody_id} onChange={(e) => this.handleChange(e)} name="mindbody_id" type="text" placeholder="Enter Mindbody ID"/>
                            </div>
                          </div>
                        }
                        {(business.mbo_integration && !user?.assigned_single_site_id) &&
                          <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start border-t border-gray-200 py-4'>
                            <label htmlFor="club-type" className="block text-sm text-left font-medium text-gray-500 sm:mt-px sm:pt-2">MBO Site*</label>
                            <div className='mt-1 sm:mt-0 sm:col-span-2'>
                              <select className='form-select lg:w-80 bg-white mt-1 block w-full pl-2 pr-10 py-2 border border-gray-300 text-sm rounded-md' defaultValue={formClass.mbo_site_id} onChange={(e) => this.setMboSiteIDSkill(e)} name="mbo_site_id">
                                <option value="">Select MBO Site</option>
                                {user?.assigned_mbo_sites?.length > 0 &&
                                  <React.Fragment>
                                    {user?.assigned_mbo_sites.map((detail, i) => 
                                      <option key={i} value={detail.site_id}>{detail.site_name}</option>
                                    )}
                                  </React.Fragment>
                                }
                              </select>
                            </div>
                          </div>
                        }
                        {business.crunch_integration &&
                          <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start border-t py-4'>
                            <label htmlFor="location-name" className="block text-sm  text-left font-medium text-gray-500 sm:mt-px sm:pt-2">Crunch ID*</label>
                            <div className='mt-1 sm:mt-0 sm:col-span-1'>
                              <input type="text" className="lg:w-80 border rounded-md px-2 py-1.5 w-full shadow-sm numeric" defaultValue={formClass.external_id} onChange={(e) => this.handleChange(e)} name="external_id" placeholder="Enter Crunch ID"/>
                            </div>
                          </div>
                        }
                        {business?.fisikal_integration &&
                          <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start border-t py-4'>
                            <label htmlFor="fisikal-name" className="block text-sm  text-left font-medium text-gray-500 sm:mt-px sm:pt-2">Fisikal ID*</label>
                            <div className='mt-1 sm:mt-0 sm:col-span-1'>
                              <input type="text" className={`lg:w-80 border rounded-md px-2 py-1.5 w-full text-sm h-10 shadow-sm numeric ${this.isDisabledIntegratedIdField() ? 'cursor-not-allowed' : ''}`} defaultValue={formClass.external_id} onChange={(e) => this.handleChange(e)} name="external_id" placeholder="Enter Fisikal ID" disabled={this.isDisabledIntegratedIdField()}/>
                              </div>
                          </div>
                        }
                        {business.clubready_integration &&
                          <div>
                            <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start border-t py-4'>
                              <label htmlFor="location-name" className="block text-sm  text-left font-medium text-gray-500 sm:mt-px sm:pt-2">Clubready ID*</label>
                              <div className='mt-1 sm:mt-0 sm:col-span-1'>
                                <input type="text" className={`lg:w-80 border rounded-md px-2 py-1.5 w-full shadow-sm numeric text-sm h-10 ${this.isDisabledIntegratedIdField() ? 'cursor-not-allowed' : ''}`} defaultValue={formClass.external_id} onChange={(e) => this.handleChange(e)} name="external_id" placeholder="Enter Clubready ID" disabled={this.isDisabledIntegratedIdField()}/>
                              </div>
                            </div>
                          </div>
                        }
                        <div className='flex mt-4 gap-3 justify-end'>
                          <a className='bg-white hover:bg-gray-100 sm:text-base text-sm items-center rounded-md text-gray-700 font-medium border border-gray-200 px-6 py-3 cursor-pointer' onClick={(e) => this.closeModal(e)}>Cancel</a>
                          {classFormType == 'add' &&
                            <button className={`flex sm:text-base items-center text-sm font-medium rounded-md text-white bg-dark-blue px-6 py-3 gap-2 ${(formClass?.name === "" || formClass?.business_category?.id === "" || (business.abc_integration && formClass?.external_id === "") || (business.marianatek_integration && formClass?.external_id === "") || (business?.mbo_integration && formClass?.mindbody_id === "" && user?.assigned_single_site_id) || (business?.mbo_integration && formClass?.mindbody_id === "" && !user?.assigned_single_site_id) || (business?.mbo_integration && !user?.assigned_single_site_id && formClass?.mbo_site_id === "") || (business.crunch_integration && formClass?.external_id === "" ) || (business?.fisikal_integration && formClass?.external_id === "") || (business.clubready_integration && (formClass?.external_id === ""))) ? 'btn-disabled' : ''}`} onClick={(e) => this.addClass(e)} disabled={formClass?.name === "" || formClass?.business_category?.id === "" || (business.abc_integration && formClass?.external_id === "") || (business.marianatek_integration && formClass?.external_id === "") || (business?.mbo_integration && formClass?.mindbody_id === "" && user?.assigned_single_site_id) || (business?.mbo_integration && formClass?.mindbody_id === "" && !user?.assigned_single_site_id) || (business?.mbo_integration && !user?.assigned_single_site_id && formClass?.mbo_site_id === "") || (business.crunch_integration && formClass?.external_id === "") || (business?.fisikal_integration && formClass?.external_id === "") || (business.clubready_integration && (formClass?.external_id === ""))}>Add New Class</button>
                          }
                          {classFormType == 'edit' &&
                            <button className={`flex sm:text-base items-center text-sm hover:bg-gray-800 font-medium rounded-md text-white bg-dark-blue px-6 py-3 gap-2 ${(formClass?.name === "" || formClass?.business_category?.id === "" || (business.abc_integration && formClass?.external_id === "") || (business.marianatek_integration && formClass?.external_id === "") || (business?.mbo_integration && formClass?.mindbody_id === "" && user?.assigned_single_site_id) || (business?.mbo_integration && formClass?.mindbody_id === "" && !user?.assigned_single_site_id) || (business?.mbo_integration && !user?.assigned_single_site_id && formClass?.mbo_site_id === "") || (business.crunch_integration && formClass?.external_id === "") || (business?.fisikal_integration && formClass?.external_id === "") || (business.clubready_integration && (formClass?.external_id === ""))) ? 'btn-disabled' : ''}`} onClick={(e) => this.editClass(e)} disabled={formClass?.name === "" || formClass?.business_category?.id === "" || (business.abc_integration && formClass?.external_id === "") || (business.marianatek_integration && formClass?.external_id === "") || (business?.mbo_integration && formClass?.mindbody_id === "" && user?.assigned_single_site_id) || (business?.mbo_integration && formClass?.mindbody_id === "" && !user?.assigned_single_site_id) || (business?.mbo_integration && !user?.assigned_single_site_idd && formClass?.mbo_site_id === "") || (business.crunch_integration && formClass?.external_id === "") || (business?.fisikal_integration && formClass?.external_id === "") || (business.clubready_integration && (formClass?.external_id === ""))}>Update Class</button>
                          }
                        </div>
                      </div>
										</div>
									</div>
								</div>
							</Transition.Child>
						</div>
					</Dialog>
				</Transition.Root>
      </React.Fragment>
    )
  }
}
