import React, { Fragment } from "react";
import UserIcon from '../../../../../subRequestCreate/assets/userIcon';
import ReactTooltip from "react-tooltip";

export default function AssignMassSubComponent({setShowAssignPopup, dueToInvalidEvents, dueToNotEligibleLocation}) {
  return (
    <Fragment>
      <button className={`bg-dark-blue text-white py-1.5 px-4 rounded-full gap-2 text-sm flex items-center border ${(dueToInvalidEvents || dueToNotEligibleLocation) ? 'cursor-not-allowed border-gray-400' : 'cursor-pointer hover:bg-gray-800 border-white'}`} onClick={() => setShowAssignPopup(true)} disabled={(dueToInvalidEvents || dueToNotEligibleLocation)} data-tip data-for={`mass_assign_action_button`}>
        <UserIcon classNames={`w-4 h-4 ${(dueToInvalidEvents || dueToNotEligibleLocation) ? 'opacity-50' : ''}`} customColour={'white'}/>
        <p className={`text-white text-sm ${(dueToInvalidEvents || dueToNotEligibleLocation) ? 'opacity-50' : ''}`}>Assign Sub</p>
        {(dueToInvalidEvents || dueToNotEligibleLocation) &&
          <ReactTooltip className='max-w-xs' id={`mass_assign_action_button`} place="top" effect="solid">
            {dueToInvalidEvents ? 
              'No common action(s) for requests selected'
            :
              (dueToNotEligibleLocation && 'You can only bulk assign subs at one location at a time.')
            }
          </ReactTooltip>
        }
      </button>
    </Fragment>
  )
}