import React, { Fragment, useState } from "react";
import { ApproveButtonIcon, DeclineButtonIcon } from './../../../../assets';
import { performMGRAction } from './../../../../helpers/helpers';
import ReactTooltip from "react-tooltip";

export default function MgrButtonsComponent(props) {
  const { user, event, handleSetAlert, handleCloseAndReloadData, defaultDisabled = false, handleDeleteEvent } = props
  const [ disabledButtons, setDisabledButtons ] = useState(false)

  const handlePerformAction = (action) => {
    setDisabledButtons(true)
    let url = `/api/v3/business_events/${event?.id}/manager_response`
    let params = {
      fm_id: user?.id, 
      status: action,
      preview: true
    }
    performMGRAction(url, params).then((result) => {
      if(Object.keys(result).includes('status') && result?.status === 200){
        setDisabledButtons(false)
        handleSetAlert(true, 'Status Updated', 'success')
        if(Object.keys(result?.event).length > 0) handleCloseAndReloadData(result?.event)
        if(!action && Object.keys(result?.event).length == 0) handleDeleteEvent(event)
      }else{
        setDisabledButtons(false)
        handleSetAlert(true, result?.error, 'danger')
      }
      setTimeout((
        handleSetAlert(false, '', '')
      ), 3000)
    })
  }

  return(
    <Fragment>
      <button className={`flex relative z-20 justify-center items-center border border-green-600 rounded-full min-h-8 min-w-8 ${(disabledButtons || defaultDisabled) ? 'opacity-50 cursor-not-allowed' : ''}`} onClick={() => handlePerformAction(true)} data-tip data-for={`approve_button_`+event?.id.toString()} disabled={disabledButtons || defaultDisabled}>
        <ApproveButtonIcon classNames={'text-green-600 wc-14 hc-14'}/>
        <ReactTooltip className='max-w-xs whitespace-nowrap' id={`approve_button_`+event?.id.toString()} place="top" effect="solid">
          Approve
        </ReactTooltip>
      </button>
      <button className={`flex relative z-20 justify-center items-center border border-red-600 text-red-600 rounded-full min-h-8 min-w-8 ${(disabledButtons || defaultDisabled) ? 'opacity-50 cursor-not-allowed' : ''}`} onClick={() => handlePerformAction(false)} data-tip data-for={`decline_button_`+event?.id.toString()} disabled={disabledButtons || defaultDisabled}>
        <DeclineButtonIcon classNames={'text-red-600 wc-14 hc-14'}/>
        <ReactTooltip className='max-w-xs whitespace-nowrap' id={`decline_button_`+event?.id.toString()} place="top" effect="solid">
          Decline
        </ReactTooltip>
      </button>
    </Fragment>
  )
}