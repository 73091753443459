import React from 'react'
import Header from './../../availability/header'
import Sidebar from './../../availability/sidebar'
import Footer from './../../availability/footer'
import Messenger from './../index';
import CreatePostForm from './../pages/createPostForm';
import ShowPost from './../pages/showPost';
import UserContext from '../../userComponents/userContext';
import { getSudomain, fetchAccounts, } from '../../helpers';
import Cookies from 'js-cookie';

const navigation = [
  { name: 'Sub Board', href: '/my-requests', icon: "/assets/icon_sb_sub_board.png", current: false, alt_name: 'Sub Board', old_prop: false, drop_down: true },
  { name: 'Attendance', href: '#', icon: "/assets/icon_sb_attandance.png", current: false, alt_name: 'Attendance', old_prop: false, drop_down: false },
  { name: 'My Profile', href: '/my-profile', icon: "/assets/icon_sb_my_profile.png", current: false, alt_name: 'My Profile', old_prop: false, drop_down: false },
  { name: 'Resource Hub', href: '/resource_hub', icon: "/assets/icon_sb_resource.png", current: false, alt_name: 'Resource Hub', old_prop: false, drop_down: false },
  { name: 'Documentation', href: '/documentation', icon: "/assets/documentations-icon-new.svg", current: false, alt_name: 'Documentation', old_prop: false, drop_down: false },
  { name: 'Availability', href: '/availability', icon: "/assets/icon_sb_availability.png", current: false, alt_name: 'Availability', old_prop: false, drop_down: false },
  { name: 'Directory', href: '/directory', icon: "/assets/icon_sb_directory.png", current: false, alt_name: 'Directory', old_prop: false, drop_down: false },
  { name: 'Sub Schedule', href: '#', icon: "/assets/icon_sb_calendar.png", current: false, alt_name: 'Sub Schedule', old_prop: false, drop_down: false },
  { name: 'Insights', href: '#', icon: "/assets/icon_sb_insights.png", current: false, alt_name: 'Insights', old_prop: false, drop_down: false },
  { name: 'Messages', href: '/messenger', icon: "/assets/icon_sb_message.png", current: false, alt_name: 'Messages', old_prop: false, drop_down: false },
  { name: 'Settings', href: '/settings-v4', icon: "/assets/icon_sb_settings.png", current: false, alt_name: 'Settings', old_prop: false, drop_down: false },
  { name: 'Configure', href: '/configure', icon: "/assets/icon_sb_configure.png", current: false, alt_name: 'Configure', old_prop: false, drop_down: false },
  { name: 'Refer & Earn', href: '#', icon: "/assets/icon_sb_refer.png", current: false, alt_name: 'Refer & Earn', old_prop: true, drop_down: false },
  { name: 'Notification Logs', href: '/notification-audits', icon: "/assets/notification_log_icon.png", current: false, alt_name: 'Notification Logs', old_prop: false, drop_down: false },
]


export default class TailwindMessengerLayout extends React.Component {
  static contextType = UserContext;
  constructor(props){
    super(props);
    this.state = {
      sidebarOpen: false,
      insightsOpen: false,
      navigation: navigation,
      user: this.props.user,
      business: this.props.business,
      accounts: this.props.accounts,
      firstTime: true
    }
  }

  componentDidMount = () => {
    if(Object.keys(this.props.user).length !== 0){
      this.context.updateUserData(this.props.user)
    }
    if(Object.keys(this.context.user).length !== 0){
      this.updateNavigation()
    }
    if(this.props.pageLoaded){
      if(this.state.firstTime){
        this.fetch()
        this.setState({
          firstTime: false
        })
      }
    }
  }

  fetch = () => {
    if(!this.context.fetchedAccounts){
      fetchAccounts(Cookies.get(`currentUser.${getSudomain()}`), getSudomain())
      .then(response => response.json())
      .then(result => {
        if(result.status == 200){
          this.context.setFetchedAccounts(true)
          this.context.setAccounts(result.business_users)
          this.setState({
            accounts: result.business_users
          })
        }
      })
    }else{
      this.setState({
        accounts: this.context.accounts
      })
    }
  }

  updateNavigation = () => {
    let { navigation } = this.state
    let { user } = this.context
    let attendance = navigation.findIndex((obj => obj.name === 'Attendance'));
    navigation[attendance].count = user?.unread_attendance_count

    let msg = navigation.findIndex((obj => obj.name === 'Messages'));
    navigation[msg].count = user?.unread_count

    this.setState({
      navigation: navigation
    })
  }

  getName(role){
    let name = ""
    switch(role){
      case 'business_admin':
      case 'account_owner':
        name = "admin"
        break;
      case 'regional_admin':
        name = 'regional_admin'
        break;
      case 'fitness_manager':
        name = 'fitness_manager'
        break;
      case 'instructor':
        name = 'instructor'
        break;
      case 'ops':
        name = 'ops'
        break;
    }
    return name
  }

  snakeCase = (string) => {
    return string?.replace(/\d+/g, ' ')
      .split(/ |\B(?=[A-Z])/)
      .map((word) => word.toLowerCase())
      .join('_');
  }

  setSidebarOpen = () => {
    this.setState({
      sidebarOpen: !this.state.sidebarOpen
    })
  }

  setInsightsOpen = () => {
    this.setState({
      insightsOpen: !this.state.insightsOpen
    })
  }

  setActive = (name) => {
    this.setState(prevState => ({
      navigation: prevState.navigation.map(
        obj => (obj.alt_name === name ? Object.assign(obj, { current: true }) : Object.assign(obj, { current: false }))
      )
    }));
  }

  setCurrent = (name, shallHide) => {
    if(shallHide){
      this.setState(prevState => ({
        navigation: prevState.navigation.map(
          obj => (obj.alt_name === 'Availability' ? Object.assign(obj, { current: false }) : obj)
        )
      }));
    }    
    this.setState(prevState => ({
      navigation: prevState.navigation.map(
        obj => (obj.alt_name === name ? Object.assign(obj, { current: true }) : obj)
      )
    }));
  }

  renderSwitch(){  
    const curent = this  
    switch(this.props.path){
      case 'messenger':
        return <Messenger user = {curent.context.user} permissions={curent.props.permissions} business = {curent.props.business} loadingMessage={curent.props.loadingMessage} setCurrent={this.setActive} path = {this.props.path} pageLoaded={curent.props.pageLoaded}/>
      case 'inbox':
        return <Messenger user = {curent.context.user} permissions={curent.props.permissions} business = {curent.props.business} loadingMessage={curent.props.loadingMessage} setCurrent={this.setActive} path = {this.props.path} pageLoaded={curent.props.pageLoaded}/>
      case 'create-post':
        return <CreatePostForm user = {curent.context.user} permissions={curent.props.permissions} business = {curent.props.business} loadingMessage={curent.props.loadingMessage} setCurrent={this.setActive} pageLoaded={curent.props.pageLoaded}/>
      case 'show-post':
        return <ShowPost user = {curent.context.user} permissions={curent.props.permissions} business = {curent.props.business} loadingMessage={curent.props.loadingMessage} setCurrent={this.setActive} path = {this.props.path} pageLoaded={curent.props.pageLoaded}/>
      case 'compliance':
        return <Messenger user = {curent.context.user} permissions={curent.props.permissions} business = {curent.props.business} loadingMessage={curent.props.loadingMessage} setCurrent={this.setActive} path = {this.props.path} pageLoaded={curent.props.pageLoaded}/>
      case 'announcement':
        return <Messenger user = {curent.context.user} permissions={curent.props.permissions} business = {curent.props.business} loadingMessage={curent.props.loadingMessage} setCurrent={this.setActive} path = {this.props.path} pageLoaded={curent.props.pageLoaded}/>
      case 'event':
        return <Messenger user = {curent.context.user} permissions={curent.props.permissions} business = {curent.props.business} loadingMessage={curent.props.loadingMessage} setCurrent={this.setActive} path = {this.props.path} pageLoaded={curent.props.pageLoaded}/>
      case 'archive':
        return <Messenger user = {curent.context.user} permissions={curent.props.permissions} business = {curent.props.business} loadingMessage={curent.props.loadingMessage} setCurrent={this.setActive} path = {this.props.path} pageLoaded={curent.props.pageLoaded}/>
      case 'chat-room':
        return <Messenger user = {curent.context.user} permissions={curent.props.permissions} business = {curent.props.business} loadingMessage={curent.props.loadingMessage} setCurrent={this.setActive} path = {this.props.path} pageLoaded={curent.props.pageLoaded}/>  
      case 'permanent_class_opening':
        return <Messenger user = {curent.context.user} permissions={curent.props.permissions} business = {curent.props.business} loadingMessage={curent.props.loadingMessage} setCurrent={this.setActive} path = {this.props.path} pageLoaded={curent.props.pageLoaded}/>
    }
  }
  
  render() {
    const { user, business, accounts } = this.state
    return (
      <React.Fragment>
        <div className="h-screen flex flex-col justify-between">
          <Header sidebarOpen = {this.state.sidebarOpen} setSidebarOpen={this.setSidebarOpen} user={this.context.user} permissions = {this.props.permissions} business={this.props.business} pageLoaded={this.props.pageLoaded} accounts={accounts}/>

          <div className={`flex overflow-hidden bg-gray-100 h-full  ${location.pathname.split('/')[1] === 'availability' && location.pathname.split('/')[2] === 'search' ? 'layout-height' : ''}`}>
            <Sidebar insightsOpen={this.state.insightsOpen} setInsightsOpen={this.setInsightsOpen} sidebarOpen = {this.state.sidebarOpen} setSidebarOpen={this.setSidebarOpen} user={this.context.user} permissions = {this.props.permissions} business={this.props.business} navigation={this.state.navigation} setCurrent={this.setCurrent} accounts={accounts} />
            {this.renderSwitch()}
          </div>
        <Footer />
        </div>
        
      </React.Fragment>
    )
  }
}