import React, { Fragment, useState } from "react";
import { BackButtonIcon } from './../../../subRequestDetails/assets';
import { Redirect } from 'react-router-dom';
import { setUrlByPageNo } from '../../helpers';

export default function TopButtonComponent(props) {
  const { user, step, setStep, newSubRequest, actionType } = props
  const [ redirect, setRedirect ] = useState(false)
  const [ backPath, setBackPath ] = useState('/my-requests')
  
  const goBack = () => {
    if (actionType === 'exit') {
      setRedirect(true)
      return
    }

    if(step == 1){
      if(backPath !== '' && !backPath.includes('/business_user')){
        setRedirect(true)
      }else{
        window.location.href = backPath
      }
    }else if(step == 2){
      setStep(1)
      setUrlByPageNo(1)
    }else if(step == 3){
      if(newSubRequest?.approvedUser?.id !== ''){
        setStep(1)
        setUrlByPageNo(1)
      }else{
        setStep(2)
        setUrlByPageNo(2)
      }
    }
  }

  return (
    <Fragment>
      {redirect &&
        <Redirect to={`${backPath}`} />
      }
      <div className={`flex justify-start ${actionType === 'back' ? 'mb-0' : 'mb-5'}`}>
        <button className={`flex justify-center items-center rounded-2xl px-4 py-4 h-11 bg-white text-sm text-gray-900 border-2 border-gray-300 font-semibold gap-2 ${actionType === 'back' ? 'w-48' : ''}`} onClick={() => goBack()}>
          <BackButtonIcon classNames={'w-4 h-4'}/>
          {actionType === 'back' ? 
            `Step ${step - 1}`
          :
            'Exit'
          }
        </button>
      </div>
    </Fragment>
  );
}