import React, { Fragment } from "react";
import ClassesComponent from './classesComponent';
import ConfirmInstructorsTableComponent from './confirmInstructorsTableComponent';
import ConfirmSubRequestButton from './confirmSubRequestButton';
import ApprovedUserDetailsComponent from './approvedUserDetailsComponent';
import UrgentClassTextMessageComponent from './urgentClassTextMessageComponent';
import TopButtonComponent from '../topButtonComponent';

export default function FinalStepComponents(props) {
  const { newSubRequest, user, setStep, business, step } = props;

  return(
    <Fragment>
      <ClassesComponent
        newSubRequest={newSubRequest}
      />
      {newSubRequest?.approvedUser?.id == '' ?
        <ConfirmInstructorsTableComponent
          newSubRequest={newSubRequest}
          business={business}
        />
      :
        <ApprovedUserDetailsComponent newSubRequest={newSubRequest} />
      }
      {!newSubRequest?.previousWeek &&
        <UrgentClassTextMessageComponent 
          newSubRequest={newSubRequest}
          business={business}
          user={user}
        />
      }
      <div className="flex items-center justify-center gap-3 flex-wrap">
        <TopButtonComponent
          user={user}
          step={step}
          setStep={setStep}
          newSubRequest={newSubRequest}
          actionType={'back'}
        />
        <ConfirmSubRequestButton 
          newSubRequest={newSubRequest}
          user={user}
          setStep={setStep}
          business={business}
        />
      </div>
    </Fragment>
  )
}