import React, { Fragment } from "react";
import DeclineButtonIcon from '../../../../assets/declineButtonIcon';
import ReactTooltip from "react-tooltip";

export default function CancelMassSubComponent({setShowCancelActionsPopup, dueToInvalidEvents}) {
  return (
    <Fragment>
      <button className={`bg-dark-blue text-white py-1.5 px-4 rounded-full gap-2 text-sm flex items-center border ${(dueToInvalidEvents) ? 'cursor-not-allowed border-gray-400' : 'cursor-pointer hover:bg-gray-800 border-white'}`} onClick={() => setShowCancelActionsPopup(true)} disabled={dueToInvalidEvents} data-tip data-for={`mass_cancel_action_button`}>
        <DeclineButtonIcon classNames={`w-5 h-5 text-white ${(dueToInvalidEvents) ? 'opacity-50' : ''}`}/>
        <p className={`text-white text-sm ${(dueToInvalidEvents) ? 'opacity-50' : ''}`}>{`Cancel Sub Request(s)`}</p>
        {(dueToInvalidEvents) &&
          <ReactTooltip className='max-w-xs' id={`mass_cancel_action_button`} place="top" effect="solid">
            {dueToInvalidEvents && 'No common action(s) for requests selected'}
          </ReactTooltip>
        }
      </button>
    </Fragment>
  )
}