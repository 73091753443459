import React, { Fragment, useState, useEffect } from "react";
import DocumentationsTable from '../instructorDocumentations/documentationsTable';
import AlertPopup from '../../../messenger/pages/chatComponents/assets/alertPopup';
import { LoadingSpinner } from '../../helpers';
import { axiosGetRequest } from '../../../subRequests/helpers/helpers';
import { Redirect } from "react-router-dom";

export default function MyDocumentations(props) {
  const { user, permissions } = props
  const [ documentations, setDocumentations ] = useState([])
  const [ dataLoaded, setDataLoaded ] = useState(false)
  const [ showAlert, setShowAlert ] = useState(false)
  const [ alertMessage, setAlertMessage ] = useState('')
  const [ alertType, setAlertType ] = useState('')
  const pageSize = 30
  const [ page, setPage ] = useState(1)
  const [ totalCount, setTotalCount ] = useState(1)
  const [ sortType, setSortType ] = useState('category_name')
  const [ sortReverse, setSortReverse ] = useState(false)
  const [ loaded, setLoaded ] = useState(true)
  const [ filterDataLoaded, setFilterDataLoaded ] = useState(false)
  const [ redirect, setRedirect ] = useState(false)
  const [ redirectUrl, setRedirectUrl ] = useState('')

  useEffect(() => {
    fetchDocumentations(sortType, sortReverse, page)
  }, []);

  const closeAlert = (data) => {
    setShowAlert(data)
    setAlertType('')
    setAlertMessage('')
  }

  const setPageNumber = (page) => {
    setPage(page)
    setFilterDataLoaded(false)
    fetchDocumentations(sortType, sortReverse, page)
  }

  const setSort = (e, name, order) => {
    setSortType(name)
    setSortReverse(order)
    setFilterDataLoaded(false)
    fetchDocumentations(name, order, page)
  }

  const fetchDocumentations = (sortType, sortReverse, page) => {
    let url = `/api/v3/documentations?my_documentations=${true}&instructor_ids=&category_ids=&statuses=&sort_type=${sortType}&sort_reverse=${sortReverse}&page=${page}&page_size=${pageSize}`
    axiosGetRequest(url).then(result => {
      if(result.status === 200){
        setDocumentations(result?.results)
        setTotalCount(result?.total_count)
        setDataLoaded(true)
        setFilterDataLoaded(true)
      }
    })
  }

  const handleEditDocumentation = (data, type) => {
    setRedirectUrl(`${location?.pathname}/submit-documentation/${data}?page-type=${type || 'view'}`)
    setRedirect(true)
  }

  const handleSetAlert = (show, message, type) => {
    setAlertMessage(message)
    setAlertType(type)
    setShowAlert(show)
  }

  return (
    <Fragment>
      {redirect &&
        <Redirect push to={`${redirectUrl}`} />
      }
      {showAlert &&
        <AlertPopup alert_type={alertType} alert_message={alertMessage} closeAlert={closeAlert} />
      }
      {!loaded &&
        <LoadingSpinner />
      }
      {loaded &&
        <div className="relative">
          <DocumentationsTable 
            user={user}
            permissions={permissions}
            documentations={documentations}
            dataLoaded={dataLoaded}
            page={page}
            totalCount={totalCount}
            pageSize={pageSize}
            setPageNumber={setPageNumber}
            sortType={sortType}
            sortReverse={sortReverse}
            setSort={setSort}
            filterDataLoaded={filterDataLoaded}
            pageType={'my-documentation'}
            handleEditDocumentation={handleEditDocumentation}
            handleSetAlert={handleSetAlert}
          />
        </div>
      }
    </Fragment>
  )
}