import React, { Fragment, useEffect, useState } from "react";
import AssignPopupComponent from '../../../assignPopupComponent';
import EmptyEventsComponent from './emptyEventsComponent';
import { cancelConfirmationText } from '../../../../../helpers/supportHelpers';
import { axiosRequest } from '../../../../../helpers/helpers';

export default function CancelMassActionPopupComponent(props) {
  const { showCancelOptionsPopup, closeCancelOptionsPopup, handleSetAlert, handleUpdateEvents, eventIds } = props
  const cancelActionOptions = [
    {key: "delete_without_sync", value: "Schedule updated", reason: "Schedule Updated"},
    {key: "cancel_sub_request", value: "Can't find sub", reason: ""}
  ]
  const [ selectedOption, setSelectedOption ] = useState('')
  const [ disableConfirmBtn, setDisableConfirmBtn ] = useState(false)
  const [ allDisabled, setAllDisabled ] = useState(eventIds?.length == 0)
  const [ actionText, setActionText ] = useState('')
  const [ confirmationText, setConfirmationText ] = useState('')
  const [ permanentlyCancel, setPermanentlyCancel ] = useState(false);

  useEffect(() => {
    setAllDisabled(eventIds?.length == 0)
  }, [eventIds])

  useEffect(() => {
    const [text, permanentConfirmationText] = cancelConfirmationText(eventIds, selectedOption, false);
    setActionText(text);
    setConfirmationText(permanentConfirmationText);
  }, [selectedOption, eventIds]);  

  const handleOptionSelection = (e) => {
    if(disableConfirmBtn){
      return
    }
    setPermanentlyCancel(false);
    if(selectedOption === e.target.value) {
      setSelectedOption('');
    }else{
      setSelectedOption(e.target.value);
    }
  }

  const handleClosePopup = (data) => {
    if(!disableConfirmBtn){
      closeCancelOptionsPopup(data)
    }
  }

  const handleConfirm = () => {    
    if(selectedOption == '' || eventIds?.length == 0){
      return
    }
    let url = `/api/v3/business_events/perform_more_actions`
    setDisableConfirmBtn(true)
    let params = {
      key: selectedOption,
      action_type: 'mass_cancel',
      event_ids: eventIds,
    }
    if(selectedOption == 'delete_without_sync'){
      params = {...params, reason: "Schedule Updated"}
    }
    axiosRequest(url, 'PATCH', params, 'data').then((result) => {
      if(result?.status === 200){
        handleSetAlert(true, result?.success, 'success');
        setTimeout(() => {
          closeCancelOptionsPopup(false);
          handleUpdateEvents();
          handleSetAlert(false, '', ''); 
        }, 3000)
      }else{
        if(!result?.error?.toLowerCase()?.includes('check your email to view the details')){
          setDisableConfirmBtn(false);
        }
        handleSetAlert(true, result?.error, 'danger');
        setTimeout(() => {
          if(result?.error?.toLowerCase()?.includes('check your email to view the details')){
            handleUpdateEvents();
            closeCancelOptionsPopup(false);
          }
          handleSetAlert(false, '', ''); 
        }, 3000)
      }
    })
  }

  return(
    <Fragment>
      <AssignPopupComponent show={showCancelOptionsPopup} close={handleClosePopup} title={""}>
        <div className="px-5 pt-3 font-open-sans">
          <div className="text-center font-bold text-xl mb-4 font-open-sans">
            Select Reason for Canceling
          </div>
          {eventIds?.length == 0 &&
            <EmptyEventsComponent />
          }
          {eventIds?.length > 0 &&
            <Fragment>
              {cancelActionOptions?.map((action, i) => (
                <div
                  key={i}
                  className="flex items-center gap-2 mb-3"
                >
                  <div className="assign-checkbox flex items-center">
                    <input
                      id={action?.key+'_'+i}
                      type="checkbox"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 cursor-pointer"
                      value={action?.key}
                      checked={selectedOption == action?.key}
                      onChange={(e) => handleOptionSelection(e)}
                      disabled={allDisabled}
                    />
                  </div>
                  <label className="flex items-center gap-2 cursor-pointer" htmlFor={action?.key+'_'+i}>
                    <div className="text-sm">{action?.value}</div>
                  </label>
                </div>
              ))}
              {selectedOption !== '' &&
                <Fragment>
                  <div className="text-sm font-medium my-3.5 text-left">
                    {actionText}
                  </div>
                  <div>
                    <label htmlFor="permanently_delete_cancel" className="flex text-sm cursor-pointer gap-2 text-left">
                      <input
                        id={`permanently_delete_cancel`}
                        type="checkbox"
                        className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mt-0.5 cursor-pointer"
                        onChange={() => {setPermanentlyCancel(!permanentlyCancel)}}
                        checked={permanentlyCancel}
                      />
                      {confirmationText}
                    </label>
                  </div>
                </Fragment>
              }
              <div className="mt-4">
                <button 
                  className={`bg-gray-900 rounded-xl text-white flex items-center justify-center w-full py-2 text-sm h-11 font-semibold ${(selectedOption == '' || !permanentlyCancel || disableConfirmBtn) ? 'opacity-50 cursor-not-allowed' : ''}`}
                  onClick={() => handleConfirm()}
                  disabled={selectedOption == '' || allDisabled || !permanentlyCancel || disableConfirmBtn}
                >
                  Apply
                </button>
              </div>
            </Fragment>
          }
        </div>
      </AssignPopupComponent>
    </Fragment>
  )
}