import React, { Fragment } from "react";

export default function SearchComponent (props){
  const { searchHandler, searchValue } = props

  return(
    <Fragment>
      <div className="w-60 relative rounded-md mb-3 md:mb-0">
        <div className='w-full bg-white border border-gray-300 shadow-sm rounded-md h-10 flex items-center pr-1.5'>
          <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-400 h-4 w-4 ml-3.5" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
          </svg>
          <input type="text" className="text-sm w-full border-0 h-10 ml-1.5 focus:outline-none bg-transparent users-search-class" placeholder="Search" value={searchValue} onChange = {(e) => searchHandler(e.target.value)}/>
        </div>
      </div>
    </Fragment>
  )

}