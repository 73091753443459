import React, { Fragment } from "react";
import StatusFilterComponent from '../statusFilterComponent/statusFilterComponent';
import LocationFilterComponent from './../locationFilterComponent/locationFilterComponent';
import CalenderViewFilter from './../calenderViewFilter/calenderViewFilter'
import MonthSelectComponent from './../monthSelectComponent'
import CalendarViewTabs from './../myRequestsCalendarViewTabs';
import WeekTypeRangeSelectorComponent from './../calendarRangeTypeSelectorComponents/weekTypeRangeSelectorComponent';
import InstructorFilterComponent from './../instructorFilterComponent';
import ClassesFilterComponent from './../classesFilterComponent';
import DownloadComponent from './../downloadComponent';
import YearTypeRangeSelectorComponent from './../calendarRangeTypeSelectorComponents/yearTypeRangeSelectorComponent';
import ShowAllEventsButtonComponent from '../showAllEventsButtonComponent';
import ShowPastEventsButtonComponent from '../showPastEventsButtonComponent';
import MyRequestStatusFilterComponent from '../myRequestsFiltersComponents/myRequestStatusFilterComponent';
import SkillsFilterComponent from '../skillsFilterComponent';
import CalendarListTab from '../calendarListTab';
import CreateSubRequestButtonComponent from '../createSubRequestButtonComponent';

export default function FiltersComponent(props) {
  const { selectedStatus, setSelectedStatus, business, approvedCount, cancelledCount, managerCount, noShowCount, openCount, pendingCount, regionLocationsList, selectedLocations, hanldleSelectLocations, selectedRegions, selectedMonth, setSelectedMonth, myRequestsTab, handleMyRequestsTab, sentCount, availableCount, path, calendarViewType, handleCalendarViewType, currentDate, selectedWeek, handleWeekChange, selectedInstructors, handleSelectInstructors, handleSelectClasses, selectedClasses, activeTab, selectedYear, showAllEvents, handleSetShowAllEvents, showRegions, hidePastEvents, handleSetHidePastEvents, user, setMyRequestStatusFilter, sentManagerCount, sentPendingCount, sentApprovedCount, sentAcceptedCount, sentReopenCount, sentStatusFilter, availableSelectedStatus, availablePendingCount, availableAcceptCount, availableDeclineCount, deletedCount, handleSelectSkills, selectedSkills, setActiveTab, permissions } = props

  const handleSetSelectedStatus = (data) => {
    setSelectedStatus(data)
  }

  const handleChangeMonth = (data) => {
    setSelectedMonth(data)
  }

  const handleCalendarViewChange = (data) => {
    handleCalendarViewType(data)
  }

  const handleChangeWeek = (data) => {
    handleWeekChange(data)
  }

  return (
    <Fragment>
      <div className="py-5 px-8 bg-white">
        <div className={`flex justify-between flex-wrap gap-3`}>
          <div className={`flex items-center gap-3 month-select-filter flex-wrap ${(['my-requests'].includes(path)) ? 'min-wc-330' : ''}`}>
            {calendarViewType == 'month' &&
              <MonthSelectComponent selectedMonth={selectedMonth} handleChangeMonth={handleChangeMonth} />
            }
            {['week', 'day'].includes(calendarViewType) &&
              <WeekTypeRangeSelectorComponent handleChangeWeek={handleChangeWeek} selectedWeek={selectedWeek} calendarViewType={calendarViewType} currentDate={currentDate}/>
            }
            {calendarViewType == 'year' &&
              <YearTypeRangeSelectorComponent selectedYear={selectedYear} handleChangeYear={handleChangeWeek}/>
            }
            {(['all-requests'].includes(path)) && (
              <Fragment>
                <div className="w-c-26">
                  <CalenderViewFilter 
                    calendarViewType={calendarViewType} 
                    handleCalendarViewChange={handleCalendarViewChange}
                    path={path}
                    user={user}
                  />
                </div>
                <CalendarListTab
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                />
              </Fragment>
            )}
          </div>
          {(['my-requests'].includes(path)) &&
            <div className="flex items-center gap-3 flex-wrap">
              <CalendarViewTabs myRequestsTab={myRequestsTab} handleMyRequestsTab={handleMyRequestsTab} sentCount={sentCount} availableCount={availableCount} approvedCount={approvedCount}/>
            </div>
          }
          {(['my-requests'].includes(path)) &&
            <div className="flex items-center gap-3 flex-wrap">
              <div className="w-c-26">
                <CalenderViewFilter 
                  calendarViewType={calendarViewType} 
                  handleCalendarViewChange={handleCalendarViewChange}
                  path={path}
                  user={user}
                />
              </div>
              {activeTab == 'calendar' &&
                <ShowAllEventsButtonComponent 
                  showAllEvents={showAllEvents} 
                  handleSetShowAllEvents={handleSetShowAllEvents}
                  path={path}
                />
              }
              {activeTab == 'list' &&
                <ShowPastEventsButtonComponent 
                  hidePastEvents={hidePastEvents} 
                  handleSetHidePastEvents={handleSetHidePastEvents}
                  path={path}
                />
              }
            </div>
          }
          {(['all-requests'].includes(path)) && (
            <div className="flex items-center gap-3 flex-wrap">
              <CreateSubRequestButtonComponent user={user} permissions={permissions} business={business} path={path}/>
              <DownloadComponent
                business={business}
                user={user}
                selectedStatus={selectedStatus}
                selectedLocations={selectedLocations}
                selectedInstructors={selectedInstructors}
                selectedClasses={selectedClasses}
                selectedSkills={selectedSkills}
              />
            </div>
          )}
        </div>
        {(['all-requests'].includes(path)) &&
          <div className="flex justify-center items-center flex-wrap gap-3 mt-4">
            <div className="w-56">
              <StatusFilterComponent selectedStatus={selectedStatus} setSelectedStatus={handleSetSelectedStatus} business={business} approvedCount={approvedCount} cancelledCount={cancelledCount} managerCount={managerCount} noShowCount={noShowCount} openCount={openCount} pendingCount={pendingCount} user={user} deletedCount={deletedCount}/>
            </div>
            <div className="w-60">
              <LocationFilterComponent regionLocationsList={regionLocationsList} selectedFilterLocations={selectedLocations} hanldleSelectFilterLocations={hanldleSelectLocations} selectedFilterRegions={selectedRegions} showRegions={showRegions} user={user} path={path}/>
            </div>
            <div className="w-56">
              <InstructorFilterComponent selectedInstructors={selectedInstructors} handleSelectInstructors={handleSelectInstructors} user={user} page={path}/>
            </div>
            <div className="w-56">
              <ClassesFilterComponent handleSelectClasses={handleSelectClasses} selectedClasses={selectedClasses} user={user}/>
            </div>
            <div className="w-56">
              <SkillsFilterComponent handleSelectSkills={handleSelectSkills} selectedSkills={selectedSkills} user={user}/>
            </div>
            {activeTab == 'calendar' &&
              <ShowAllEventsButtonComponent 
                showAllEvents={showAllEvents} 
                handleSetShowAllEvents={handleSetShowAllEvents}
                path={path}
              />
            }
            {activeTab == 'list' &&
              <ShowPastEventsButtonComponent 
                hidePastEvents={hidePastEvents} 
                handleSetHidePastEvents={handleSetHidePastEvents}
                path={path}
              />
            }
          </div>
        }
        {(['my-requests'].includes(path)) && (['sent', 'available'].includes(myRequestsTab)) &&
          <div className="flex justify-center items-center flex-wrap gap-3 mt-3">
            <div className="w-56">
              <MyRequestStatusFilterComponent
                selectedStatus={sentStatusFilter}
                setMyRequestStatusFilter={setMyRequestStatusFilter}
                business={business}
                managerCount={sentManagerCount}
                approvedCount={sentApprovedCount}
                acceptedCount={sentAcceptedCount}
                pendingCount={sentPendingCount}
                reopenCount={sentReopenCount}
                user={user}
                type={myRequestsTab}
                availableSelectedStatus={availableSelectedStatus}
                availablePendingCount={availablePendingCount}
                availableAcceptCount={availableAcceptCount}
                availableDeclineCount={availableDeclineCount}
              />
            </div>
            {myRequestsTab == 'available' &&
              <div className="w-60">
                <LocationFilterComponent regionLocationsList={regionLocationsList} selectedFilterLocations={selectedLocations} hanldleSelectFilterLocations={hanldleSelectLocations} selectedFilterRegions={selectedRegions} showRegions={showRegions} user={user} path={path}/>
              </div>
            }
          </div>
        }
      </div>
    </Fragment>
  )
}