import React, { Fragment, useState, useEffect } from "react";
import { useParams, Redirect } from 'react-router-dom';
import DropZone from './dropZone';
import DocumentationCard from './documentationCard'
import AlertPopup from '../../../messenger/pages/chatComponents/assets/alertPopup';
import { LoadingSpinner } from '../../helpers';
import { axiosGetRequest } from '../../../subRequests/helpers/helpers';
import HistoryComponent from './historyComponent';
import HeaderComponent from './headerComponent';

export default function SubmitDocumentation(props) {
  const { user, path, parentLoaded } = props
  const { id } = useParams();
  const [ pageType, setPageType ] = useState('view')
  const [ documentation, setDocumentation ] = useState({
    id: id,
    name: '',
    expiration_date: '',
    business_user_id: '',
    documentation_category_id: '',
    key_url: '',
    category: {name: '', expiration_date_required: false},
    status: '',
    submitted_at: '',
    submitted_by: '',
    documentation_status: '',
    expiring_soon: false
  })
  const [ documentationCopy, setDocumentationCopy ] = useState(documentation)
  const [ loaded, setLoaded ] = useState(false)
  const [ showAlert, setShowAlert ] = useState(false)
  const [ alertMessage, setAlertMessage ] = useState('')
  const [ alertType, setAlertType ] = useState('')
  const [ redirectUrl, setRedirectUrl ] = useState('')
  const [ redirect, setRedirect ] = useState(false)
  const [ formEditable, setFormEditable ] = useState(false)
  const [ firstTime, setFirstTime ] = useState(true)
  const [ fileToUpload, setFileToUpload ] = useState(null)

  useEffect(() => {
    if(parentLoaded && firstTime){
      setFirstTime(false)
      getPageType()
    }
  }, [parentLoaded]);

  const getPageType = () => {
    let params = new URLSearchParams(window.location.search)
    let type = (params.has('page-type') && params.get('page-type') !== '') ? params.get('page-type') : ''
    if(type === '' || ![ 'renew', 'edit', 'view', 'add' ].includes(type)){
      let pathName = window.location.pathname.replace(/^\/+/, "")
      window.history.replaceState(`path:${pathName}?page-type=view`, '', `/${pathName}?page-type=view`)
      setPageType('view')
      setFormEditable(false)
      fetchDocumentationDetails('view')
    }else{
      setPageType(type)
      setFormEditable(['renew', 'edit', 'add'].includes(type))
      fetchDocumentationDetails(type)
    }
  }

  const fetchDocumentationDetails = (type) => {
    let url = `/api/v3/documentations/${id}`
    axiosGetRequest(url).then(result => {
      if(result.status === 200){
        let expiration_date = result?.results?.expiration_date
        let newData = {
          ...result?.results,
          expiration_date: ((expiration_date && expiration_date !== '') ? new Date(expiration_date) : '')
        }
        setDocumentationCopy(newData)
        if(type == 'renew'){
          newData = get_renew_type_data(newData)
        }
        setDocumentation(newData)
        setLoaded(true)
      }else{
        setAlertMessage(result.error)
        setAlertType('danger')
        setShowAlert(true)
        setTimeout(()=>{
          setShowAlert(false)
          handleRedirect()
        },3000)
      }
    })
  }

  const handleShowAlert = (show, message, type) => {
    setAlertMessage(message)
    setAlertType(type)
    setShowAlert(show)
  }

  const handleRedirect = () => {
    setRedirectUrl(location?.pathname?.split('/submit-documentation')[0])
    setRedirect(true)
  }

  const handleDiscard = () => {
    viewChange('view')
    handlePageSwitch('view')
  }

  const viewChange = (type, reload=false) => {
    let pathName = window.location.pathname.replace(/^\/+/, "")
    window.history.replaceState(`path:${pathName}?page-type=${type}`, '', `/${pathName}?page-type=${type}`)
    setPageType(type)
    setFormEditable((type !== 'view'))
    if(reload){
      handlePageSwitch(type)
    }
  }

  const handlePageSwitch = (type) => {
    let newData = documentationCopy
    if(type == 'renew'){
      newData = get_renew_type_data(newData)
    }
    setDocumentation(newData)
    setFileToUpload(null)
  }

  const submitComplete = (type, data) => {
    viewChange(type)
    updateDocumentation(type, data)
  }

  const updateDocumentation = (type, data) => {
    let expiration_date = data?.expiration_date
    let newData = {
      ...data,
      expiration_date: ((expiration_date && expiration_date !== '') ? new Date(expiration_date) : '')
    }
    setDocumentationCopy(newData)
    if(type == 'renew'){
      newData = get_renew_type_data(newData)
    }
    setDocumentation(newData)
    setFileToUpload(null)
  }

  const get_renew_type_data = (data) => {
    return(
      {
        ...data,
        key_url: '',
        expiration_date: '',
        name: ''
      }
    )
  }

  return(
    <Fragment>
      {redirect &&
        <Redirect to={redirectUrl}/>
      }
      {showAlert &&
        <AlertPopup alert_type={alertType} alert_message={alertMessage} closeAlert={(data) => handleShowAlert(data, '', '')} />
      }
      <HeaderComponent
        loaded={loaded}
        pageType={pageType}
        documentation={documentation}
        handleShowAlert={handleShowAlert}
        handleRedirect={handleRedirect}
        handleDiscard={handleDiscard}
        viewChange={viewChange}
        submitComplete={submitComplete}
        setFileToUpload={setFileToUpload}
        fileToUpload={fileToUpload}
      />
      {parentLoaded &&
        <Fragment>
          {!loaded &&
            <LoadingSpinner />
          }
          {loaded &&
            <div className="flex lg:flex-row flex-col gap-5">
              <div className="w-full lg:w-3/5">
                <DropZone
                  documentation={documentation}
                  setDocumentation={setDocumentation}
                  handleShowAlert={handleShowAlert}
                  pageType={pageType}
                  setDocumentationCopy={setDocumentationCopy}
                  setFileToUpload={setFileToUpload}
                  fileToUpload={fileToUpload}
                />
              </div>
              <div className="w-full lg:w-2/5">
                <DocumentationCard
                  documentation={documentation}
                  setDocumentation={setDocumentation}
                  handleShowAlert={handleShowAlert}
                  handleRedirect={handleRedirect}
                  formEditable={formEditable}
                  pageType={pageType}
                  user={user}
                />
                {(pageType === 'view' && documentation?.histories?.length > 0) &&
                  <HistoryComponent documentation={documentation}/>
                }
              </div>
            </div>
          }
        </Fragment>
      }
      {!parentLoaded &&
        <LoadingSpinner />
      }
    </Fragment>
  )
}