import React from 'react'
import Cookies from 'js-cookie';
import { getSudomain } from '../../../../helpers'

function getHeaders(token, subdomain){  
  if (token !== undefined) {
    let obj = {
      'Accept': 'application/vnd.netgym.v3',
      'Content-Type': 'application/json',
      'Authorization': eval(`${token}`),
      'subdomain': localStorage.getItem("businessID")
    }
    return obj    
  }  
}

function userToken(){
  return `${Cookies.get(`currentUser.${getSudomain()}`)}`
}

export const axiosGetRequest = async(url) => {
  return await fetch(url, {
    headers: getHeaders(`${userToken()}`, `${getSudomain()}`)
  }).then(      
    response => response.json()
  )
}

export const getTooltipMsg = (user) =>{
  let msg = ""
  // if(user.business_skill_ids.length === 0 && user.business_location_ids.length === 0 && !user.email){
  //   msg = `${user.first_name} needs to be assigned email, skills and locations.`
  // }
  // else if(user.business_skill_ids.length === 0 && user.business_location_ids.length === 0 && user.email){
  //   msg = `${user.first_name} needs to be assigned skills and locations.`
  // }
  // else if(user.business_skill_ids.length === 0 && user.business_location_ids.length > 0 && !user.email){
  //   msg = `${user.first_name} needs to be assigned email and skills.`
  // }
  // else if(user.business_skill_ids.length === 0 && user.business_location_ids.length > 0 && user.email){
  //   msg = `${user.first_name} needs to be assigned skills.`
  // }
  // else if(user.business_skill_ids.length > 0 && user.business_location_ids.length === 0 && !user.email){
  //   msg = `${user.first_name} needs to be assigned email and locations.`
  // }
  // else if(user.business_skill_ids.length > 0 && user.business_location_ids.length === 0 && user.email){
  //   msg = `${user.first_name} needs to be assigned locations.`
  // }
  // else if(user.business_skill_ids.length > 0 && user.business_location_ids.length > 0 && !user.email){
  //   msg = `${user.first_name} needs to be assigned email.`
  // }
  if(user.business_skill_ids.length === 0 && user.business_location_ids.length === 0){
    msg = `${user.first_name} needs to be assigned skills and locations.`
  }else if(user.business_skill_ids.length === 0 && user.business_location_ids.length > 0){
    msg = `${user.first_name} needs to be assigned skills.`
  }else if(user.business_skill_ids.length > 0 && user.business_location_ids.length === 0){
    msg = `${user.first_name} needs to be assigned locations.`
  }
  else{
    msg = ""
  }
  return msg
}

export const getFisikalTooltipMsg = (user) =>{
  let msg = ""
  if(user.business_skill_ids.length === 0 && user.business_location_ids.length === 0){
    msg = `${user.first_name} has no skills or locations and needs to be assigned services and allocated locations in Fisikal.`
  }else if(user.business_skill_ids.length === 0 && user.business_location_ids.length > 0){
    msg = `${user.first_name} has no skills and needs to be assigned services in Fisikal.`
  }else if(user.business_skill_ids.length > 0 && user.business_location_ids.length === 0){
    msg = `${user.first_name} has no locations and needs to be allocated locations in Fisikal.`
  }
  else{
    msg = ""
  }
  return msg
}

export const getClubreadyTooltipMsg = (user) =>{
  let msg = ""
  if(user.business_skill_ids.length === 0 && user.business_location_ids.length === 0){
    msg = `${user.first_name} has no skills or locations and needs to be assigned classes and locations in ClubReady.`
  }else if(user.business_skill_ids.length === 0 && user.business_location_ids.length > 0){
    msg = `${user.first_name} has no skills and needs to be assigned classes in ClubReady.`
  }else if(user.business_skill_ids.length > 0 && user.business_location_ids.length === 0){
    msg = `${user.first_name} has no locations and needs to be assigned locations in ClubReady.`
  }
  else{
    msg = ""
  }
  return msg
}

export const filterUsers = async (user_token, subdomain, page, text, sortType, sortReverse, userIds, selectedStatus, selectedRole, pageTab, selectedHomeClubs) => {    
  return await fetch(`/api/v3/get_filtered_users`,{
    method: "POST",
    body: JSON.stringify({ text, page, pageSize: 30, sortType, sortReverse, ids: userIds, selectedStatus, selectedRole, pageTab, selectedHomeClubs }),
    headers: getHeaders(user_token, subdomain)
  }).then(      
    response => response.json()
  );  
}

export const hideItems = (cls) => {
  $('.filter_items').each(function(i, obj) {
    if($(obj).hasClass(`${cls}_items`)){
      $(`.${cls}_items`).toggleClass("hidden")
    }else{
      $(obj).addClass("hidden")
    }
  });
}

export const axiosGetExportRequest = async(url) => {
  return await fetch(url, {
    headers: getHeaders(`${userToken()}`, `${getSudomain()}`)
  }).then(      
    response => response.blob()
  )
}

export const initial_user = {
  first_name: '',
  last_name: '',
  email: '',
  home_club_id: '',
  time_zone_string: '',
  role: 'instructor',
  mbo_site_id: '',
  mindbody_id: '',
  external_id: '',
  add_user_in_classes: false,
  employee_id: '',
  directory_phone_number: ''
}

export const sendConfirmationAll = async (user_token, subdomain, count, userIds) => {  
  return await fetch(`/api/v3/send_confirmation_mail_to_all?users_count=${parseInt(count)}`,{
    method: "POST",
    body: JSON.stringify({ user_ids: userIds }),
    headers: getHeaders(user_token, subdomain)
  }).then(      
    response => response.json()
  );
}

export const disableAutofill = () => {
  let searchField = document.getElementsByClassName('users-search-class')
  if(searchField.length > 0){
    searchField[0].setAttribute('readonly', true)
  }
}

export const enableEditing = () => {
  let searchField = document.getElementsByClassName('users-search-class')
  if(searchField.length > 0){
    searchField[0].removeAttribute('readonly')
  }
}

export const validateEmail = (email) => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
}

export const locationName = (locationList, allSelectedLocations) => {
  let locations = locationList
  let selected_locations = allSelectedLocations
  let locationNames = []
  let ctr = 0
  for(let i = 0; i < locations?.length; i++){
    if(selected_locations.includes(locations[i].id)){
      if(ctr < 2){
        locationNames.push(locations[i].name)
        ctr++
      }
      else{
        break;
      }
    }
  }
  return chekLocationNameCount(locationNames.join(", "))
}

export const chekLocationNameCount = (name) => {
  let shortName = []
  for(let i = 0; i < name?.length; i++){
    if(i < 19){
      shortName.push(name[i])
    }else{
      shortName.push('...')
      break
    }
  }

  return shortName.join('')
}

export const usersForActivationEmails = (users) => {
  return (
    users?.filter(item => {
      let email = item?.email.trim(); 
      return (email && email !== "" && validateEmail(email) && item?.active_status !== "Active")
    })?.map(item => item.id)
  )
}