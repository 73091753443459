import React, { useEffect, useContext } from "react";
import { useIntercom } from 'react-use-intercom';
import UserContext from '../../userComponents/userContext';

const IntercomManager = ({ isLoggedIn, user, signInPage=false }) => {
  const { boot, shutdown } = useIntercom();
  const { setUserLoggedIn } = useContext(UserContext);
  
  useEffect(() => {
    const bootIntercom = () => {
      if (isLoggedIn) {
        let params = {
          email: user?.email,
          createdAt: user?.created_at_unix,
          userId: user?.id,
        }
        boot(params);
        setUserLoggedIn(true);
      } else {
        shutdown();
        if(signInPage) {
          boot();
        }
        setUserLoggedIn(false);
      }
    };
    const timer = setTimeout(bootIntercom, 100);

    return () => clearTimeout(timer);
  }, [isLoggedIn, user, signInPage, boot, shutdown]);

  return null;
};

export default IntercomManager;