import React, { Fragment } from "react";
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon, } from '@heroicons/react/outline'

export default function SkillsDropdownComponent(props) {
  const { clubreadyFisikalIntegration, skills, selectedSkillId, skillType, handleSelectSkill, skillName, removeSelectedSkill} = props;

  const checkDisabled = () => {
    return (clubreadyFisikalIntegration && skillType === 'primary')
  }

  return(
    <Fragment>
      <div className='grid grid-cols-1 sm:gap-4 sm:items-start mt-2'>
        <div className='mt-1 sm:mt-0 sm:col-span-1'>
          <div className="relative filters_box w-full">
            <Menu as="div" className={`relative block text-left`} disabled={checkDisabled()}>
              <div>
                <Menu.Button className={`h-10 flex items-center justify-center w-full rounded-md border border-gray-300 shadow-sm pl-4 pr-2 py-2 text-sm text-gray-400 focus:outline-nonefocus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 ${checkDisabled() ? 'bg-gray-100 cursor-not-allowed' : ''}`} disabled={checkDisabled()}>
                  {selectedSkillId === null ?
                    <p>Select skill</p>
                  :
                    <p className='text-gray-900'>{`1 skill selected`}</p>
                  } 
                  <ChevronDownIcon className="ml-auto text-gray-400 w-4 mr-0.5" aria-hidden="true" />
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95">
                <Menu.Items className="origin-top-right z-10 relative right-0 w-full rounded-md shadow-lg bg-white ring-none border border-gray-300 focus:outline-none">
                  <div className="p-2 max-h-60 overflow-y-auto pb-12">
                    {skills.map((skill, i) =>
                      <div className="py-1" key = {i}>
                        <label className="flex items-center text-gray-900 text-sm mb-2 profile-notification-container relative">
                          <input type="checkbox" className={`w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-1`} id={skill.id} name="skills[]" onChange={(e) => handleSelectSkill(e, skillType)} value={skill.id} checked={selectedSkillId === skill.id}/>
                          <label className={`ml-1.5 text-sm font-medium text-gray-500`} htmlFor={skill.id}>{skill?.name}</label> 
                        </label>
                      </div>
                    )}
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
          {selectedSkillId !== null &&
            <div className='flex items-center mt-3 gap-2 flex-wrap'>
              <div className='bg-purple-100 text-purple-800 py-0.5 px-2.5 flex items-center rounded-xl'>
                <div className='text-sm pr-1 font-medium'>{skillName(selectedSkillId)}</div>
                <a onClick={() => removeSelectedSkill(selectedSkillId, skillType)} className="cursor-pointer">
                  <svg xmlns="http://www.w3.org/2000/svg" className="text-violet-800  h-3 w-3" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                  </svg>
                </a>
              </div>
            </div>
          }
        </div>
      </div>
    </Fragment>
  )
}