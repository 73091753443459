import React from "react";
import EditButtonIcon from "../../assets/editButtonIcon";

export default function EditButton({viewChange}) {
  const handleOnClick = () => {
    viewChange('edit')
  }

  return (
    <button className={`flex bg-dark-blue border border-gray-900 sm:text-base text-sm text-white inline-block px-5 py-2 border shadow rounded-lg relative gap-2 items-center hover:bg-gray-800`} onClick={() => handleOnClick()}>
      <EditButtonIcon classNames={'hc-14 wc-14 text-black shrink-0'} strokeColor={'white'}/>
      <div className='text-white text-base'>
        {`Edit`}
      </div>
    </button>
  )
}