import React, { Fragment, useState } from "react";
import PdfView from './pdfView';
import { getFileType } from '../../helpers';
import { axiosRequest } from "../../../subRequests/helpers/helpers";
import RemoveFileConfirmation from './removeFileConfirmation';
import PdfPreview from './pdfPreview';

export default function ContentViewer(props) {
  const { documentation, setDocumentation, handleShowAlert, pageType, setDocumentationCopy, fileToUpload, setFileToUpload } = props
  const fileType = getFileType(documentation?.key_url);
  const [ disabledRemove, setDisabledRemove ] = useState(false)
  const [ showConfirmation, setShowConfirmation ] = useState(false)

  const renderImage = (url) => {
    return (
      <div className="detail-img flex items-center justify-center">
        <img src={url} />
      </div>
    )
  }

  const renderDefault = () => {
    return (<div>Unsupported content type or CORS issue: Unable to load the resource.</div>)
  }
  
  const renderContent = () => {
    switch (fileType) {
      case "image":
        return (renderImage(documentation?.key_url));
      case "pdf":
        return (<PdfView docUrl={documentation?.key_url}/>);
      default:
        return renderDefault();
    }
  };

  const renderFileOrUrl = () => {
    if(documentation?.key_url){
      return (renderContent())
    }else{
      return (renderFile())
    }
  }

  const renderFile = () => {
    let fileType = (fileToUpload?.file?.type || '').split("/").includes('image') ? 'image' : 'pdf'
    switch (fileType) {
      case "pdf":
        return (<PdfPreview file={fileToUpload?.file}/>);
      case "image":
        return (renderImage(fileToUpload?.previewUrl));
      default:
        return renderDefault();
    };
  }

  const handleClose = (data) => {
    if (disabledRemove) return;
    setShowConfirmation(data)
  }

  const processRemove = () => {
    setDocumentation((prevDocumentation) => ({ ...prevDocumentation, key_url: '' }))
    setFileToUpload(null)
  }

  const removeDocumentation = () => {
    setDisabledRemove(true)
    axiosRequest(`/api/v3/documentations/remove_file`, 'PATCH', {id: documentation?.id}, 'data').then((response) => {
      if(response.status === 200){
        handleShowAlert(true, response?.message, 'success')
        setTimeout(() => {
          handleShowAlert(false, '', '')
          setDisabledRemove(false)
          setDocumentation((prevDocumentation) => ({ ...prevDocumentation, key_url: '' }))
          setDocumentationCopy((prevDocumentation) => ({ ...prevDocumentation, key_url: '' }))
        }, 3000)
      }else{
        handleShowAlert(true, response?.error, 'danger')
        setTimeout(() => {
          handleShowAlert(false, '', '')
          setDisabledRemove(false)
        }, 3000)
      }
    })
  }

  return(
    <Fragment>
      {showConfirmation && (
        <RemoveFileConfirmation showConfirmation={showConfirmation} handleClose={handleClose} processRemove={processRemove} disabledRemove={disabledRemove}/>
      )}
      {renderFileOrUrl()}
      <div className="w-full flex py-2 justify-center items-center mt-2">
        <button className={`flex bg-dark-blue hover:bg-gray-800 sm:text-base text-sm text-white inline-block px-3 py-2 border shadow rounded-lg relative gap-2 items-center ${(disabledRemove || pageType == 'view') ? 'cursor-not-allowed opacity-50' : ''}`} onClick={(e) => setShowConfirmation(true)} disabled={disabledRemove || pageType == 'view'}>
          <div className='text-white text-base'>Remove File</div>
        </button>
      </div>
    </Fragment>
  )
}