import React, { Fragment } from "react";
import FiltersComponents from "./filtersComponents";
import IntegratedBusinessClassesTableComponent from './integratedBusinessClassesTableComponent';
import CommentSectionComponent from './commentSectionComponent';
import NextStepButtonComponent from './nextStepButtonComponent';
import { integratedBusiness } from '../../../subRequests/helpers/helpers';
import BasicBusinessSelectClassComponents from './basicBusinessSelectClassComponents';
import ReasonComponent from './filtersComponents/reasonComponent';
import OtherReasonComponent from './filtersComponents/reasonComponent/otherReasonComponent';
import PersonalInviteToggle from './personalInviteToggle';

export default function FirstStepComponents(props) {
  const { user, business, newSubRequest, setNewSubRequest, setStep, permissions } = props
  const businessIntegration = integratedBusiness(business) || false
  return (
    <Fragment>
      <div className="w-full">
        {!newSubRequest?.editSubRequest &&
          <FiltersComponents 
            user={user} 
            business={business} 
            integratedBusiness={businessIntegration}
            newSubRequest={newSubRequest}
            setNewSubRequest={setNewSubRequest}
          />
        }
        {businessIntegration ?
          <Fragment>
            <IntegratedBusinessClassesTableComponent 
              user={user}
              business={business}
              newSubRequest={newSubRequest} 
              setNewSubRequest={setNewSubRequest}
            />
          </Fragment>
          :
          <BasicBusinessSelectClassComponents
            user={user}
            newSubRequest={newSubRequest} 
            setNewSubRequest={setNewSubRequest}
          />
        }
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-0 lg:gap-6">
          <div>
            {(!user?.nysc_gym) &&
              <Fragment>
                <div className={`gap-6 create-sub-instructor-grid mb-6`}>
                  <ReasonComponent 
                    newSubRequest={newSubRequest}
                    setNewSubRequest={setNewSubRequest}
                    business={business}
                    businessIntegration={businessIntegration}
                  />
                </div> 
                {newSubRequest.reason?.reason == 'Other' &&
                  <div className={`gap-6 create-sub-instructor-grid mb-6`}>
                    <OtherReasonComponent
                      newSubRequest={newSubRequest}
                      setNewSubRequest={setNewSubRequest}
                      business={business}
                    />
                  </div>
                }
              </Fragment>
            }
          </div>
          {!business?.invite_all_default &&
            <div className={`gap-6 create-sub-instructor-grid mb-6`}>
              <PersonalInviteToggle 
                newSubRequest={newSubRequest}
                setNewSubRequest={setNewSubRequest}
                user={user}
                step={'1'}
              />
            </div>
          }
        </div>
        <div className={`gap-6 grid grid-cols-1 lg:grid-cols-2 create-sub-instructor-grid mb-6`}>
          <CommentSectionComponent 
            newSubRequest={newSubRequest}
            setNewSubRequest={setNewSubRequest}
            user={user}
            common={false}
          />
        </div>
        <NextStepButtonComponent 
          user={user}
          business={business} 
          integratedBusiness={businessIntegration}
          newSubRequest={newSubRequest}
          setStep={setStep}
          permissions={permissions}
        />
      </div>
    </Fragment>
  )
}