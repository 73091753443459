import React from "react";

export default function DocumentationIcon({classNames, strokeColor='white'}) {
  return(
    <svg className={classNames} viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.45825 1.6665H3.49992C3.1905 1.6665 2.89375 1.78942 2.67496 2.00821C2.45617 2.22701 2.33325 2.52375 2.33325 2.83317V12.1665C2.33325 12.4759 2.45617 12.7727 2.67496 12.9915C2.89375 13.2103 3.1905 13.3332 3.49992 13.3332H10.4999C10.8093 13.3332 11.1061 13.2103 11.3249 12.9915C11.5437 12.7727 11.6666 12.4759 11.6666 12.1665V4.87484L8.45825 1.6665Z" stroke={strokeColor} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M8.16675 1.6665V5.1665H11.6667" stroke={strokeColor} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M5.25 9.25016L6.41667 10.4168L8.75 8.0835" stroke={strokeColor} strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}