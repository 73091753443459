import React, { Fragment, useState } from "react";
import { Switch } from '@headlessui/react'

export default function PersonalInviteToggle(props) {
  const { newSubRequest, setNewSubRequest, business, step, fetchInstructors } = props
  const [ disabledBtn, setDisabledBtn ] = useState(false)

  const handleChanege = (e, name) => {
    if (step == '2') {
      setDisabledBtn(true)
      fetchInstructors('', true, 'step_2_button')
    }else{
      setNewSubRequest(prevNewSubRequest => ({
        ...prevNewSubRequest,
        [name]: !prevNewSubRequest[name]
      }))
    }
  }

  return (
    <Fragment>
      {step == '1' ?
        <div className="flex flex-col gap-2 filter-column justify-end">
          <div className="hidden md:flex text-base md:text-lg text-black font-semibold items-center gap-2 flex-wrap">
            Audience
          </div>
          <div className="flex md:hidden text-base md:text-lg text-black font-semibold items-center gap-2 flex-wrap">
            Only send Invite to select user(s)
          </div>
          <div className="relative filters_box">
            <div className="flex w-full rounded-2xl pl-0 md:pl-4 pr-2 py-0 md:py-2.5 audience-bg-gray-custom-50 text-sm text-gray-900 focus:outline-none focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 md:h-11 gap-1 items-center opacity-c-100 border-2 border-transparent">
              <div className='inline-flex items-center align-middle'>
                <Switch
                  id={'personalInviteToggle'}
                  checked={newSubRequest?.allowPersonalInvites}
                  name={'allowPersonalInvites'}
                  onChange={(e) => handleChanege(e, 'allowPersonalInvites')}
                  className={`inline ${newSubRequest?.allowPersonalInvites ? 'bg-gray-900' : 'bg-gray-200'} relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none`}
                >
                  <span className="sr-only">Use setting</span>
                  <span
                    aria-hidden="true"
                    className={`${newSubRequest?.allowPersonalInvites ? 'translate-x-5' : 'translate-x-0'} pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200`}
                  />
                </Switch>
                <label className={`hidden md:flex inline ml-2 text-sm`} htmlFor={'personalInviteToggle'}>
                  Only send Invite to select user(s)
                </label>
              </div>            
            </div>
          </div>
        </div>
      : step == '2' &&
        <div className="flex filter-column">
          <div className="relative filters_box">
            <button
              className={`flex w-full rounded-2xl px-6 font-medium py-2 border border-gray-200 my-3 text-sm text-gray-900 focus:outline-none focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 h-10 gap-1 items-center opacity-c-100 border-2 border-transparent relative ${disabledBtn ? 'cursor-not-allowed' : 'hover:bg-gray-100'}`}
              onClick={(e) => handleChanege(e, 'allowPersonalInvites')}
              disabled={disabledBtn}
            >
              {disabledBtn && 
                <div className="flex items-center left-1 justify-center absolute">
                  <svg className="animate-spin h-4 w-4 text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                </div>
              }
              Only send Invite to select user(s)
            </button>
          </div>
        </div>
      }
    </Fragment>
  )
}