import React, { Fragment, useState, useCallback} from "react";
import { useDropzone } from "react-dropzone";
import LoadingIcon from '../../../subRequests/assets/loadingIcon';
import { axiosRequest } from '../../../subRequests/helpers/helpers';
import ContentViewer from "./contentViewer";

export default function DropZone(props) {
  const { documentation, setDocumentation, handleShowAlert, pageType, setDocumentationCopy, fileToUpload, setFileToUpload } = props

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      const droppedFile = acceptedFiles[0];
      let fileData = {
        file: droppedFile,
        name: droppedFile.name,
        size: droppedFile.size,
        status: "uploaded",
        previewUrl: URL.createObjectURL(droppedFile)
      }
      setFileToUpload(fileData);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/*': [],
      'application/pdf': ['.pdf']
    },
    maxFiles: 1,
    disabled: fileToUpload?.status === "uploading",
  });

  const checkShowPreview = () => {
    return (documentation?.key_url !== '' || fileToUpload?.status === "uploaded")
  }

  return(
    <Fragment>
      <div className="w-full">
        {(!checkShowPreview()) && (
          <div
            {...getRootProps()}
            className={`border-2 border-dashed md:p-6 p-3 text-center rounded-lg bg-white ${
              isDragActive
                ? "border-gray-500"
                : "border-gray-300"
            }`}
          >
            <input {...getInputProps()} />
            <div className="bg-gray-100 py-40 rounded-lg">
              {fileToUpload?.status === "uploading" ? (
                <div className="flex items-center justify-center h-30">
                  <LoadingIcon classNames={'animate-spin h-6 w-6 text-black'}/>
                </div>
              ) : (
              <div className="flex items-center flex-col gap-y-4">
                <p className="text-gray-900 text-lg font-bold text-center">Drag and drop a photo or PDF file</p>
                <span className="text-gray-900 text-base">Or</span>
                <div className="bg-dark-blue text-white inline-flex items-center rounded-lg font-sm px-3 h-9">
                  <button type="submit" className="flex items-center relative">
                    <div>
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12" />
                      </svg>
                    </div>
                    <div className="text-white ml-2.5">
                      Select File
                    </div>
                  </button>
                </div>
              </div>
              )}
            </div>
          </div>
        )}
        {(checkShowPreview()) && (
          <Fragment>
            <ContentViewer
              documentation={documentation}
              setDocumentation={setDocumentation}
              handleShowAlert={handleShowAlert}
              pageType={pageType}
              setDocumentationCopy={setDocumentationCopy}
              fileToUpload={fileToUpload}
              setFileToUpload={setFileToUpload}
            />
          </Fragment>
        )}
      </div>
    </Fragment>
  )
}