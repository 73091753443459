import React, { Fragment, useState } from "react";
import CustomPopupContainerComponent from './../../../../../../subRequestDetails/components/customPopupContainerComponent';
import ReopenReasonComponent from '../../../../../../subRequests/pages/components/reOpenEventComponent/reopenReasonComponent';
import ReopenOtherReasonComponent from "../../../../../../subRequests/pages/components/reOpenEventComponent/reopenOtherReasonComponent";

export default function ReopenConfirmationComponent(props) {
	const { user, showReopenConfirmationPopup, closeReopenConfirmationPopup, handleConfirmReopen, reopenEventId, reason, setReason, requestingInstructor } = props
	const [ disableConfirmBtn, setDisableConfirmBtn ] = useState(false)

	const handleConfirm = () => {
		setDisableConfirmBtn(true)
		handleConfirmReopen(reopenEventId)
	}

	const checkReopenButtonDisabled = () => {
    if(!user?.nysc_gym && !user?.request_reason_optional){
      if(reason?.reason == ''){
        return true
      }else if(reason?.otherReason == '' && reason?.reason == 'Other'){
        return true
      }
    }

    return false
  }
	
	const requestingInstructorName = () => {
		return user?.id == requestingInstructor?.id ? 'You' : requestingInstructor?.full_name
	}

  return (
		<Fragment>
			<CustomPopupContainerComponent show={showReopenConfirmationPopup} close={closeReopenConfirmationPopup} title={'Reopen Sub Request'} customWidth={''}>
				<div className="flex flex-col w-full border-t border-gray-300 px-6 pt-3 mt-2 gap-6 text-left">
					<div className="text-sm text-gray-700">
						<p>{`Are you sure you want to reopen this sub request? By confirming, ${requestingInstructorName()} will now be the requesting instructor.`}</p>
						<ul className="list-disc pl-6">
							<li>All instructors who accepted previously will be moved to 'No Response' state. We want to ensure they are still free to sub.</li>
							<li>Instructors who did not respond initially will be notified again.</li>
							<li>Instructors who declined will not receive another notification.</li>
							<li>To invite more people, head to the not yet invited section.</li>
						</ul>
					</div>
					<div className={`gap-6 grid grid-cols-1`}>
						<ReopenReasonComponent 
							reason={reason}
							setReason={setReason}
							user={user}
						/>
					</div> 
					{reason?.reason == 'Other' &&
						<div className={`gap-6 grid grid-cols-1`}>
							<ReopenOtherReasonComponent
								reason={reason}
								setReason={setReason}
								user={user}
							/>
						</div>
					}
					<div className="flex justify-end">
						<button 
							className={`bg-gray-900 rounded-xl text-white flex items-center justify-center px-5 py-2 text-sm ${(disableConfirmBtn || checkReopenButtonDisabled()) ? 'opacity-50 cursor-not-allowed' : ''}`}
							onClick={() => handleConfirm()}
							disabled={disableConfirmBtn || checkReopenButtonDisabled()}
						>
							Continue
						</button>
					</div>
				</div>
			</CustomPopupContainerComponent>
		</Fragment>
	)
}