import React, { Fragment, useState } from "react";
import DatePicker from "react-datepicker";
import StatusBubble from '../../assets/statusBubble';

export default function DocumentationCard(props) {
  const { documentation, setDocumentation, formEditable, pageType, user } = props

  const handleDocChange = (name, val) => {
    setDocumentation( prevDocumentation => ({
      ...prevDocumentation,
      [name]: val
    }))
  }

  const dateFormat = () => {
    return (user?.date_format_web?.replace('D', 'd')?.replace('YY', 'yyyy') || "M/d/yyyy")
  }

  return(
    <Fragment>
      <div className="w-full p-4 bg-white shadow border border-gray-100 rounded-lg">
        <div className="flex items-center justify-between pb-2">
          <p className="text-lg font-bold break-all">
            {documentation?.category?.name}
          </p>
          {['renew'].includes(pageType) ?
            <div></div>
          :
            <StatusBubble status={documentation?.documentation_status}/>
          }
        </div>
        <div className="flex items-center flex-wrap pt-4 pb-2 w-full lg:gap-2">
          <label className="block text-left text-sm font-medium text-gray-500 w-full">Document Name*</label>
          <input
            type="text"
            className={`border rounded-md px-2 py-1.5 shadow-sm text-sm h-10 w-full lg:w-3/5 mt-2 lg:mt-0 focus:outline-none bg-white ${!formEditable ? 'cursor-not-allowed' : ''}`}
            value={documentation?.name}
            onChange={(e) => handleDocChange('name', e?.target?.value)}
            name="name"
            placeholder="Enter Document Name"
            disabled={!formEditable}
          />
        </div>
        <div className="flex items-center flex-wrap py-4 w-full lg:gap-2">
          <label className="block text-left text-sm font-medium text-gray-500 w-full">Expiration Date{documentation?.category?.expiration_date_required ? '*' : ''}</label>
          <div className="w-full lg:w-3/5 mt-2 lg:mt-0 relative">
            <DatePicker
              selected={documentation?.expiration_date}
              className={`h-10 w-full rounded-md border shadow-sm px-2 py-1.5 text-sm text-gray-900 focus:outline-none focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 relative z-10 bg-transparent ${!formEditable ? 'cursor-not-allowed' : 'cursor-pointer'}`}
              autoFocus={false}
              minDate={new Date()}
              dateFormat={dateFormat()}
              onChange={(e) => handleDocChange('expiration_date', e ? e : '')} 
              calendarType="US"
              disabled={!formEditable}
            />
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 absolute top-2 right-2.5">
              <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5" />
            </svg>
          </div>
        </div>
        {pageType !== 'renew' &&
          <Fragment>
            {documentation?.status == 'submitted' &&
              <div className="w-full mt-4">
                <div className="flex items-center flex-wrap pb-2 w-full flex-wrap gap-2">
                  <label className="block text-left text-sm font-semibold text-gray-900 w-26">Submitted By:</label>
                  <label className="block text-left text-sm font-semibold text-gray-500">{documentation?.submitted_by}</label>
                </div>
                <div className="flex items-center flex-wrap pb-2 w-full flex-wrap gap-2">
                  <label className="block text-left text-sm font-semibold text-gray-900 w-26">Submitted On:</label>
                  <label className="block text-left text-sm font-semibold text-gray-500">{documentation?.submitted_at}</label>
                </div>
              </div>
            }
          </Fragment>
        }
      </div>
    </Fragment>
  )
}