import React, { Fragment, useState } from "react";
import ActionButton from '../categories/tableActionButton';
import EmptyCategories from '../categories/emptyCategories';
import Pagination from "react-js-pagination";
import TableSortingHeader from './tableSortingHeader';
import EmptyMyDocumentations from '../myDocumentations/emptyMyDocumentations';
import { axiosRequest } from '../../../subRequests/helpers/helpers';
import StatusBubble from '../../assets/statusBubble';

export default function DocumentationsTable(props) {
  const { user, permissions, documentations, dataLoaded, pageSize, page, totalCount, setPageNumber, sortType, sortReverse, setSort, filterDataLoaded, pageType, handleEditDocumentation, handleSetAlert } = props
  const [ disabledEdit, setDisabledEdit ] = useState(false)
  const [ editDoc, setEditDoc ] = useState('')

  const processAddDocumentation = (e, documentation) => {
    if(documentation?.documentation?.id && documentation?.documentation?.id !== '') {
      handleEditDocumentation(documentation?.documentation?.id, 'add')
      return
    }
    setEditDoc(documentation)
    setDisabledEdit(true)
    let params = {
      business_user_id: documentation?.business_user?.id || '',
      documentation_category_id: documentation?.documentation_category?.id || '',
    }
    axiosRequest(`/api/v3/documentations`, 'post', params, 'data').then((response) => {
      if(response.status === 200){
        handleEditDocumentation(response?.documentationId, 'add')
      }else{
        handleSetAlert(true, response?.error, 'danger')
        setDisabledEdit(false)
        setTimeout((handleSetAlert(false, '', '')), 3000)
      }
    })
  }

  const processDocumentation = (e, documentation, type) => {
    if(documentation?.documentation?.id && documentation?.documentation?.id !== '') {
      handleEditDocumentation(documentation?.documentation?.id, type)
      return
    }
  }

  const checkAndDisabled = (documentation) => {
    if (Object.keys(editDoc).length === 0) return false
    return(disabledEdit && documentation?.business_user?.id == editDoc?.business_user?.id && documentation?.documentation_category?.id == editDoc?.documentation_category?.id)
  }

  const checkSubmitType = (documentation) => {
    return(documentation?.documentation?.status == 'Submitted')
  }

  return(
    <Fragment>
      {!dataLoaded &&
        <div className="msg-loading flex flex-col items-center w-full justify-center h-screen">
          <img className = "w-9" src="/assets/business/loading.gif" />
        </div>
      }
      {dataLoaded &&
        <Fragment>
          {!filterDataLoaded &&
            <div className={`absolute z-30 bg-gray-400 bg-opacity-10 mt-2 w-full ${(pageType === 'my-documentation') ? 'my-documentation-list-loader' : 'documentation-list-loader'} flex justify-center rounded-lg`}>
              <div className={`msg-loading flex flex-col items-center w-full mt-20`}>
                <img className = "w-9" src="/assets/business/loading.gif" />
              </div>
            </div>
          }
          {(pageType === 'my-documentation' && documentations.length === 0) &&
            <EmptyMyDocumentations />
          }
          {(pageType === 'instructor-documentation' || (pageType === 'my-documentation' && documentations.length > 0)) &&
            <div className='overflow-x-auto sm:-mx-6 lg:-mx-8 mb-4'>
              <div className='py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8'>
                <div className='shadow border overflow-hidden border-b border-gray-200 rounded-lg'>
                  <table className='min-w-full divide-y divide-gray-200'>
                    <thead className='bg-gray-50'>
                      <tr>
                        {pageType === 'instructor-documentation' &&
                          <th scope="col" className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            <TableSortingHeader sortType={sortType} sortReverse={sortReverse} sort={setSort} sortName={'instructor_name'} title={'Instructor Name'}/>
                          </th>
                        }
                        <th scope="col" className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          <TableSortingHeader sortType={sortType} sortReverse={sortReverse} sort={setSort} sortName={'category_name'} title={'Category'}/>
                        </th>
                        {pageType === 'instructor-documentation' &&
                          <th scope="col" className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Instructor location(s)
                          </th>
                        }
                        <th scope="col" className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider md:w-44">
                          <TableSortingHeader sortType={sortType} sortReverse={sortReverse} sort={setSort} sortName={'documentation_name'} title={'Name'}/>
                        </th>
                        <th scope="col" className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Submitted By
                        </th>
                        <th scope="col" className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          <TableSortingHeader sortType={sortType} sortReverse={sortReverse} sort={setSort} sortName={'submitted_on'} title={'Submitted On'}/>
                        </th>
                        <th scope="col" className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider md:w-44">
                          <TableSortingHeader sortType={sortType} sortReverse={sortReverse} sort={setSort} sortName={'expiration_date'} title={'Expiration Date'}/>
                        </th>
                        <th scope="col" className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider md:w-32">
                          <TableSortingHeader sortType={sortType} sortReverse={sortReverse} sort={setSort} sortName={'status'} title={'Status'}/>
                        </th>
                        <th scope="col" className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-10"></th>
                      </tr>
                    </thead>
                    <tbody className='divide-y divide-gray-200'>
                      {documentations.map((documentation, i) => 
                        <tr key={i} className='bg-white'>
                          {pageType === 'instructor-documentation' &&
                            <td className='px-3 py-4'>
                              <div className='text-sm font-medium text-gray-900 word-break'>{documentation?.business_user?.full_name}</div>
                            </td>
                          }
                          <td className='px-3 py-4'>
                            <div className='text-sm font-medium text-gray-900 word-break'>{documentation?.documentation_category?.name}</div>
                          </td>
                          {pageType === 'instructor-documentation' &&
                            <td className='px-3 py-4'>
                              <div className='text-sm font-medium text-gray-900 word-break'>{documentation?.business_user?.instructor_locations}</div>
                            </td>
                          }
                          <td className='px-3 py-4'>
                            <div className='text-sm font-medium text-gray-900 truncate max-w-230'>
                              {documentation?.documentation?.name}
                            </div>
                          </td>
                          <td className='px-3 py-4'>
                            <div className='text-sm font-medium text-gray-900 word-break'>{!['not submitted'].includes(documentation?.documentation?.status?.toLowerCase()) ? documentation?.documentation?.submitted_by : ''}</div>
                          </td>
                          <td className='px-3 py-4'>
                            <div className='text-sm font-medium text-gray-900 truncate'>{!['not submitted'].includes(documentation?.documentation?.status?.toLowerCase()) ? documentation?.documentation?.submitted_at : ''}</div>
                          </td>
                          <td className='px-3 py-4'>
                            <div className='text-sm font-medium text-gray-900'>
                              {documentation?.documentation?.expiration_date}
                            </div>
                          </td>
                          <td className='px-3 py-4'>
                            <div className='text-sm font-medium text-gray-900 flex justify-start'>
                              <StatusBubble status={documentation?.documentation?.status}/>
                            </div>
                          </td>
                          <td className='px-3 py-4'>
                            {(permissions?.documentations?.write || user?.role_display === "Netgym Employee" || pageType === 'my-documentation') &&
                              <div className='flex gap-3 items-center justify-end'>
                                {documentation?.documentation?.show_renew &&
                                  <ActionButton setAction={(e, documentation) => processDocumentation(e, documentation, 'renew')} data={documentation} type={'renew'} tooltipId={`${checkSubmitType(documentation) ? 'resubmit' : 'renew'}-documentation-${i}-${documentation?.documentation_category?.id}`} tooltipMessage={`${checkSubmitType(documentation) ? 'Resubmit' : 'Renew'} documentation`} disabledButton={false} pageType={'documentation'}/>
                                }
                                {documentation?.documentation?.show_edit &&
                                  <ActionButton setAction={(e, documentation) => processDocumentation(e, documentation, 'edit')} data={documentation} type={'edit'} tooltipId={`edit-documentation-${i}-${documentation?.documentation_category?.id}`} tooltipMessage={`Edit documentation`} disabledButton={checkAndDisabled(documentation)} pageType={'documentation'}/>
                                }
                                {documentation?.documentation?.show_view &&
                                  <ActionButton setAction={(e, documentation) => processDocumentation(e, documentation, 'view')} data={documentation} type={'view'} tooltipId={`view-documentation-${i}-${documentation?.documentation_category?.id}`} tooltipMessage={`View documentation`} disabledButton={false} pageType={'documentation'}/>
                                }
                                {documentation?.documentation?.show_add &&
                                  <ActionButton setAction={processAddDocumentation} data={documentation} type={'add'} tooltipId={`add-documentation-${i}-${documentation?.documentation_category?.id}`} tooltipMessage={`Add documentation`} disabledButton={checkAndDisabled(documentation)} pageType={'documentation'}/>
                                }
                              </div>
                            }
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  {documentations.length == 0 &&
                    <EmptyCategories type={pageType} />
                  }
                </div>
              </div>
            </div>
          }
          {totalCount > pageSize &&
            <div className="flex justify-center w-full flex-wrap mt-4 pb-2">
              <div className="custom-pagination order-first sm:order-last mb-3 sm:mb-0 mr-7 list-view-pagination">
                <Pagination
                  activePage={page}
                  itemsCountPerPage={pageSize}
                  totalItemsCount={totalCount}
                  pageRangeDisplayed={9}
                  onChange={setPageNumber}
                  itemClassPrev='previous'
                  itemClassNext='next'
                  itemClassFirst='first'
                  itemClassLast='last'
                  hideDisabled={true}
                  activeClass={'pagination-active-tab'}
                />
              </div>
            </div>
          }
        </Fragment>
      }
    </Fragment>
  )
}