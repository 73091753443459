import React, { Fragment, useState } from "react";

export default function ReopenOtherReasonComponent(props) {
  const { reason, setReason, user } = props
  const [ testFieldTouched, setTestFieldTouched ] = useState(false)

  const handleChangeOtherReason = (e) => {
    let val = e.target.value
    if (e.key === ' ') {
      e.preventDefault();
      val += ' ';
    }
    handleApplyFilter(val)
  }

  const handleOnBlur = () => {
    setTestFieldTouched(true)
  }

  const handleApplyFilter = (otherReason) => {
    setReason(prevReason => ({
      ...prevReason, 
      otherReason: otherReason
    }))
  }

  return(
    <Fragment>
      <div className="flex flex-col w-full gap-2">
        <input type="text" className={`w-full border-0 resize-none rounded-2xl px-4 py-2 text-sm text-gray-900 placeholder-gray-400 bg-gray-custom-50 focus:outline-none h-11`} placeholder="Add your reason here" name="other_reason" rows="1" value={reason?.otherReason} onChange={(e) => handleChangeOtherReason(e)} onKeyDown={handleChangeOtherReason} onBlur={handleOnBlur}/>
        {testFieldTouched && !user?.request_reason_optional && reason?.otherReason == '' &&
          <div className="text-xs text-red-500 px-2">
            Reason for reopening the sub request is required
          </div>
        }
      </div>
    </Fragment>
  )
}