import React, { Fragment } from "react";
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/outline'
import { locationName } from './../../../helpers'
import { seprateOutLocations } from '../../../../../../subRequests/helpers/helpers'

export default function HomeClubComponent(props){
	const { locationSettings, showFilterOther, selectedHomeClubRegions, selectedHomeClubLocations, setTheState, handleSaveHomeClub } = props
  const locationList = seprateOutLocations(locationSettings) || []

	const selectedAllRegLoc = () => {
    let check = selectedHomeClubRegions?.length === locationSettings?.length
    return check
  }

	const selectAll = (e) => {
    if(e.target.checked){
      let all = locationSettings
      let selected_regions = []
      let selected_locations = []
      for(var i=0; i<all.length;i++){     
        selected_regions.push(all[i]['region']['id'])
        for(var j=0; j<all[i]['locations'].length; j++){
          selected_locations.push(all[i]['locations'][j]['id'])
        }
      }
      setTheState('selectedHomeClubLocations', selected_locations)
      setTheState('selectedHomeClubRegions', selected_regions)
			handleSaveHomeClub(selected_locations, selected_regions)
    }else{
      setTheState('selectedHomeClubLocations', [])
      setTheState('selectedHomeClubRegions', [])
			handleSaveHomeClub([], [])
    }
  }

	const selectRegions = (e, region) => {
    let val = parseInt(e.target.value)

    let regions = selectedHomeClubRegions
   
    var __FOUND = regions.indexOf(val) > -1;
    var result = region.locations.map(function(a) {return a.id;});
    
    let selected_locations = selectedHomeClubLocations;
    
    if(e.target.checked){
      if(!__FOUND){
        let newRegs = [...selectedHomeClubRegions, val]
        setTheState('selectedHomeClubRegions', newRegs)
        selected_locations = [...new Set([...selectedHomeClubLocations, ...result])];
        setTheState('selectedHomeClubLocations', selected_locations)
        handleSaveHomeClub(selected_locations, newRegs)
      }
    }else{
      if(__FOUND){
        let newRegs = selectedHomeClubRegions.filter((tag, index) => tag !== val)
        setTheState('selectedHomeClubRegions', newRegs)
        selected_locations = selected_locations.filter(item => !result.includes(item));
        setTheState('selectedHomeClubLocations', selected_locations)
				handleSaveHomeClub(selected_locations, newRegs)
      }
    }
  }

  const selectLocation = (e, region) => {
    let val = parseInt(e.target.value)
    var __FOUND = selectedHomeClubLocations.includes(val)
    if(e.target.checked){
      if(!__FOUND){
        let newlocs = [...selectedHomeClubLocations, val]
        setTheState('selectedHomeClubLocations', newlocs)
        checkRegionVisibility(region, newlocs)
      }
    }else{
      if(__FOUND){
        let newLocs = selectedHomeClubLocations.filter((tag, index) => tag !== val)  
        setTheState('selectedHomeClubLocations', newLocs)
        checkRegionVisibility(region, newLocs)  
      }      
    }
    
  }

  const checkRegionVisibility = (region, locations) =>{
    let location_ids = region.locations.map(function(a) {return a.id;});
    let checker = (arr, target) => target.every(v => arr.includes(v));
    let equal = checker(locations, location_ids)
    let val = region.region?.id

    let regions = selectedHomeClubRegions
    let newRegions = regions
    var __FOUND = regions.indexOf(val) > -1;
    if(equal){
      if(!__FOUND){
        newRegions = [...selectedHomeClubRegions, val]
        setTheState('selectedHomeClubRegions', newRegions)
      }
    }else{
      if(__FOUND){
        newRegions = selectedHomeClubRegions.filter((tag, index) => tag !== val)
        setTheState('selectedHomeClubRegions', newRegions)
      }
    }
    handleSaveHomeClub(locations, newRegions)
  }

	return (
		<Fragment>
			<div className="relative filters_box w-60">
        <Menu as="div" className="relative block text-left ">
          <div>
            <Menu.Button className="inline-flex items-center h-10 justify-center w-full rounded border border-gray-300 shadow-sm pl-4 pr-2 py-2 bg-white text-sm text-gray-400 hover:bg-gray-50 focus:outline-none focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 home_club_items_button">
              {selectedHomeClubLocations?.length === locationList?.length && <p className = "text-gray-900">All Home clubs</p>
              }
              {selectedHomeClubLocations?.length < locationList?.length &&
                <React.Fragment>
                  {(selectedHomeClubLocations?.length === 1 && selectedHomeClubLocations?.length !== 0) && 
                    <p className = "text-gray-900">{locationName(locationList, selectedHomeClubLocations)}</p>
                  }
                  {(selectedHomeClubLocations?.length > 1 && selectedHomeClubLocations?.length < locationList?.length) && 
                    <div className = "flex text-gray-900">{locationName(locationList, selectedHomeClubLocations)} {(selectedHomeClubLocations?.length - locationName(locationList, selectedHomeClubLocations)?.split(',')?.length) > 0 && <p>+{selectedHomeClubLocations?.length - locationName(locationList, selectedHomeClubLocations)?.split(',')?.length}</p>}</div>
                  }
                </React.Fragment>
              }
              {(selectedHomeClubLocations?.length === 0  && selectedHomeClubLocations?.length !== locationList?.length) && <p>Home club</p>}
              <ChevronDownIcon className="ml-auto w-4 mr-0.5 text-gray-400" aria-hidden="true" />
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            show={true}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95">
            <Menu.Items className="origin-top-right z-10 absolute right-0 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none hidden home_club_items filter_items">
              <div className="flex items-center p-2 border-b">
                <div className=" ml-auto">
                  <label className="inline-flex items-center text-gray-400 text-sm mb-2">
                    Select All
                    <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 ml-1" checked = {selectedAllRegLoc()} onChange = {(e) => selectAll(e)}/>
                  </label>
                </div>
              </div>
              <div className="p-2 max-h-60 overflow-y-auto">
                {locationSettings.map((region, i) =>
                  <div className="py-1" key = {i}>
                    {showFilterOther &&
                      <Menu.Item>
                        {({ active }) => (
                          <label className="flex items-center text-gray-900 text-sm mb-2">
                            <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-1" onChange={(e) => selectRegions(e, region)} value={region?.region?.id} checked={selectedHomeClubRegions.includes(region.region.id)}/>
                            {region.region.name}
                          </label>
                            
                        )}
                      </Menu.Item>
                    }
                    <div className="ml-5">
                      {region.locations.map((location, j) =>
                      <Menu.Item key = {j}>
                        {({ active }) => (
                          <label className="flex items-center text-gray-900 text-sm mb-2">
                            <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-1" checked={selectedHomeClubLocations.includes(location?.id)} value={location.id} onChange={(e) => selectLocation(e, region)}/>
                            {location.name}
                          </label>
                            
                        )}
                      </Menu.Item>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
		</Fragment>
	)
}