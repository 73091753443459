import React, { Fragment, useState } from "react";
import CustomPopupContainerComponent from '../../../subRequestDetails/components/customPopupContainerComponent';
import { LoadingIcon } from '../../../subRequests/assets';
import { axiosRequest } from '../../../subRequests/helpers/helpers';
import InfoIcon from '../../../documentations/assets/infoIcon';
import SkillsDropdownComponent from './skillsDropdownComponent';

export default function AssignSkillsPopup(props) {
  const { showAssignSkillPopup, closeAssignSkillsPopup, skills, selectedClassesForMassAction, showAlert, completeMassAssignActions, business } = props
  const [selectedSkillId, setSelectedSkillId] = useState(null);
  const [selectedSecondarySkillId, setSelectedSecondarySkillId] = useState(null);
  const [ disableConfirmBtn, setDisableConfirmBtn ] = useState(false)
  const clubreadyFisikalIntegration = (business?.clubready_integration || business?.fisikal_integration)

  const handleAssign = () => {
    if (isApplyDisabled() || selectedClassesForMassAction?.length === 0) return;
    setDisableConfirmBtn(true)
    let params = {
      business_class_ids: selectedClassesForMassAction,
      primary_skill_id: selectedSkillId,
      secondary_skill_id: selectedSecondarySkillId
    }
    axiosRequest(`/api/v3/business_classes/assign_skills`, 'POST', params, 'data').then(result => {
      if(result?.status === 200){
        showAlert(true, 'success', result?.success)
        setTimeout(()=>{
          showAlert(false, '', '')
          completeMassAssignActions()
          setDisableConfirmBtn(false)
        }, 3000)
      }else{
        showAlert(true, 'danger', result?.error)
        setDisableConfirmBtn(false)
        setTimeout(()=>{
          showAlert(false, '', '')
        }, 3000)
      }
    })
  }

  const handleSelectSkill = (e, type) => {
    let val = parseInt(e.target.value);

    if (type === 'primary') {
      setSelectedSkillId(prev => (prev === val ? null : val));
    } else {
      setSelectedSecondarySkillId(prev => (prev === val ? null : val));
    }
  };

  const skillName = (categoryId) => {
    let category = skills.find((item) => item?.id === categoryId)
    if(category){
      return category?.name
    }else{
      return categoryId
    }
  }

  const removeSelectedSkill = (categoryId, type) => {
    if (type === 'primary') {
      setSelectedSkillId(prev => (prev === parseInt(categoryId) ? null : prev));
    } else {
      setSelectedSecondarySkillId(prev => (prev === parseInt(categoryId) ? null : prev));
    }
  };

  const isApplyDisabled = () => {
    return (
      (selectedSkillId == null && !clubreadyFisikalIntegration) ||
      (selectedSecondarySkillId == null && clubreadyFisikalIntegration) ||
      disableConfirmBtn
    );
  };

  return(
    <Fragment>
      <CustomPopupContainerComponent show={showAssignSkillPopup} close={closeAssignSkillsPopup} title={''} customWidth={'sm:max-w-sm assign-skills-popup'}>
        <div className="px-4 pt-3 font-open-sans">
          <div className={`flex gap-2 justify-center text-center font-bold text-xl font-open-sans`}>
            Assign Skill(s)
          </div>
          <div className="flex flex-col mt-5 gap-y-6 min-h-400">
            <div>
              <div className="flex gap-1 ">
                <div className="font-medium text-sm">{`Primary Skill${clubreadyFisikalIntegration ? '' : '*'}`}</div>
                {clubreadyFisikalIntegration &&
                  <div className='inline shrink-0 w-5 h-5 profile-notification-container relative'>
                    <InfoIcon classNames={'inline shrink-0 w-5 h-5 -mt-1.5 cursor-pointer'}/>
                    <div className="profile-notification-tooltip text-white bg-black shadow-md border rounded-md p-2 absolute bottom-8 -left-24 text-xs font-semibold w-56 text-center">
                      {`Primary skills are automatically associated with your classes in ${business?.clubready_integration ? 'Clubready' : 'Fisikal'}. They cannot be applied directly to classes in NetGym.`}
                    </div>
                  </div>
                }
              </div>
              <SkillsDropdownComponent
                clubreadyFisikalIntegration={clubreadyFisikalIntegration}
                skills={skills}
                selectedSkillId={selectedSkillId}
                skillType={'primary'}
                handleSelectSkill={handleSelectSkill}
                skillName={skillName}
                removeSelectedSkill={removeSelectedSkill}
              />
            </div>
            {business?.second_skill_enabled &&
              <div>
                <div className="flex font-medium text-sm">Secondary Skill</div>
                <SkillsDropdownComponent
                  clubreadyFisikalIntegration={clubreadyFisikalIntegration}
                  skills={skills}
                  selectedSkillId={selectedSecondarySkillId}
                  skillType={'secondary'}
                  handleSelectSkill={handleSelectSkill}
                  skillName={skillName}
                  removeSelectedSkill={removeSelectedSkill}
                />
              </div>
            }
          </div>
          <div className="mt-4 relative">
            {disableConfirmBtn && 
              <div className="flex items-center left-5 top-2.5 justify-center absolute">
                <LoadingIcon classNames={'animate-spin h-4 w-4 text-black'}/>
              </div>
            }
            <button 
              className={`bg-gray-900 rounded-md text-white flex items-center justify-center w-full py-2 text-sm ${isApplyDisabled() ? 'opacity-50 cursor-not-allowed' : ''}`}
              onClick={() => handleAssign()}
              disabled={isApplyDisabled()}
            >
              Apply
            </button>
          </div>
        </div>
      </CustomPopupContainerComponent>
    </Fragment>
  )
}