export const checkTheSignUpPagePath = (user) => {
  let path = ''
  if(user?.created_password == false){
    path = '/user-info'
  }
  else if(user?.activation_phone_verified == false){
    path = '/phone-verify'
  }
  else if(user?.set_mobile_notifications == false){
    path = '/notifications'
  }
  else if(user?.set_instructor_locations == false && (!user?.clubready_feature && !user?.fisikal_feature && user?.integration_name !== 'Crunch')){
    if (user?.role_display != 'Fitness Instructor' || (user?.role_display == 'Fitness Instructor' && user?.can_instructor_modify_locations)){
      path = '/locations'
    }else{
      if(!user?.set_daily_notifications && !user?.away){
        path = '/daily-notifications'
      }else{
        path = '/profile-pic'
      }
    }
  }
  else if(user?.set_daily_notifications == false){
    if(!user?.away){
      path = '/daily-notifications'
    }else{
      path = '/profile-pic'
    }
  }
  else {
    path = '/profile-pic'
  }
  return path
}

export const checkSelctedRegionsWithSome = (regionLocations, locations) => {
  let selectedRegions = []
  for (let i = 0; i < regionLocations?.length; i++) {
    selectedRegions = checkRegionVisibility(regionLocations[i], locations, selectedRegions)
  }
  
  return selectedRegions
}

const checkRegionVisibility = (region, locations, selectedRegions) =>{
  let location_ids = region.locations.map(function(a) {return a.id;});
  let checker = (arr, target) => target.some(v => arr.includes(v));
  let equal = checker(locations, location_ids)
  let val = region.region?.name
  var __FOUND = selectedRegions.indexOf(val) > -1;
  if(equal){
    if(!__FOUND){
      return ([...selectedRegions, val])
    }else{
      return selectedRegions
    }
  }else{
    if(__FOUND){
      return(selectedRegions.filter((tag, index) => tag !== val))
    }else{
      return selectedRegions
    }
  }
}