import React, { Fragment, useState } from 'react';
import CustomPopupComponent from './../customPopupComponent/customPopupComponent';
import { axiosRequest } from './../../../helpers/helpers'
import ReopenReasonComponent from './reopenReasonComponent';
import ReopenOtherReasonComponent from "./reopenOtherReasonComponent";

export default function ReOpenEventComponent(props) {
  const { eventId, approvedUser, showReopenPopup, closeReopenPopup, user, handleSetAlert, processFetchEventData, type, sentBucket, calendarEvent, handleUpdateCalendarEvent, fetchInviteMorePopupData } = props
  const [ disableContinueBtn, setDisableContinueBtn ] = useState(false)
  const [ reason, setReason ] = useState({
    reason: '',
    otherReason: ''
  })

  const checkReopenButtonDisabled = () => {
    if(!user?.nysc_gym && !user?.request_reason_optional){
      if(reason?.reason == ''){
        return true
      }else if(reason?.otherReason == '' && reason?.reason == 'Other'){
        return true
      }
    }

    return false
  }

  const handleContinueReopen = () => {
    setDisableContinueBtn(true)
    let params = {
      inst_id: approvedUser?.id,
      preview: type !== 'detail',
      bucket_type: type !== 'detail' ? sentBucket : '',
      calendar_event: calendarEvent,
      reason: reason?.reason == 'Other' ? reason?.otherReason : reason?.reason
    }
    let url = ''
    if(type == 'detail'){
      url = `/api/v3/business_events/${eventId}/perform_more_actions`;
      params = { ...params, key: 'reopen_class' }
    }else{
      url = '/api/v2/reopen_sub_request'
      params = {
        ...params,
        event_id: eventId,
        gql_event_details: true,
      }
    }
    axiosRequest(url, 'PATCH', params, 'data').then((result) => {
      if((type == 'detail' && result?.status == 200) || !Object.keys(result).includes('error')){
        setDisableContinueBtn(false);
        handleSetAlert(true, result?.success, 'success');
        if(calendarEvent) handleUpdateCalendarEvent(result?.calendar_event, 'updated');
        if(result?.invite_more_display){
          fetchInviteMorePopupData(result)
        }else{
          processFetchEventData(result?.event);
        }
        closeReopenPopup(false);
      }else{
        setDisableContinueBtn(false);
        handleSetAlert(true, result?.error, 'danger');
      }
      setTimeout(() => {
        handleSetAlert(false, '', ''); 
      }, 3000)
    })
  }

  const requestingInstructorName = () => {
		return user?.id == approvedUser?.id ? 'You' : approvedUser?.full_name
	}

  return(
    <Fragment>
      <CustomPopupComponent show={showReopenPopup} close={closeReopenPopup} title={'Reopen Sub Request'}>
        <div className="px-4 pt-3 font-open-sans text-left">
          <div className="px-2 mt-3 mb-5">
            <Fragment>
              <div className='text-sm text-gray-700 mb-5'>
                <p>{`Are you sure you want to reopen this sub request? By confirming, ${requestingInstructorName()} will now be the requesting instructor.`}</p>
                <ul className="list-disc pl-6">
                  <li>All instructors who accepted previously will be moved to 'No Response' state. We want to ensure they are still free to sub.</li>
                  <li>Instructors who did not respond initially will be notified again.</li>
                  <li>Instructors who declined will not receive another notification.</li>
                  <li>To invite more people, head to the not yet invited section.</li>
                </ul>
              </div>
            </Fragment>
            <Fragment>
              <div className={`gap-6 grid grid-cols-1 mb-5`}>
                <ReopenReasonComponent 
                  reason={reason}
                  setReason={setReason}
                  user={user}
                />
              </div> 
              {reason?.reason == 'Other' &&
                <div className={`gap-6 grid grid-cols-1`}>
                  <ReopenOtherReasonComponent
                    reason={reason}
                    setReason={setReason}
                    user={user}
                  />
                </div>
              }
            </Fragment>
          </div>
          <div className="flex gap-3 flex-row-reverse">
            <button 
              className={`bg-gray-900 rounded-xl text-white flex items-center justify-center w-32 py-2 text-sm ${(disableContinueBtn || checkReopenButtonDisabled()) ? 'opacity-50 cursor-not-allowed' : ''}`}
              onClick={() => handleContinueReopen()}
              disabled={disableContinueBtn || checkReopenButtonDisabled()}
            >
              Confirm
            </button>
            <button 
              className={`bg-white border rounded-xl text-gray-800 flex items-center justify-center w-32 py-2 text-sm hover:bg-gray-100 shadow`}
              onClick={() => closeReopenPopup(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      </CustomPopupComponent>
    </Fragment>
  )
}